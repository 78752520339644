import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ClientsSelect from 'components/clients/ClientsSelect/ClientsSelect';
import { DateInput } from 'components/Common/DateInput';
import { CalendarIcon } from 'components/Icon/Icon';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { INVOICE_STATUS } from 'const/financials/invoices/INVOICE_STATUS';
import { INVOICE_PROP } from 'const/financials/invoices/INVOICE_PROP';
import classNames from 'classnames';
import { getError, isInvalid } from 'services/validationService';
import { Label } from 'components/Common/Typography/Label';
import { Column } from 'components/Common/Column';
import { Row } from 'components/Common/Row';
import { useMobile } from 'hooks/useMobile';

function InvoicesInfoFormGroup({ formik, fixedClientId }) {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const {
        errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        total,
    } = formik;
    const isMobile = useMobile();

    useEffect(() => {
        if (values.status === INVOICE_STATUS.UNPAID && !total) {
            setFieldValue('status', INVOICE_STATUS.DRAFT);
        }
    }, [values.status, total]);

    return (
        <Row stretched gap={16} align="start" wrap={isMobile}>
            <Column stretched gap={16}>
                <ClientsSelect formik={formik} fixedClientId={fixedClientId} />
                <Column stretched gap={8}>
                    <Label htmlFor="note" tooltip={t('add.note.tooltip')}>
                        {t('add.note.label')}
                    </Label>
                    <Form.Control
                        id="note"
                        as="textarea"
                        rows={3}
                        name="note"
                        value={values.note}
                        onChange={handleChange}
                        maxLength={INVOICE_PROP.NOTE.MAX_LENGTH}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-flex': isInvalid('note', errors, touched),
                        })}
                    >
                        {getError('note', errors)}
                    </Form.Control.Feedback>
                </Column>
            </Column>
            <Column stretched gap={8}>
                <Row stretched gap={16} wrap={isMobile}>
                    <Column stretched gap={8} align="stretch">
                        <Label htmlFor="issueDate">
                            {t('add.issueDate.label')}
                        </Label>
                        <DateInput
                            id="issueDate"
                            name="issueDate"
                            value={values.issueDate}
                            className="text-nowrap"
                            iconComponent={CalendarIcon}
                            isDisabled
                            format="DD.MM.YYYY"
                        />
                    </Column>
                    <Column stretched gap={8} align="stretch">
                        <Label htmlFor="dueDate">
                            {t('add.dueDate.label')}
                        </Label>
                        <DateInput
                            id="dueDate"
                            name="dueDate"
                            value={values.dueDate}
                            className="text-nowrap"
                            iconComponent={CalendarIcon}
                            isDisabled
                            format="DD.MM.YYYY"
                        />
                    </Column>
                </Row>
            </Column>
        </Row>
    );
}

export default InvoicesInfoFormGroup;
