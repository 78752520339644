import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './ListTableBodyCell.module.scss';

const ListTableBodyCell = (props) => {
    const {
        colSpan,
        children,
        className,
        noPadding,
        align,
    } = props;

    return (
        <td
            colSpan={colSpan}
            className={classNames(styles.bodyCell, align && styles[align], noPadding && styles.noPadding, className)}
            data-testid={props['data-testid']}
            style={{ maxWidth: props.maxWidth }}
        >
            {children}
        </td>
    );
};

ListTableBodyCell.propTypes = {
    noPadding: PropTypes.bool,
    colSpan: PropTypes.number,
    children: PropTypes.any,
    className: PropTypes.string,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    'data-testid': PropTypes.string,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ListTableBodyCell.defaultProps = {
    noPadding: false,
    colSpan: 1,
    children: null,
    className: '',
    align: 'left',
    'data-testid': 'data-test-list-table-body-cell',
    maxWidth: 'unset',
};

export default ListTableBodyCell;
