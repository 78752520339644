import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    Nav, Tab,
} from 'react-bootstrap';

import { useCallback } from 'react';
import { PAYOUT_TRANSACTION_TYPE } from 'const/payouts/PAYOUT_TRANSACTION_TYPE';
import { Dialog } from 'components/Common/Dialogs/Dialog/Dialog';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { DialogHeader } from '../../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../../Common/Dialogs/Dialog/DialogBody';
import { TransactionsTable } from './TransactionsTable';

import * as PAYOUTS_ACTIONS from '../../../../store/actions/financials/payouts';

import * as styles from './PayoutsDetailsDialog.module.scss';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const PayoutsDetailsDialog = (props) => {
    const {
        payoutId,
        isVisible,
        onClose,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.PAYOUTS);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isVisible) {
            dispatch(PAYOUTS_ACTIONS.setPayoutSelectedId({
                payoutId,
            }));
        }
    }, [isVisible]);

    const handleOnSelect = useCallback((activeKey) => {
        dispatch(PAYOUTS_ACTIONS.setPayoutTransactionsType({
            type: activeKey,
        }));
    }, []);

    return (
        <Dialog
            visible={isVisible}
            restoreFocus={false}
            dialogClassName={styles.dialog}
            onClose={onClose}
        >
            <DialogHeader>
                <DialogTitle>
                    {t('payoutsDetailsDialog.title')}
                </DialogTitle>
            </DialogHeader>

            <DialogBody>
                <Tab.Container
                    defaultActiveKey={PAYOUT_TRANSACTION_TYPE.PAYMENTS}
                    onSelect={handleOnSelect}
                >
                    <Nav className={styles.navBar}>
                        <Nav.Link eventKey={PAYOUT_TRANSACTION_TYPE.PAYMENTS}>
                            {t('payoutsDetailsDialog.tabs.payments')}
                        </Nav.Link>
                        <Nav.Link eventKey={PAYOUT_TRANSACTION_TYPE.DEBIT}>
                            {t('payoutsDetailsDialog.tabs.debit')}
                        </Nav.Link>
                        <Nav.Link eventKey={PAYOUT_TRANSACTION_TYPE.REFUNDS}>
                            {t('payoutsDetailsDialog.tabs.refunds')}
                        </Nav.Link>
                    </Nav>
                </Tab.Container>

                <div className={styles.tabContent}>
                    <TransactionsTable />
                </div>
            </DialogBody>
        </Dialog>
    );
};

PayoutsDetailsDialog.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};

PayoutsDetailsDialog.defaultProps = {
    isVisible: false,
    onClose: emptyFunc,
};

export default PayoutsDetailsDialog;
