import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { Close } from 'components/Icon/Icon';
import Check from 'components/Icon/icons/check';
import { LOCALE_NAMESPACE } from '../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ProfileWalletInfo.module.scss';

const T_PREFIX = 'profile.details';

const ProfileWalletInfo = (props) => {
    const { client } = props;
    const { note } = client || {};
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const noteLabel = note || t(`${T_PREFIX}.fields.note.notAvailable`);

    return (
        <Col className={styles.profileWalletContainer}>
            <Row className={styles.profileWalletLine}>
                <Col className={styles.lineName}>
                    {t(`${T_PREFIX}.fields.loyaltyPoints.label`)}
                </Col>
                <Col className={styles.lineContent}>{client?.loyaltyPoints || 0}</Col>
            </Row>
            <Row className={styles.profileWalletLine}>
                <Col className={styles.lineName}>
                    {t(`${T_PREFIX}.fields.maskedIban.label`)}
                </Col>
                <Col className={styles.lineContent}>
                    {client?.maskedIban || (
                        <Close className={styles.lineName} />
                    )}
                </Col>
            </Row>
            <Row className={styles.profileWalletLine}>
                <Col className={styles.lineName}>
                    {t(`${T_PREFIX}.fields.hadMandate.label`)}
                </Col>
                <Col className={styles.lineContent}>
                    {client?.hadMandate ? (
                        <Check className={styles.lineName} />
                    ) : (
                        <Close className={styles.lineName} />
                    )}
                </Col>
            </Row>
            <Row className={styles.lineName}>
                {t(`${T_PREFIX}.fields.note.label`)}
            </Row>
            <Row className={classNames(styles.lineContent, styles.note)}>
                {noteLabel}
            </Row>
        </Col>
    );
};

ProfileWalletInfo.propTypes = {
    client: PropTypes.object,
};

ProfileWalletInfo.defaultProps = {
    client: null,
};

export default ProfileWalletInfo;
