import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { INVOICE_PROP } from 'const/financials/invoices/INVOICE_PROP';
import { Tooltip } from 'components/Common/Tooltip';
import { productInitialData } from 'pages/financials/scenes/invoices/scenes/editInvoice/page';
import { useMobile } from 'hooks/useMobile';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Button } from 'components/Common/Button';
import { SubHeader } from 'components/Common/Typography/SubHeader';
import { Panel } from 'components/Common/Panel';
import { Divider } from 'components/Common/Divider';
import { Column } from 'components/Common/Column';
import { Row } from 'components/Common/Row';
import { Label } from 'components/Common/Typography/Label';
import { Header } from 'components/Common/Typography/Header';
import InvoiceProductFormGroup from './InvoiceProductFormGroup/InvoiceProductFormGroup';

function InvoiceProductsFormGroup({ formik }) {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    const {
        values: { products },
        errors,
        touched,
        setFieldValue,
        handleChange,
        setFieldTouched,
    } = formik;

    const isMobile = useMobile();

    const handleAddProduct = useCallback(() => {
        setFieldValue('products', [...products, productInitialData()]);
    }, [setFieldValue, products]);

    const handleDeleteProduct = useCallback(({ id, index }) => {
        const filteredproducts = products.filter((s, i) => String(s.id) !== String(id) || String(index) !== String(i));
        setFieldValue('products', filteredproducts);
    }, [products, setFieldValue]);

    const subTotal = useMemo(() => products.reduce((acc, product) => {
        const price = Number(product.price);
        const quantity = Number(product.quantity);
        const vat = Number(product.vatValue);
        const productTotal = price * quantity;
        return acc + productTotal / (1 + vat / 100);
    }, 0));

    const total = useMemo(
        () => products.reduce((acc, product) => {
            const price = Number(product.price);
            const quantity = Number(product.quantity);
            const productTotal = price * quantity;
            return acc + productTotal;
        }, 0),
        [products],
    );

    const vats = useMemo(() => products.reduce((acc, product) => {
        const vat = Number(product.vatValue);
        const price = Number(product.price);
        const quantity = Number(product.quantity);
        const vatTotal = (price * quantity) * (1 - 100 / (100 + vat));
        if (!vat) {
            return acc;
        }
        const vatIndex = acc.findIndex((v) => v.vat === vat);
        if (vatIndex === -1) {
            return [...acc, { vat, total: vatTotal }];
        }
        acc[vatIndex].total += vatTotal;
        return acc;
    }, []), [products]);

    return (
        <Panel stretched color="secondary" noBorder padding={isMobile ? 16 : 24}>
            <Column gap={16} stretched>
                <Header>
                    {t('add.products.label')}
                </Header>
                <Column stretched gap={16}>
                    {
                        products.map((product, i) => (
                            <Column stretched gap={16} key={i}>
                                <InvoiceProductFormGroup
                                    index={i}
                                    product={product}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    setFieldTouched={setFieldTouched}
                                    onDelete={handleDeleteProduct}
                                    isDeleteDisabled={Boolean(products.length === 1)}
                                />
                                <Divider horisontal padding={0} />
                            </Column>
                        ))
                    }
                </Column>
                <Row stretched gap={16} wrap={isMobile} align="start" justify="between">
                    <Tooltip
                        isDisabled={products.length < INVOICE_PROP.PRODUCTS.MAX_COUNT}
                        tooltip={t('add.addProduct.tooltip', { count: INVOICE_PROP.PRODUCTS.MAX_COUNT })}
                        placement="bottom-start"
                    >
                        <Button
                            onClick={handleAddProduct}
                            disabled={products.length >= INVOICE_PROP.PRODUCTS.MAX_COUNT}
                            color="yellow"
                        >
                            {t('add.addProduct.label')}
                        </Button>
                    </Tooltip>
                    {isMobile && (
                        <React.Fragment>
                            <Label color="gray">
                                {t('add.addProduct.tooltip', { count: INVOICE_PROP.PRODUCTS.MAX_COUNT })}
                            </Label>
                            <Divider horisontal />
                        </React.Fragment>
                    )}
                    <Column gap={0}>
                        <Row gap={16} justify="between" stretched>
                            <SubHeader color="gray" weight="light">
                                {t('add.products.subtotal.label')}
                            </SubHeader>
                            <SubHeader weight="light">
                                {formatPrice.toEuroWithComma({ amount: subTotal })}
                            </SubHeader>
                        </Row>
                        {vats.map((value) => (
                            <Row gap={16} justify="between" stretched>
                                <SubHeader color="gray" weight="light">
                                    {t('add.products.vat.label', { vat: value.vat })}
                                </SubHeader>
                                <SubHeader weight="light">
                                    {formatPrice.toEuroWithComma({ amount: value.total })}
                                </SubHeader>
                            </Row>
                        ))}
                        <Row gap={16} justify="between" stretched>
                            <SubHeader color="gray" weight="light">
                                {t('add.products.total.label')}
                            </SubHeader>
                            <SubHeader weight="light">
                                {formatPrice.toEuroWithComma({ amount: total })}
                            </SubHeader>
                        </Row>
                    </Column>
                </Row>
            </Column>
        </Panel>
    );
}

export default InvoiceProductsFormGroup;
