import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'components/Common/Button';
import { Header } from 'components/Common/Typography/Header';
import { Label } from 'components/Common/Typography/Label';
import { Dialog } from 'components/Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from 'components/Common/Dialogs/Dialog/DialogHeader';
import { DialogBody } from 'components/Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from 'components/Common/Dialogs/Dialog/DialogFooter';

const ConfirmationModal = (
    {
        titleText,
        bodyText,
        deleteText,
        dismissText,
        deleteColor = 'red',
        isShow,
        hide,
        loading,
        confirmAction,
        customFooter,
        restoreFocus = false,
        size = 'md',
        ...rest
    },
) => {
    const { t } = useTranslation();

    return (
        <Dialog
            size={size}
            visible={isShow}
            onClose={hide}
            restoreFocus={restoreFocus}
            data-testid="data-test-confirmation-modal"
            {...rest}
        >
            <DialogHeader closeButton data-testid="data-test-confirm-dialog-header">
                <Header data-testid="data-test-title">
                    {titleText}
                </Header>
            </DialogHeader>

            <DialogBody data-testid="data-test-confirm-dialog-text">
                { typeof bodyText === 'string' ? <Label>{bodyText}</Label> : bodyText}
            </DialogBody>

            {customFooter || (
                <DialogFooter data-testid="data-test-confirm-dialog-footer">
                    <Button
                        color="outline"
                        onClick={hide}
                        data-testid="data-test-cancel-button"
                    >
                        {dismissText ?? t('bookingModal.cancel')}
                    </Button>

                    <Button
                        color={deleteColor}
                        className="mx-2"
                        loading={loading}
                        onClick={confirmAction}
                        data-testid="data-test-delete-button"
                    >
                        {deleteText}
                    </Button>
                </DialogFooter>
            )}
        </Dialog>
    );
};

export default ConfirmationModal;
