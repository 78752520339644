import { invoicesListSaga } from './invoicesList';
import { payoutsListSaga } from './payoutsList';
import { clearingsListSaga } from './clearingsList';
import { multisafePayoutsListSaga } from './multisafePayoutsList';
import { billingsListSaga } from './billingsList';

export const financialsSaga = [
    ...invoicesListSaga,
    ...payoutsListSaga,
    ...clearingsListSaga,
    ...multisafePayoutsListSaga,
    ...billingsListSaga,
];
