const PREFIX = '@@BILLINGS/';

const BILLINGS = `${PREFIX}BILLINGS`;
export const BILLING_DOWNLOAD = `${BILLINGS}:DOWNLOAD_BILLING`;
const BILLINGS_PAGE = `${BILLINGS}:PAGE`;
export const BILLINGS_PAGE_SET = `${BILLINGS_PAGE}:SET`;
export const BILLINGS_PAGE_SET_FIRST = `${BILLINGS_PAGE}:FIRST`;
export const BILLINGS_PAGE_SET_LAST = `${BILLINGS_PAGE}:LAST`;
export const BILLINGS_PAGE_SET_PREV = `${BILLINGS_PAGE}:PREV`;
export const BILLINGS_PAGE_SET_NEXT = `${BILLINGS_PAGE}:NEXT`;

export const GET_BILLINGS = `${BILLINGS}:GET`;
export const GET_BILLINGS_TRIGGER = `${GET_BILLINGS}.TRIGGER`;
export const GET_BILLINGS_RESOLVED = `${GET_BILLINGS}.RESOLVED`;
export const GET_BILLINGS_REJECTED = `${GET_BILLINGS}.REJECTED`;

export const BILLINGS_SORT_OPTIONS_SET = `${BILLINGS}:SORT_OPTIONS_SET`;
export const BILLINGS_SORT_OPTIONS_SET_TRIGGER = `${BILLINGS_SORT_OPTIONS_SET}.TRIGGER`;
export const BILLINGS_SORT_OPTIONS_SET_RESOLVED = `${BILLINGS_SORT_OPTIONS_SET}.RESOLVED`;
export const BILLINGS_SORT_OPTIONS_SET_REJECTED = `${BILLINGS_SORT_OPTIONS_SET}.REJECTED`;
