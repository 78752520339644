import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'components/Common/Column';
import { Row } from 'components/Common/Row';

import { Trans, useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Panel } from 'components/Common/Panel';
import { IconButton } from 'components/Common/IconButton';
import { Pen2Icon, Trash3Icon } from 'components/Icon/Icon';
import { Tooltip } from 'components/Common/Tooltip';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useDialog } from 'hooks/useDialog';
import { Header } from 'components/Common/Typography/Header';
import { Label } from 'components/Common/Typography/Label';
import { Divider } from 'components/Common/Divider';
// import * as styles from './ProfileCard.module.scss';

const ProfileCard = ({
    profile, onEdit, onDelete,
}) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const confirmDeleteDialog = useDialog(false);

    const handleDelete = useCallback(() => {
        onDelete();
        confirmDeleteDialog.onClose();
    }, [onDelete, confirmDeleteDialog]);

    const rule = useMemo(() => ({
        booking: t('profiles.card.rulesForBundlesAndSubscriptions.booking'),
        booking_limited: t('profiles.card.rulesForBundlesAndSubscriptions.booking'),
        walk_in_always: t('profiles.card.rulesForBundlesAndSubscriptions.walkIn'),
        walk_in_match: t('profiles.card.rulesForBundlesAndSubscriptions.walkIn'),
    }), [t]);

    const schedule = useMemo(() => ({
        walk_in_always: t('profiles.card.rule.alwaysOpen'),
        walk_in_match: t('profiles.card.rule.matchCompanyWorkTime'),
        booking_limited: t('profiles.card.rule.bookingLimited'),
        booking: t('profiles.card.rule.bookingUnlimited'),
    }), [t]);

    return (
        <React.Fragment>
            <Panel stretched padding={12} gap={32} color="secondary">
                <Column gap={16} stretched>
                    <Column gap={4} stretched>
                        <Row justify="between" stretched>
                            <Header ellipsis>
                                {profile.name}
                            </Header>
                            <Row>
                                <Tooltip forButton tooltip={tc('placeholders.edit')} placement="bottom">
                                    <IconButton
                                        onClick={onEdit}
                                        color="gray"
                                        size={32}
                                    >
                                        <Pen2Icon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip forButton tooltip={tc('placeholders.delete')} placement="bottom">
                                    <IconButton onClick={confirmDeleteDialog.onShow} color="gray">
                                        <Trash3Icon />
                                    </IconButton>
                                </Tooltip>
                            </Row>
                        </Row>
                        <Label>
                            {t('profiles.card.gatesCount.label', { count: profile.gates.length })}
                        </Label>
                    </Column>
                    <Column gap={8} stretched>
                        <Row justify="between" stretched>
                            <Label color="gray">
                                {t('profiles.card.rulesForBundlesAndSubscriptions.label')}
                            </Label>
                            <Label align="right">
                                {rule[profile.rule]}
                            </Label>
                        </Row>
                        <Divider horisontal />
                        <Row justify="between" stretched align="start">
                            <Label color="gray" noWrap>
                                {t('profiles.card.rule.label')}
                            </Label>
                            <Label align="right">
                                {schedule[profile.rule]}
                            </Label>
                        </Row>
                    </Column>
                </Column>
            </Panel>
            <ConfirmationModal
                isShow={confirmDeleteDialog.visible}
                titleText={t('profiles.card.deleteModal.title')}
                bodyText={(
                    <Trans
                        t={t}
                        i18nKey="profiles.card.deleteModal.text"
                        values={{
                            name: profile.name,
                        }}
                    />
                )}
                deleteText={tc('placeholders.delete')}
                dismissText={tc('placeholders.cancel')}
                confirmAction={handleDelete}
                hide={confirmDeleteDialog.onClose}
            />
        </React.Fragment>
    );
};

ProfileCard.propTypes = {
    profile: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

ProfileCard.defaultProps = {
};

export default ProfileCard;
