import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { companyAccessModeSelector, companyBuisnessModeSelector } from 'store/selectors/company';

import { isValidIBAN } from 'helpers/validation/iban';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { Button } from 'components/Common/Button';
import TextButton from 'components/Common/TextButton/TextButton';
import { Pen2Icon } from 'components/Icon/Icon';
import { useDialog } from 'hooks/useDialog';
import { Row } from 'components/Common/Row';
import { NAME_VALIDATION } from 'const/string/REGEX_VALIDATION';
import DateInput1 from 'components/DateInput/DateInput';
import moment from 'moment';
import { Divider } from 'components/Common/Divider';
import { Dialog } from '../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../Common/Dialogs/Dialog/DialogBody';
import { FormInput } from '../../Common/FormInput';
import { PhoneInput } from '../../Common/PhoneInput';
import { CheckBox } from '../../Common/CheckBox';
import { DialogFooter } from '../../Common/Dialogs/Dialog/DialogFooter';

import { useBool } from '../../../hooks/useBool';

import * as CLIENTS_ACTIONS from '../../../store/actions/clients';

import { getError, isInvalid } from '../../../services/validationService';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { CLIENT_PROP } from '../../../const/clients/CLIENT_PROP';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './EditClientDialog.module.scss';
import { EditIbanDialog } from '../EditIbanDialog';

const DATA_TRANSFORMER = {
    send: ({ values }) => {
        const formData = new FormData();
        formData.append('firstName', values.firstName);
        formData.append('lastName', values.lastName);
        formData.append('email', values.email);
        formData.append('contactNumber', values.phone);
        formData.append('clientCompanyName', values.companyName);
        if (values.birthDate) {
            formData.append('birthDate', moment(values.birthDate).format('YYYY-MM-DD'));
        }
        formData.append('address', values.street);
        formData.append('zipCode', values.zipCode);
        formData.append('city', values.city);
        formData.append('note', values.note);
        formData.append('disallowPaymentsInShop', !values.afterwardPayments);
        formData.append('blocked', values.block);
        formData.append('monthlyInvoicing', values.monthlyInvoicing);
        if (values.country) {
            formData.append('country', values.country);
        }
        if (values.vatNumber) {
            formData.append('vatNumber', values.vatNumber);
        }
        if (values.cocNumber) {
            formData.append('cocNumber', values.cocNumber);
        }
        if (values.iban) {
            formData.append('iban', values.iban);
        }
        if (values.accessCardCode) {
            formData.append('accessCardCode', values.accessCardCode);
        }
        return formData;
    },
};

const T_PREFIX = 'list.dialogs.edit';
const T_FIELDS = `${T_PREFIX}.body.form.fields`;
const T_ACTIONS = `${T_PREFIX}.footer.actions`;

const EditClientDialog = (props) => {
    const {
        client,
        visible,
        onClose,
        isRequiredClientAddress,
    } = props;

    const {
        fullName,
        contactInfo,
        disallowPaymentInShop,
        companyName,
        note,
        registered,
        block,
        maskedIban,
        ibanOwner,
    } = client || {};
    const { firstName, lastName } = fullName || {};
    const { email, phone, address } = contactInfo || {};
    const { street, zip, city } = address || {};
    const { enableBusinessMode } = useSelector(companyBuisnessModeSelector);
    const { accessEnabled, accessCardsEnabled } = useSelector(companyAccessModeSelector);

    const editIbanDialog = useDialog(false);

    const accessCardsCodeEnabled = Boolean(accessEnabled && accessCardsEnabled);

    const initialValues = useMemo(() => ({
        firstName: CLIENT_PROP.FIRST_NAME.DEFAULT,
        lastName: CLIENT_PROP.LAST_NAME.DEFAULT,
        birthDate: CLIENT_PROP.BIRTH_DATE.DEFAULT,
        email: CLIENT_PROP.EMAIL.DEFAULT,
        phone: CLIENT_PROP.PHONE.DEFAULT,
        companyName: CLIENT_PROP.COMPANY_NAME.DEFAULT,
        street: CLIENT_PROP.STREET.DEFAULT,
        zipCode: CLIENT_PROP.ZIP.DEFAULT,
        city: CLIENT_PROP.CITY.DEFAULT,
        note: CLIENT_PROP.NOTE.DEFAULT,
        afterwardPayments: CLIENT_PROP.AFTERWARD_PAYMENTS.DEFAULT,
        block: CLIENT_PROP.BLOCKED.DEFAULT,
        ...(enableBusinessMode ? {
            cocNumber: CLIENT_PROP.NULA_COC_NUMBER.DEFAULT,
            vatNumber: CLIENT_PROP.NULA_VAT_NUMBER.DEFAULT,
            country: CLIENT_PROP.NULA_COUNTRY.DEFAULT,
            iban: CLIENT_PROP.IBAN.DEFAULT,
        } : {}),
        maskedIban: CLIENT_PROP.IBAN.DEFAULT,
        ...(accessCardsCodeEnabled ? {
            accessCardCode: CLIENT_PROP.ACCESS_CARD_CODE.DEFAULT,
        } : {}),
        // monthlyInvoicing: CLIENT_PROP.MONTHLY_INVOICING.DEFAULT,
    }), [enableBusinessMode, accessCardsCodeEnabled]);

    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const hasErrors = useBool(false);

    const dispatch = useDispatch();

    const validationSchema = useMemo(() => yup.object({
        firstName: yup.string()
            .trim()
            .min(
                CLIENT_PROP.FIRST_NAME.MIN_LENGTH,
                t(`${T_FIELDS}.firstName.validation.minLength`, {
                    length: CLIENT_PROP.FIRST_NAME.MIN_LENGTH,
                }),
            )
            .max(
                CLIENT_PROP.FIRST_NAME.MAX_LENGTH,
                t(`${T_FIELDS}.firstName.validation.maxLength`, {
                    length: CLIENT_PROP.FIRST_NAME.MAX_LENGTH,
                }),
            )
            .test('name', t(`${T_FIELDS}.firstName.validation.invalid`), (value) => NAME_VALIDATION.test(value))
            .required(t(`${T_FIELDS}.firstName.validation.required`)),
        lastName: yup.string()
            .trim()
            .min(
                CLIENT_PROP.LAST_NAME.MIN_LENGTH,
                t(`${T_FIELDS}.lastName.validation.minLength`, {
                    length: CLIENT_PROP.LAST_NAME.MIN_LENGTH,
                }),
            )
            .max(
                CLIENT_PROP.LAST_NAME.MAX_LENGTH,
                t(`${T_FIELDS}.lastName.validation.maxLength`, {
                    length: CLIENT_PROP.LAST_NAME.MAX_LENGTH,
                }),
            )
            .test('name', t(`${T_FIELDS}.lastName.validation.invalid`), (value) => NAME_VALIDATION.test(value))
            .required(t(`${T_FIELDS}.lastName.validation.required`)),
        birthDate: yup.date().nullable(),
        email: yup.string()
            .email(t(`${T_FIELDS}.email.validation.email`))
            .max(
                CLIENT_PROP.EMAIL.MAX_LENGTH,
                t(`${T_FIELDS}.email.validation.maxLength`, {
                    length: CLIENT_PROP.EMAIL.MAX_LENGTH,
                }),
            )
            .notRequired(),
        phone: yup.string()
            .required(t(`${T_FIELDS}.phone.validation.required`)),
        companyName: yup.string()
            .trim()
            .max(
                CLIENT_PROP.COMPANY_NAME.MAX_LENGTH,
                t(`${T_FIELDS}.companyName.validation.maxLength`, {
                    length: CLIENT_PROP.COMPANY_NAME.MAX_LENGTH,
                }),
            ),
        street: yup.string()
            .trim()
            .max(
                CLIENT_PROP.STREET.MAX_LENGTH,
                t(`${T_FIELDS}.street.validation.maxLength`, {
                    length: CLIENT_PROP.STREET.MAX_LENGTH,
                }),
            )
            .required(isRequiredClientAddress ? t(`${T_FIELDS}.street.validation.required`) : ''),
        zipCode: yup.string()
            .trim()
            .max(
                CLIENT_PROP.ZIP.MAX_LENGTH,
                t(`${T_FIELDS}.zip.validation.maxLength`, {
                    length: CLIENT_PROP.ZIP.MAX_LENGTH,
                }),
            )
            .required(isRequiredClientAddress ? t(`${T_FIELDS}.zip.validation.required`) : ''),
        city: yup.string()
            .trim()
            .max(
                CLIENT_PROP.CITY.MAX_LENGTH,
                t(`${T_FIELDS}.city.validation.maxLength`, {
                    length: CLIENT_PROP.CITY.MAX_LENGTH,
                }),
            )
            .required(isRequiredClientAddress ? t(`${T_FIELDS}.city.validation.required`) : ''),
        note: yup.string()
            .trim()
            .max(
                CLIENT_PROP.NOTE.MAX_LENGTH,
                t(`${T_FIELDS}.note.validation.maxLength`, {
                    length: CLIENT_PROP.NOTE.MAX_LENGTH,
                }),
            ),
        afterwardPayments: yup.bool(),
        monthlyInvoicing: yup.bool(),
        ...(enableBusinessMode ? {
            cocNumber: yup.string()
                .trim()
                .max(
                    CLIENT_PROP.NULA_COC_NUMBER.MAX_LENGTH,
                    t(`${T_FIELDS}.cocNumber.validation.maxLength`, {
                        length: CLIENT_PROP.NULA_COC_NUMBER.MAX_LENGTH,
                    }),
                ),
            vatNumber: yup.string()
                .trim()
                .max(
                    CLIENT_PROP.NULA_VAT_NUMBER.MAX_LENGTH,
                    t(`${T_FIELDS}.vatNumber.validation.maxLength`, {
                        length: CLIENT_PROP.NULA_VAT_NUMBER.MAX_LENGTH,
                    }),
                ),
            country: yup.string()
                .trim()
                .max(
                    CLIENT_PROP.NULA_COUNTRY.MAX_LENGTH,
                    t(`${T_FIELDS}.country.validation.maxLength`, {
                        length: CLIENT_PROP.NULA_COUNTRY.MAX_LENGTH,
                    }),
                ),
            iban: yup.string()
                .trim()
                .max(
                    CLIENT_PROP.IBAN.MAX_LENGTH,
                    t(`${T_FIELDS}.iban.validation.maxLength`, {
                        length: CLIENT_PROP.IBAN.MAX_LENGTH,
                    }),
                )
                .test({
                    name: 'is-valid-iban',
                    message: t(`${T_FIELDS}.iban.validation.invalid`),
                    exclusive: true,
                    test: (value) => !value || isValidIBAN(value),
                }),
        } : {}),
        ...(accessCardsCodeEnabled ? {
            accessCardCode: yup.string()
                .trim()
                .max(
                    CLIENT_PROP.ACCESS_CARD_CODE.MAX_LENGTH,
                    t(`${T_FIELDS}.accessCardCode.validation.maxLength`, {
                        length: CLIENT_PROP.ACCESS_CARD_CODE.MAX_LENGTH,
                    }),
                ),
        } : {}),
    }), [t, isRequiredClientAddress, enableBusinessMode]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: useCallback((values, { setSubmitting }) => {
            if (!client?.id) {
                return;
            }

            setSubmitting(true);

            const data = DATA_TRANSFORMER.send({ values });
            dispatch(CLIENTS_ACTIONS.editClientItem({
                clientId: client.id,
                data,
                onSuccess: () => {
                    dispatch(CLIENTS_ACTIONS.getClients());
                    dispatch(CLIENTS_ACTIONS.getClientItem({ clientId: client.id }));
                    onClose();
                    setSubmitting(false);
                },
                onError: (data) => {
                    setErrors(data);
                    setSubmitting(false);
                },
            }));
        }, [dispatch, onClose, client?.id]),
        enableReinitialize: true,
    });

    const {
        values,
        errors,
        setErrors,
        touched,
        setValues,
        setFieldValue,
        setFieldTouched,
        resetForm,
        handleChange,
        handleSubmit,
        isSubmitting,
    } = formik;

    const validation = useMemo(() => {
        hasErrors.onFalse();
        return Object.keys(values).reduce((res, k) => {
            const invalid = isInvalid(k, errors, touched);
            if (invalid) {
                hasErrors.onTrue();
            }
            const subValidation = {
                isInvalid: invalid,
                error: getError(k, errors),
            };
            return {
                ...res,
                [k]: subValidation,
            };
        }, {});
    }, [
        values,
        errors,
        touched,
        hasErrors.onFalse,
        hasErrors.onTrue,
    ]);

    useEffect(() => {
        if (!visible) {
            resetForm();
        }
    }, [visible, resetForm]);

    useEffect(() => {
        if (!client?.id) {
            return;
        }
        setValues({
            firstName: firstName || CLIENT_PROP.FIRST_NAME.DEFAULT,
            lastName: lastName || CLIENT_PROP.LAST_NAME.DEFAULT,
            birthDate: client?.dateOfBirth ? moment.unix(client.dateOfBirth) : CLIENT_PROP.BIRTH_DATE.DEFAULT,
            email,
            phone: phone || CLIENT_PROP.PHONE.DEFAULT,
            companyName: companyName || CLIENT_PROP.COMPANY_NAME.DEFAULT,
            street: street || CLIENT_PROP.STREET.DEFAULT,
            zipCode: zip || CLIENT_PROP.ZIP.DEFAULT,
            city: city || CLIENT_PROP.CITY.DEFAULT,
            note: note || CLIENT_PROP.NOTE.DEFAULT,
            afterwardPayments: !disallowPaymentInShop,
            block: block || CLIENT_PROP.BLOCKED.DEFAULT,
            // monthlyInvoicing: CLIENT_PROP.MONTHLY_INVOICING.DEFAULT,
            ...(enableBusinessMode ? {
                cocNumber: client?.cocNumber || CLIENT_PROP.NULA_COC_NUMBER.DEFAULT,
                vatNumber: client?.vatNumber || CLIENT_PROP.NULA_VAT_NUMBER.DEFAULT,
                country: client?.country || CLIENT_PROP.NULA_COUNTRY.DEFAULT,
                iban: client?.iban || CLIENT_PROP.IBAN.DEFAULT,
            } : {}),
            maskedIban: (`${maskedIban || ''} ${ibanOwner || ''}`).trim() || CLIENT_PROP.IBAN.DEFAULT,
            ...(accessCardsCodeEnabled ? {
                accessCardCode: client?.accessCardCode || CLIENT_PROP.ACCESS_CARD_CODE.DEFAULT,
            } : {}),
        });
    }, [client, setValues, visible, enableBusinessMode, accessCardsCodeEnabled]);

    const handleChangeTextField = useCallback(({ field }) => (e) => {
        const { target: { value } } = e;
        setFieldTouched(field, true);
        setFieldValue(field, value);
    }, [setFieldValue, setFieldTouched]);

    const handlePaymentAfetrwardChange = useCallback((e) => {
        const { target } = e;
        const { checked } = target;
        setFieldValue('afterwardPayments', !checked);
    }, [setFieldValue]);

    const handleChangeBirthday = useCallback((birthDate) => {
        setFieldTouched('birthDate', true);
        setFieldValue('birthDate', birthDate);
    }, [setFieldValue, setFieldTouched]);

    return (
        <React.Fragment>
            <Dialog
                visible={visible && !editIbanDialog.visible}
                onClose={onClose}
            >
                <DialogHeader>
                    <DialogTitle>
                        {t(`${T_PREFIX}.header.title`)}
                    </DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <Form
                        id="client-add"
                        onSubmit={handleSubmit}
                    >
                        <Form.Group
                            xs={12}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="firstName">
                                {t(`${T_FIELDS}.firstName.label`)}
                            </Form.Label>
                            <FormInput
                                id="firstName"
                                name="firstName"
                                value={values.firstName}
                                isInvalid={isInvalid('firstName', errors, touched)}
                                onChange={handleChangeTextField({ field: 'firstName' })}
                                regexValidation={NAME_VALIDATION}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    [styles.visible]: validation.firstName?.isInvalid,
                                })}
                            >
                                {validation.firstName?.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            xs={12}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="lastName">
                                {t(`${T_FIELDS}.lastName.label`)}
                            </Form.Label>
                            <FormInput
                                id="lastName"
                                name="lastName"
                                value={values.lastName}
                                isInvalid={isInvalid('lastName', errors, touched)}
                                onChange={handleChangeTextField({ field: 'lastName' })}
                                regexValidation={NAME_VALIDATION}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    [styles.visible]: validation.lastName?.isInvalid,
                                })}
                            >
                                {validation.lastName?.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            xs={12}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="birthDate">
                                {t(`${T_FIELDS}.birthDate.label`)}
                            </Form.Label>
                            <DateInput1
                                value={values.birthDate}
                                onChange={handleChangeBirthday}
                                isInvalid={validation.birthDate?.isInvalid}
                                maxDate={moment()}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    [styles.visible]: validation.birthDate?.isInvalid,
                                })}
                            >
                                {validation.birthDate?.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            xs={12}
                            lg={6}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="email">
                                {t(`${T_FIELDS}.email.label`)}
                            </Form.Label>
                            <FormInput
                                id="email"
                                name="email"
                                disabled={registered}
                                value={values.email}
                                isInvalid={validation.email?.isInvalid}
                                onChange={handleChangeTextField({ field: 'email' })}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    [styles.visible]: validation.email?.isInvalid,
                                })}
                            >
                                {validation.email?.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            xs={12}
                            lg={6}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="phone">
                                {t(`${T_FIELDS}.phone.label`)}
                            </Form.Label>
                            <PhoneInput
                                id="phone"
                                name="phone"
                                disabled
                                value={values.phone}
                                isInvalid={validation.phone?.isInvalid}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    [styles.visible]: validation.phone?.isInvalid,
                                })}
                            >
                                {validation.phone?.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            xs={12}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="companyName">
                                {t(`${T_FIELDS}.companyName.label`)}
                            </Form.Label>
                            <FormInput
                                id="companyName"
                                name="companyName"
                                value={values.companyName}
                                isInvalid={isInvalid('companyName', errors, touched)}
                                onChange={handleChangeTextField({ field: 'companyName' })}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    [styles.visible]: validation.companyName?.isInvalid,
                                })}
                            >
                                {validation.companyName?.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            xs={12}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="street">
                                {t(`${T_FIELDS}.street.label`)}
                            </Form.Label>
                            <FormInput
                                id="street"
                                name="street"
                                value={values.street}
                                isInvalid={isInvalid('street', errors, touched)}
                                onChange={handleChangeTextField({ field: 'street' })}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    [styles.visible]: validation.street?.isInvalid,
                                })}
                            >
                                {validation.street?.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            xs={12}
                            lg={6}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="zipCode">
                                {t(`${T_FIELDS}.zip.label`)}
                            </Form.Label>
                            <FormInput
                                id="zipCode"
                                name="zipCode"
                                value={values.zipCode}
                                isInvalid={isInvalid('zipCode', errors, touched)}
                                onChange={handleChangeTextField({ field: 'zipCode' })}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    [styles.visible]: validation.zipCode?.isInvalid,
                                })}
                            >
                                {validation.zipCode?.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            xs={12}
                            lg={6}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="city">
                                {t(`${T_FIELDS}.city.label`)}
                            </Form.Label>
                            <FormInput
                                id="city"
                                name="city"
                                value={values.city}
                                isInvalid={isInvalid('city', errors, touched)}
                                onChange={handleChangeTextField({ field: 'city' })}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    [styles.visible]: validation.city?.isInvalid,
                                })}
                            >
                                {validation.city?.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            xs={12}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="note" className="d-flex align-items-center">
                                {t(`${T_FIELDS}.note.label`)}
                                <InfoTooltip
                                    text={t(`${T_FIELDS}.note.tooltip`)}
                                    placement="bottom"
                                />
                            </Form.Label>
                            <FormInput
                                id="note"
                                as="textarea"
                                name="note"
                                rows={3}
                                value={values.note}
                                className={styles.note}
                                isInvalid={isInvalid('note', errors, touched)}
                                onChange={handleChangeTextField({ field: 'note' })}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    [styles.visible]: validation.note?.isInvalid,
                                })}
                            >
                                {validation.note?.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Divider horisontal />
                        <Form.Group
                            xs={12}
                            className={styles.formGroup}
                        >
                            <Form.Label htmlFor="maskedIban" className="d-flex align-items-center justify-content-between">
                                {t(`${T_FIELDS}.iban.label`)}
                                <TextButton
                                    before={<Pen2Icon />}
                                    onClick={editIbanDialog.onToggle}
                                >
                                    {t(`${T_FIELDS}.iban.edit.label`)}
                                </TextButton>
                            </Form.Label>
                            <Form.Label htmlFor="maskedIban" className="d-flex align-items-center text-muted font-weight-600">
                                {values.maskedIban || '-'}
                            </Form.Label>
                        </Form.Group>
                        <Divider horisontal />
                        <Form.Group
                            xs={12}
                            className={classNames(
                                styles.formGroup,
                                styles.horizontal,
                            )}
                        >
                            <CheckBox
                                id="afterwardPayments"
                                name="afterwardPayments"
                                checked={!values.afterwardPayments}
                                onChange={handlePaymentAfetrwardChange}
                                label={t(`${T_FIELDS}.afterwardPayments.label`)}
                            />
                        </Form.Group>
                        {false && (
                            <Form.Group
                                xs={12}
                                className={classNames(
                                    styles.formGroup,
                                    styles.horizontal,
                                )}
                            >
                                <CheckBox
                                    id="monthlyInvoicing"
                                    name="monthlyInvoicing"
                                    checked={values.monthlyInvoicing}
                                    onChange={handleChange}
                                    label={t(`${T_FIELDS}.monthlyInvoicing.label`)}
                                />
                            </Form.Group>
                        )}
                        {enableBusinessMode && (
                            <React.Fragment>
                                <Form.Group
                                    xs={12}
                                    className={classNames(styles.formGroup, 'mt-3')}
                                >
                                    <Form.Label htmlFor="iban">
                                        {t(`${T_FIELDS}.iban.label`)}
                                    </Form.Label>
                                    <FormInput
                                        id="iban"
                                        name="iban"
                                        value={values.iban}
                                        isInvalid={isInvalid('iban', errors, touched)}
                                        onChange={handleChangeTextField({ field: 'iban' })}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            [styles.visible]: validation.iban?.isInvalid,
                                        })}
                                    >
                                        {validation.iban?.error}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    xs={12}
                                    className={styles.formGroup}
                                >
                                    <Form.Label htmlFor="country">
                                        {t(`${T_FIELDS}.country.label`)}
                                    </Form.Label>
                                    <FormInput
                                        id="country"
                                        name="country"
                                        value={values.country}
                                        isInvalid={isInvalid('country', errors, touched)}
                                        onChange={handleChangeTextField({ field: 'country' })}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            [styles.visible]: validation.country?.isInvalid,
                                        })}
                                    >
                                        {validation.country?.error}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    xs={12}
                                    className={styles.formGroup}
                                >
                                    <Form.Label htmlFor="vatNumber">
                                        {t(`${T_FIELDS}.vatNumber.label`)}
                                    </Form.Label>
                                    <FormInput
                                        id="vatNumber"
                                        name="vatNumber"
                                        value={values.vatNumber}
                                        isInvalid={isInvalid('vatNumber', errors, touched)}
                                        onChange={handleChangeTextField({ field: 'vatNumber' })}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            [styles.visible]: validation.vatNumber?.isInvalid,
                                        })}
                                    >
                                        {validation.vatNumber?.error}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    xs={12}
                                    className={styles.formGroup}
                                >
                                    <Form.Label htmlFor="cocNumber">
                                        {t(`${T_FIELDS}.cocNumber.label`)}
                                    </Form.Label>
                                    <FormInput
                                        id="cocNumber"
                                        name="cocNumber"
                                        value={values.cocNumber}
                                        isInvalid={isInvalid('cocNumber', errors, touched)}
                                        onChange={handleChangeTextField({ field: 'cocNumber' })}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            [styles.visible]: validation.cocNumber?.isInvalid,
                                        })}
                                    >
                                        {validation.cocNumber?.error}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </React.Fragment>
                        )}
                        {accessCardsCodeEnabled && (
                            <Form.Group
                                xs={12}
                                className={classNames(styles.formGroup, 'mt-3')}
                            >
                                <Form.Label htmlFor="accessCardCode">
                                    {t(`${T_FIELDS}.accessCardCode.label`)}
                                </Form.Label>
                                <FormInput
                                    id="accessCardCode"
                                    name="accessCardCode"
                                    value={values.accessCardCode}
                                    isInvalid={isInvalid('accessCardCode', errors, touched)}
                                    onChange={handleChangeTextField({ field: 'accessCardCode' })}
                                />
                                <Form.Control.Feedback
                                    type="invalid"
                                    className={classNames({
                                        [styles.visible]: validation.accessCardCode?.isInvalid,
                                    })}
                                >
                                    {validation.accessCardCode?.error}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Form>
                </DialogBody>
                <DialogFooter>
                    <Row gap={16}>
                        <Button
                            color="outline"
                            onClick={onClose}
                        >
                            {t(`${T_ACTIONS}.cancel.label`)}
                        </Button>
                        <Button
                            type="submit"
                            form="client-add"
                            disabled={hasErrors.value}
                            loading={isSubmitting}
                        >
                            {t(`${T_ACTIONS}.edit.label`)}
                        </Button>
                    </Row>
                </DialogFooter>
            </Dialog>
            {
                editIbanDialog.visible && (
                    <EditIbanDialog
                        visible={editIbanDialog.visible}
                        onClose={editIbanDialog.onClose}
                        client={client}
                    />
                )
            }
        </React.Fragment>
    );
};

EditClientDialog.propTypes = {
    client: PropTypes.object,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    isRequiredClientAddress: PropTypes.bool,
};

EditClientDialog.defaultProps = {
    client: null,
    visible: false,
    onClose: emptyFunc,
    isRequiredClientAddress: false,
};

export default EditClientDialog;
