import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { PRODUCT_TYPE } from 'const/product/PRODUCT_TYPE';
import { CommonConfirmModal } from 'components/Common/CommonConfirmModal';
import { isArray } from 'lodash';

const NOT_NOTIFY_CLIENT_SHOWN = [PRODUCT_TYPE.EMPLOYEE, PRODUCT_TYPE.OBJECT];

const AddBookingConfirmModal = ({
    isShow,
    hide,
    loading,
    confirmAction,
    paymentOpts,
    restrictUnpaidBookings,
    recurrence,
    price,
    productType,
}) => {
    const { t } = useTranslation();
    const [notNotifyClient, setNotNotifyClient] = useState(false);

    const bookingConfirmOpts = useMemo(() => {
        const result = [
            {
                name: 'subscription',
                label: t('addBookingConfirmModal.fromSubscription.label'),
                disabledTooltip: t('addBookingConfirmModal.fromSubscription.tooltip'),
                type: 'subscription',
                paid: true,
                disabled: !paymentOpts?.find((opt) => opt.payment_type === 'subscription'),
            },
            {
                name: 'bundle',
                label: t('addBookingConfirmModal.fromBundle.label'),
                disabledTooltip: t('addBookingConfirmModal.fromBundle.tooltip'),
                type: 'bundle',
                paid: true,
                disabled: !paymentOpts?.find((opt) => opt.payment_type === 'bundle'),
            },
        ];
        if (price === 0) {
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.manual.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.manual.tooltip'),
                type: 'cash',
                paid: true,
                disabled: !paymentOpts?.find((opt) => opt.payment_type === 'cash') || restrictUnpaidBookings,
            });
        } else if (recurrence) {
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.unpaid.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.unpaid.tooltip'),
                type: 'cash',
                paid: false,
                disabled: !paymentOpts?.find((opt) => opt.payment_type === 'cash'),
            });
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.paid.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.paid.tooltip'),
                type: 'cash',
                paid: true,
                disabled: !paymentOpts?.find((opt) => opt.payment_type === 'cash'),
            });
        } else {
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.ideal.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.ideal.tooltip'),
                type: 'ideal',
                paid: false,
                disabled: !paymentOpts?.find((opt) => opt.payment_type === 'cash') || restrictUnpaidBookings,
            });
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.cash.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.cash.tooltip'),
                type: 'cash',
                paid: true,
                disabled: !paymentOpts?.find((opt) => opt.payment_type === 'cash'),
            });
        }
        const terminals = paymentOpts?.find((opt) => isArray(opt.payment_terminals));
        if (terminals) {
            result.push({
                name: 'terminal',
                label: t('addBookingConfirmModal.terminal.label'),
                disabledTooltip: undefined,
                tooltip: t('addBookingConfirmModal.terminal.tooltip'),
                type: 'ideal',
                paid: false,
                disabled: false,
                terminals: terminals.payment_terminals,
            });
        }
        return result;
    }, [restrictUnpaidBookings, price, recurrence, paymentOpts, t]);

    const checkboxConfig = useMemo(() => {
        const notNotifyClientShown = NOT_NOTIFY_CLIENT_SHOWN.includes(productType) && price === 0;
        return notNotifyClientShown
            ? {
                visible: true,
                label: t('addBookingConfirmModal.notNotifyClient'),
                checked: notNotifyClient,
                onChange: () => setNotNotifyClient((prev) => !prev),
            }
            : null;
    }, [notNotifyClient, price, productType, t]);

    return (
        <CommonConfirmModal
            isShow={isShow}
            onClose={hide}
            onConfirm={(selectedOption) => confirmAction({ option: selectedOption, notNotifyClient })}
            options={bookingConfirmOpts}
            title={t('addBookingConfirmModal.title')}
            subHeader={t('addBookingConfirmModal.bookingWillBe')}
            loading={loading}
            checkboxConfig={checkboxConfig}
            backText={t('addBookingConfirmModal.back')}
            confirmText={t('addBookingConfirmModal.yes')}
            confirmDisabledTooltip={t('addBookingConfirmModal.yesDisabledTooltip')}
        />
    );
};

export default AddBookingConfirmModal;
