// extracted by mini-css-extract-plugin
var _1 = "Panel_borderRadius4__B0Z8e";
var _2 = "Panel_borderRadius8__XJp-Y";
var _3 = "Panel_gap0__J5GQu";
var _4 = "Panel_gap12__16av8";
var _5 = "Panel_gap16__9hWhr";
var _6 = "Panel_gap24__Y2suj";
var _7 = "Panel_gap32__Ai4Dp";
var _8 = "Panel_gap4__Gom6j";
var _9 = "Panel_gap8__Zfa97";
var _a = "Panel_padding0__cnS43";
var _b = "Panel_padding12__Ew5wa";
var _c = "Panel_padding16__dadP4";
var _d = "Panel_padding24__hPl1q";
var _e = "Panel_padding32__z2U89";
var _f = "Panel_panel__wjuUQ";
var _10 = "Panel_primary__vnym5";
var _11 = "Panel_red__TInd4";
var _12 = "Panel_secondary__neiY8";
var _13 = "Panel_stretched__6Et8b";
export { _1 as "borderRadius4", _2 as "borderRadius8", _3 as "gap0", _4 as "gap12", _5 as "gap16", _6 as "gap24", _7 as "gap32", _8 as "gap4", _9 as "gap8", _a as "padding0", _b as "padding12", _c as "padding16", _d as "padding24", _e as "padding32", _f as "panel", _10 as "primary", _11 as "red", _12 as "secondary", _13 as "stretched" }
