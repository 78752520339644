import Payouts from './payouts';
import Billing from './billing';
import MultisafePayouts from './multisafePayouts';
import Clearings from './clearings';

const routes = [
    {
        component: MultisafePayouts,
        exact: true,
        multisafe: true,
        path: '/financials/accounting/multisafe-payouts',
    },
    {
        component: Clearings,
        exact: true,
        multisafe: false,
        path: '/financials/accounting/clearings',
    },
    {
        component: Payouts,
        exact: true,
        multisafe: false,
        path: '/financials/accounting/payouts',
    },
    {
        component: Billing,
        exact: true,
        multisafe: undefined,
        path: '/financials/accounting/billing',
    },
];

export default routes;
