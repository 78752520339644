import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Common/Button';
import { Dialog } from '../Dialog/Dialog';
import { DialogHeader } from '../Dialog/DialogHeader';
import { DialogTitle } from '../Dialog/DialogTitle';
import { DialogBody } from '../Dialog/DialogBody';
import { DialogFooter } from '../Dialog/DialogFooter';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';

const ConfirmDialog = (props) => {
    const {
        size,
        text,
        title,
        visible,
        rejectProps,
        confirmProps,
        onClose,
        onReject,
        onConfirm,
        onConfirmHref,
    } = props;

    return (
        <Dialog
            size={size}
            visible={visible}
            onClose={onClose}
            data-testid="data-test-confirm-dialog"
        >
            <DialogHeader data-testid="data-test-confirm-dialog-header">
                <DialogTitle data-testid="data-test-confirm-dialog-title">
                    {title}
                </DialogTitle>
            </DialogHeader>
            <DialogBody data-testid="data-test-confirm-dialog-text">
                {text}
            </DialogBody>
            <DialogFooter data-testid="data-test-confirm-dialog-footer">
                {onReject ? (
                    <Button
                        color="outline"
                        disabled={rejectProps.isDisabled || confirmProps.isLoading}
                        className={rejectProps.className}
                        onClick={onReject}
                        data-testid="data-test-confirm-dialog-reject-button"
                    >
                        {rejectProps.label}
                    </Button>
                ) : <div />}
                {(onConfirm || onConfirmHref) ? (
                    <Button
                        variant="danger"
                        disabled={confirmProps.isDisabled}
                        loading={confirmProps.isLoading}
                        className={confirmProps.className}
                        onClick={onConfirm}
                        href={onConfirmHref}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="data-test-confirm-dialog-confirm-button"
                    >
                        {confirmProps.label}
                    </Button>
                ) : <div />}
            </DialogFooter>
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    size: PropTypes.oneOf([
        'sm',
        'md',
        'lg',
        'xl',
    ]),
    text: PropTypes.node,
    title: PropTypes.node,
    visible: PropTypes.bool,
    rejectProps: PropTypes.shape({
        label: PropTypes.node,
        className: PropTypes.string,
        isLoading: PropTypes.bool,
        isDisabled: PropTypes.bool,
    }),
    confirmProps: PropTypes.shape({
        label: PropTypes.node,
        className: PropTypes.string,
        isLoading: PropTypes.bool,
        isDisabled: PropTypes.bool,
    }),
    onClose: PropTypes.func,
    onReject: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onConfirm: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onConfirmHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ConfirmDialog.defaultProps = {
    size: 'md',
    text: '',
    title: '',
    visible: false,
    rejectProps: {
        label: '',
        className: '',
        isLoading: false,
        isDisabled: false,
    },
    confirmProps: {
        label: '',
        className: '',
        isLoading: false,
        isDisabled: false,
    },
    onClose: emptyFunc,
    onReject: null,
    onConfirm: null,
    onConfirmHref: null,
};

export default ConfirmDialog;
