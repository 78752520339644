import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
    items: [],
    isDataLoaded: false,
    currentPage: 1,
    pagesCount: 0,
    itemsCount: 0,
    searchValue: '',
    showAll: false,
    hideArchived: true,
};

export const subscriptionsListReducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
    case actionTypes.RESET_SUBSCRIPTIONS_REDUCER:
        return {
            ...initialState,
        };
    case actionTypes.SET_SUBSCRIPTIONS_LIST:
        const { list } = payload;

        return ({
            ...state,
            items: list,
        });
    case actionTypes.SET_IS_DATA_LOADED:
        const { value } = payload;

        return ({
            ...state,
            isDataLoaded: value,
        });
    case actionTypes.SET_PAGES_COUNT:
        const { pagesCount } = payload;

        return ({
            ...state,
            pagesCount,
        });
    case actionTypes.SET_ITEMS_COUNT:
        const { itemsCount } = payload;

        return ({
            ...state,
            itemsCount,
        });
    case actionTypes.UPDATE_SUBSCRIPTION_STATUS:
        const { subscriptionId, status } = payload;

        return ({
            ...state,
            items: state.items.map((listItem) => {
                if (listItem.id === subscriptionId) {
                    // eslint-disable-next-line no-param-reassign
                    listItem.active = status;
                }

                return listItem;
            }),
        });
    case actionTypes.SET_SHOW_ALL:
        const { showAll } = payload;
        const page = showAll ? 0 : 1;

        return ({
            ...state,
            showAll,
            currentPage: page,
        });
    case actionTypes.SET_CURRENT_PAGE:
        const { currentPage } = payload;

        return ({
            ...state,
            currentPage,
        });
    case actionTypes.SET_SEARCH_VALUE:
        const { searchValue } = payload;

        return ({
            ...state,
            currentPage: state.currentPage && 1,
            searchValue,
        });
    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return initialState;
    case actionTypes.TOGGLE_SUBSCRIPTIONS_HIDE_ARCHIVED: {
        const { hideArchived } = payload.payload;
        return {
            ...state,
            hideArchived,
        };
    }
    case actionTypes.DELETE_SUBSCRIPTION_RESOLVED: {
        const { subscriptionId } = payload.payload;
        return {
            ...state,
            items: state.items.map((item) => {
                if (item.id === subscriptionId) {
                    return {
                        ...item,
                        isArchived: !item.isArchived,
                    };
                }
                return item;
            }),
        };
    }
    default:
        return state;
    }
};
