import {
    call, put, select, takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { resolvePromiseAction } from '@adobe/redux-saga-promise';
import { downloadFile } from '../../../helpers/file/downloadFile';
import { getDefaultHeaders } from '../../../helpers/http/getDefaultHeaders';
import * as BILLINGS_ACTIONS from '../../actions/financials/billings';
import * as FINANCIALS_SELECTORS from '../../selectors/financilas';

import * as TOAST_ACTIONS from '../../actions/toast';

import axios from '../../../services/axios';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_COMPANY_PAYOUT_EXTENDED_LIST_ROUTE,
    API_ADMIN_COMPANY_PAYOUT_DOWNLOAD_BY_ID_ROUTE,
} from '../../../const/API_URL';

function* getBillings(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { page, sortOptions } = yield select(
        FINANCIALS_SELECTORS.billingsListSelector,
    );

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_PAYOUT_EXTENDED_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                page,
                ...sortOptions,
            },
        });

        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const { items, itemsCount, pagesCount } = result;
            const billings = items.map((item) => camelize(item));
            yield call(resolvePromiseAction, action, { items: billings, itemsCount, pagesCount });
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'billings.get.error',
                    appearance: 'error',
                }),
            );
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* downloadBilling(action) {
    const {
        payload: { billingId },
    } = action;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_PAYOUT_DOWNLOAD_BY_ID_ROUTE({ id: billingId }),
            headers: defaultHeaders,
        });

        const { data, status } = res;
        if (status === HTTP_STATUS_CODE.OK) {
            const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/pdf' }),
            );
            downloadFile({ url });
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'billings.download.error',
                    appearance: 'error',
                }),
            );
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export const billingsListSaga = [
    takeLatest(BILLINGS_ACTIONS.getBillings, getBillings),
    takeLatest(BILLINGS_ACTIONS.downloadBilling, downloadBilling),
];
