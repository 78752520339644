import React from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

// Components
import { Modal, Nav, Tab } from 'react-bootstrap';

// Utils
import _ from 'lodash';
import * as actions from 'store/actions';
import { permissionsGroupSessionSelector } from 'store/selectors/permissions';
import { Button } from 'components/Common/Button';
import { Content } from 'components/Common/Content';
import { Column } from 'components/Common/Column';
import { GROUP_DETAILS_MODAL_TAB } from 'const/group/GROUP_DETAILS_MODAL_TAB';
import { companyWaitlistEnabled } from 'store/selectors/company';
import moment from 'moment';
import {
    ConfirmationModal, BookingModal, EditBookingModal, OverrideGroupModal,
} from '..';
import SpotsBooked from './SpotsBooked';
import GroupDetailsPanel from './GroupDetailsPanel';
import Waitlist from './Waitlist';

const GroupDetailsModal = ({
    groupSessionIDWithTimeStamp, show, onHide, tab, onTabChange,
}) => {
    const session = useSelector((state) => state.groups.sessions && state.groups.sessions.find((session) => session.id === groupSessionIDWithTimeStamp?.id && session.time.from === groupSessionIDWithTimeStamp?.timestamp));
    const { bookings, waitlist } = useSelector((state) => session && state.groups) ?? { bookings: [], waitlist: [] };
    const areBookingDetailsPresent = useSelector((state) => !!state.bookings?.addBookingDetails);
    const { banBooking, banCancel } = useSelector(permissionsGroupSessionSelector);
    const waitlistEnabled = useSelector(companyWaitlistEnabled);
    const hasWhaitlist = Boolean(waitlist?.length);
    const addBookingDisabled = Boolean(session?.isCancelled || banBooking || !session?.time || session?.isFull);
    const hasAvaliableSpots = Boolean(session?.places?.available && !addBookingDisabled);
    const selectedTab = hasWhaitlist ? tab : GROUP_DETAILS_MODAL_TAB.SpotsBooked;

    const dispatch = useDispatch();

    const [clickedBookingID, setClickedBookingID] = useState(null);
    const [editBooking, setEditBooking] = useState(false);
    const [overrideGroup, setOverrideGroup] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [isCancelingSession, setIsCancellingSession] = useState(false);

    useEffect(() => {
        if (!session) {
            return;
        }

        dispatch(actions.getGroupSessionBookings(session.id, session.time.from));
    }, [session?.places?.available]);

    const { addToast } = useToasts();

    const { t } = useTranslation();

    const cancelHandler = async () => {
        if (banCancel || isCancelingSession) {
            return;
        }

        setIsCancellingSession(true);
        try {
            await dispatch(actions.cancelGroupSession({
                id: session?.id,
                timestamp: (session?.time.from || 0) / 1000,
            }));
            addToast(t('groupDetailsModal.statusGroupSessionSuccess'), {
                appearance: 'success',
            });
            setCancelModalVisible(false);
        } catch ({ message }) {
            if (message) {
                addToast(message, {
                    appearance: 'error',
                });
            }
        } finally {
            setIsCancellingSession(false);
        }
    };

    const handleBookingDetails = ({ client, service }) => {
        dispatch(actions.setAddBookingDetails({
            time: moment(session?.time?.from),
            product: {
                id: session?.group?.id,
                name: session?.group?.name,
                type: 'group',
                groupSessionId: session?.id,
            },
            service: {
                id: session?.service?.id,
                name: session?.service?.name,
            },
            subOptionService: service,
            client,
        }));
    };

    const handleAddToSession = ({ client, service }) => {
        handleBookingDetails({ client, service });
    };

    return (
        <React.Fragment>
            <Modal
                size="lg"
                show={show && !clickedBookingID && !areBookingDetailsPresent && !overrideGroup && !cancelModalVisible}
                onHide={onHide}
                scrollable
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="mb-0 font-weight-600" data-testid="data-test-group-details-modal-title">
                        {t('groupDetailsModal.title')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-0">
                    <Content loading={!session || !bookings}>
                        <Column gap={10} stretched>
                            <GroupDetailsPanel session={session} onCancel={setCancelModalVisible} onOverride={setOverrideGroup} />
                            {waitlistEnabled && hasWhaitlist && (
                                <Tab.Container
                                    defaultActiveKey={selectedTab}
                                    onSelect={onTabChange}
                                >
                                    <Nav>
                                        <Nav.Link eventKey={GROUP_DETAILS_MODAL_TAB.SpotsBooked}>
                                            {t('groupDetailsModal.tabs.spotsBooked')}
                                        </Nav.Link>
                                        <Nav.Link eventKey={GROUP_DETAILS_MODAL_TAB.Waitlist}>
                                            {t('groupDetailsModal.tabs.waitlist')}
                                        </Nav.Link>
                                    </Nav>
                                </Tab.Container>
                            )}
                            {selectedTab === GROUP_DETAILS_MODAL_TAB.SpotsBooked && (
                                <SpotsBooked
                                    bookings={bookings}
                                    onBookingClick={setClickedBookingID}
                                    addBookingDisabled={addBookingDisabled}
                                    total={session?.places?.total}
                                    available={session?.places?.available}
                                    onAddBooking={handleBookingDetails}
                                />
                            )}
                            {selectedTab === GROUP_DETAILS_MODAL_TAB.Waitlist && (
                                <Waitlist
                                    waitlist={waitlist}
                                    onAddToSession={handleAddToSession}
                                    hasAvaliableSpots={hasAvaliableSpots}
                                />
                            )}
                        </Column>
                    </Content>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-between">
                    <Button
                        name="close"
                        color="outline"
                        onClick={() => setTimeout(onHide, 100)}
                    >
                        {t('groupDetailsModal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {clickedBookingID && (
                <BookingModal
                    bookingIDWithTimestamp={clickedBookingID}
                    show={clickedBookingID && !editBooking}
                    onHide={() => setClickedBookingID(null)}
                    onEditChange={() => setEditBooking(true)}
                    productType="group"
                />
            )}

            <EditBookingModal
                bookingIDWithTimestamp={clickedBookingID}
                onBookingClick={setClickedBookingID}
                show={editBooking}
                onHide={() => setEditBooking(false)}
                productType="group"
            />

            <OverrideGroupModal
                show={overrideGroup}
                onHide={() => setOverrideGroup(false)}
                id={session?.id}
                timestamp={(session?.time.from || 0) / 1000}
                registrations={session?.places.total}
            />

            <ConfirmationModal
                isShow={cancelModalVisible}
                hide={() => setCancelModalVisible(false)}
                loading={isCancelingSession}
                confirmAction={cancelHandler}
                deleteColor={session?.isCancelled ? 'green' : 'red'}
                titleText={`${t(`groupDetailsModal.${session?.isCancelled ? 'reactivate' : 'cancel'}`)} ${t('groupDetailsModal.groupSession')}`}
                bodyText={t(`groupDetailsModal.areYouSureYouWantTo${session?.isCancelled ? 'Reactivate' : 'Cancel'}Session`)}
                deleteText={t(`groupDetailsModal.${session?.isCancelled ? 'reactivate' : 'cancel'}`)}
                dismissText={t('groupDetailsModal.back')}
            />
        </React.Fragment>
    );
};

export default GroupDetailsModal;
