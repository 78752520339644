import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isNumber } from 'lodash';
import * as styles from './Column.module.scss';

const paddingClasses = {
    0: styles.padding0,
    4: styles.padding4,
    8: styles.padding8,
    12: styles.padding12,
    16: styles.padding16,
    24: styles.padding24,
    32: styles.padding32,
};

const spacingClasses = {
    0: styles.spacing0,
    4: styles.spacing4,
    8: styles.spacing8,
    12: styles.spacing12,
    16: styles.spacing16,
    24: styles.spacing24,
    32: styles.spacing32,
};

const gapClasses = {
    0: styles.gap0,
    4: styles.gap4,
    8: styles.gap8,
    10: styles.gap10,
    12: styles.gap12,
    16: styles.gap16,
    24: styles.gap24,
    32: styles.gap32,
};

const justifyClasses = {
    start: styles.justifyStart,
    center: styles.justifyCenter,
    end: styles.justifyEnd,
    between: styles.justifyBetween,
    around: styles.justifyAround,
};

const Column = (props) => (
    <div
        name={props.name}
        className={
            classNames(
                styles.column,
                paddingClasses[props.padding],
                spacingClasses[props.spacing],
                gapClasses[props.gap],
                props.stretched === true ? styles.stretched : '',
                styles[props.align],
                props.justify && justifyClasses[props.justify],
                props.shrink && styles.shrink,
            )
        }
        style={{
            width: isNumber(props.stretched) ? `${props.stretched}%` : undefined,
        }}
        data-testid={props['data-testid']}
    >
        {props.children}
    </div>
);

Column.propTypes = {
    padding: PropTypes.oneOf(Object.keys(paddingClasses).map(Number)),
    spacing: PropTypes.oneOf(Object.keys(spacingClasses).map(Number)),
    gap: PropTypes.oneOf(Object.keys(gapClasses).map(Number)),
    stretched: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    align: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
    children: PropTypes.node.isRequired,
    justify: PropTypes.oneOf(Object.keys(justifyClasses)),
    'data-testid': PropTypes.string,
    shrink: PropTypes.bool,
};

Column.defaultProps = {
    gap: 8,
    spacing: 0,
    padding: 0,
    stretched: false,
    align: 'start',
    'data-testid': undefined,
    shrink: false,
};

export default Column;
