import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import CreatableSelect from 'react-select/async-creatable';
import { WindowedMenuList } from 'react-windowed-select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TextButton from 'components/Common/TextButton/TextButton';
import { Label } from 'components/Common/Typography/Label';
import { Row } from 'components/Common/Row';
import { useMobile } from 'hooks/useMobile';
import { Column } from 'components/Common/Column';
import { Header } from 'components/Common/Typography/Header';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';
import { generateRandomString } from '../../../../helpers/string/generateRandomString';
import { useDialog } from '../../../../hooks/useDialog';

import PhoneInput from '../../../PhoneInput/PhoneInput';
import { ClientSelectOption } from './ClientSelectOption';
import { AddClientDialog } from '../../../clients/AddClientDialog';

import {
    dangerTheme as selectDangerTheme,
    theme as selectTheme,
    styles as selectStyles,
} from '../../../../styles/select';

import * as CLIENTS_ACTIONS from '../../../../store/actions/clients';
import * as CLIENTS_SELECTORS from '../../../../store/selectors/clients';
import * as COMPANY_SELECTORS from '../../../../store/selectors/company';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'sell.form';
const T_FIELDS = `${T_PREFIX}.fields`;

const ClientFormRow = (props) => {
    const {
        values,
        disabled,
        validation,
        onGetClients,
        onChangeClient,
        onCreateClient,
        onChangeClientPhone,
        onChangeClientEmail,
    } = props;

    const isMobile = useMobile();
    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const dispatch = useDispatch();

    const [clientNameAsyncSelectKey, setClientNameAsyncSelectKey] = useState(generateRandomString());
    const [isNecessarySetNewClientToValue, setIsNecessarySetNewClientToValue] = useState(false);

    const { item: client, loading: isClientLoading } = useSelector(CLIENTS_SELECTORS.clientItemSelector);
    const isRequiredClientAddress = useSelector(COMPANY_SELECTORS.companyIsRequiredClientAddressSelector);

    const addNewClientDialog = useDialog(false);

    const clientSelectTheme = validation?.client?.name?.isInvalid ? selectDangerTheme : selectTheme;

    const clientValue = useMemo(() => ({
        value: values.client.id,
        label: `${values.client.name} ${values.client.lastName ?? ''}`,
    }), [
        values.client.id,
        values.client.name,
        values.client.lastName,
    ]);

    const onClientNameLoadOptions = useCallback(async (query) => {
        try {
            const clients = await onGetClients({ query });
            return (clients || []).map((c) => ({
                value: c.id,
                name: c.name,
                lastName: c.lastName,
                phone: c.contactNumber,
                email: c.email,
            }));
        } catch (e) {
            return e;
        }
    }, [onGetClients]);

    const onClientNameCreateLabel = useCallback((name) => (
        // `${t(`${T_FIELDS}.client.name.createLabel`)}: ${name}`
        <TextButton
            onClick={onClickAddNewClient}
            after={name}
            color="yellow"
            afterColor="black"
        >
            {`+ ${tc('addBookingModal.addNewClient')} `}
        </TextButton>
    ), [tc]);

    const onClientNameLoadOptionForce = useCallback(() => {
        setClientNameAsyncSelectKey(generateRandomString());
    }, []);

    const onClickAddNewClient = useCallback((event) => {
        event.preventDefault();
        addNewClientDialog.onShow();
    });

    const onAddNewClientSuccess = useCallback(({ data }) => {
        const newClientId = data.id;
        onClientNameLoadOptionForce();
        setIsNecessarySetNewClientToValue(true);
        dispatch(CLIENTS_ACTIONS.getClientItem({ clientId: newClientId }));
    }, []);

    useEffect(() => {
        if (!isClientLoading && isNecessarySetNewClientToValue) {
            setIsNecessarySetNewClientToValue(false);
            const formatClient = {
                value: client.id,
                name: client.fullName.firstName,
                lastName: client.fullName.lastName,
                phone: client.contactInfo.phone,
                email: client.contactInfo.email,
            };
            onChangeClient(formatClient);
        }
    }, [isClientLoading]);

    const isClientSelected = Boolean(values.client.id);

    return (
        <React.Fragment>
            <Column gap={16} stretched>
                <Row gap={16} stretched justify="between">
                    <Header>
                        {tc('addBookingModal.client')}
                    </Header>

                    {!disabled && (
                        <TextButton color="yellow" onClick={onClickAddNewClient}>
                            {`+ ${tc('addBookingModal.addNewClient')}`}
                        </TextButton>
                    )}
                </Row>
                <Column gap={8} stretched>
                    <Label>
                        {t(`${T_FIELDS}.client.name.label`)}
                    </Label>
                    {disabled ? (
                        <Form.Control
                            name="client.name"
                            value={clientValue.label}
                            disabled
                        />
                    ) : (
                        <CreatableSelect
                            components={{ MenuList: WindowedMenuList }}
                            key={clientNameAsyncSelectKey}
                            name="client.name"
                            value={clientValue}
                            theme={clientSelectTheme}
                            styles={selectStyles}
                            isClearable
                            loadOptions={onClientNameLoadOptions}
                            isSearchable
                            cacheOptions
                            defaultOptions
                            formatCreateLabel={onClientNameCreateLabel}
                            formatOptionLabel={ClientSelectOption}
                            onChange={onChangeClient}
                            onCreateOption={onCreateClient}
                            placeholder={t(`${T_FIELDS}.client.name.placeholder`)}
                        />
                    )}
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.client?.name?.isInvalid,
                        })}
                    >
                        {validation?.client?.name?.error}
                    </Form.Control.Feedback>
                </Column>

                {isClientSelected && (
                    <Row stretched gap={16} wrap={isMobile} align="start">
                        <Column gap={8} stretched justify="start">
                            <Label>
                                {t(`${T_FIELDS}.client.phone.label`)}
                            </Label>
                            <PhoneInput
                                name="client.phone"
                                value={values.client.phone}
                                disabled={Boolean(disabled || values.client.id)}
                                isInvalid={validation?.client?.phone?.isInvalid}
                                onChange={onChangeClientPhone}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-block': validation?.client?.phone?.isInvalid,
                                })}
                            >
                                {validation?.client?.phone?.error}
                            </Form.Control.Feedback>
                        </Column>
                        <Column gap={8} stretched>
                            <Label>
                                {t(`${T_FIELDS}.client.email.label`)}
                            </Label>
                            <Form.Control
                                name="client.email"
                                value={values.client.email}
                                disabled={Boolean(disabled || values.client.id)}
                                isInvalid={validation?.client?.email?.isInvalid}
                                onChange={onChangeClientEmail}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-block': validation?.client?.email?.isInvalid,
                                })}
                            >
                                {validation?.client?.email?.error}
                            </Form.Control.Feedback>
                        </Column>
                    </Row>
                )}
            </Column>

            <AddClientDialog
                visible={addNewClientDialog.visible}
                onClose={addNewClientDialog.onClose}
                onSuccess={onAddNewClientSuccess}
                isRequiredClientAddress={isRequiredClientAddress}
            />
        </React.Fragment>
    );
};

ClientFormRow.propTypes = {
    values: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    validation: PropTypes.object,
    onGetClients: PropTypes.func,
    onChangeClient: PropTypes.func,
    onCreateClient: PropTypes.func,
    onChangeClientPhone: PropTypes.func,
    onChangeClientEmail: PropTypes.func,
};

ClientFormRow.defaultValues = {
    validation: null,
    disabled: false,
    onGetClients: emptyFunc,
    onChangeClient: emptyFunc,
    onCreateClient: emptyFunc,
    onChangeClientPhone: emptyFunc,
    onChangeClientEmail: emptyFunc,
};

export default ClientFormRow;
