import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Form } from 'react-bootstrap';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { Column } from 'components/Common/Column';
import { Header } from 'components/Common/Typography/Header';
import { Label } from 'components/Common/Typography/Label';
import { useMobile } from 'hooks/useMobile';
import { Row } from 'components/Common/Row';
import { CheckBox } from 'components/Common/CheckBox';
import Select from 'react-select';
import {
    styles as selectStyles,
    theme as selectTheme,
} from 'styles/select';
import ProfileSubscriptionSelect from '../ProfileSubscriptionSelect';
import ProfileBundleSelect from '../ProfileBundleSelect';

const ProfilesAccessRules = ({ formik }) => {
    const {
        values,
        setFieldValue,
    } = formik;
    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const isMobile = useMobile();

    const handleWalkInSchedule = useCallback((e) => {
        const { value } = e.target;
        setFieldValue('rule', value);
    }, []);

    const handleDeductUsageChange = useCallback((e) => {
        const { target } = e;
        const { checked } = target;
        setFieldValue('deductUsage', checked);
    }, [setFieldValue]);

    const walkInSchedule = values.rule === 'walk_in_match' || values.rule === 'walk_in_always';
    const bookingType = values.rule === 'booking' || values.rule === 'booking_limited';

    return (
        <Column gap={32} stretched>
            <Header after={(
                <InfoTooltip
                    text={t('profiles.add.accessRules.tooltip')}
                    placement="top"
                />
            )}
            >
                {t('profiles.add.accessRules.label')}
            </Header>
            <Column gap={12} stretched>
                <Column gap={12} stretched>
                    <Form.Check
                        id="booking"
                        type="radio"
                        name="rule"
                        value="booking"
                        label={(
                            <Label
                                htmlFor="booking"
                                tooltip={t('profiles.add.accessRules.booking.tooltip')}
                            >
                                {t('profiles.add.accessRules.booking.label')}
                            </Label>
                        )}
                        checked={bookingType}
                        onChange={handleWalkInSchedule}
                    />
                    {(bookingType) && (
                        <Column padding={32} stretched>
                            <Select
                                name="services"
                                styles={selectStyles}
                                theme={selectTheme}
                                isMulti
                                isClearable={false}
                                placeholder={tc('placeholders.allServices')}
                                isDisabled
                            />
                            <Column gap={12}>
                                <Form.Check
                                    id="bookingUnlimited"
                                    type="radio"
                                    name="bookingType"
                                    value="booking"
                                    label={(
                                        <Label
                                            htmlFor="bookingUnlimited"
                                            tooltip={t('profiles.add.accessRules.booking.unlimited.tooltip')}
                                        >
                                            {t('profiles.add.accessRules.booking.unlimited.label')}
                                        </Label>
                                    )}
                                    checked={values.rule === 'booking'}
                                    onChange={handleWalkInSchedule}
                                />
                                <Form.Check
                                    id="bookingLimited"
                                    type="radio"
                                    name="bookingType"
                                    value="booking_limited"
                                    label={(
                                        <Label
                                            htmlFor="bookingLimited"
                                            tooltip={t('profiles.add.accessRules.booking.limited.tooltip')}
                                        >
                                            {t('profiles.add.accessRules.booking.limited.label')}
                                        </Label>
                                    )}
                                    checked={values.rule === 'booking_limited'}
                                    onChange={handleWalkInSchedule}
                                />
                            </Column>
                        </Column>
                    )}
                </Column>
                <Column gap={12} stretched>
                    <Form.Check
                        id="walkInSchedule"
                        type="radio"
                        name="rule"
                        value="walk_in_match"
                        label={(
                            <Label
                                htmlFor="walkInSchedule"
                                tooltip={t('profiles.add.accessRules.walkIn.tooltip')}
                            >
                                {t('profiles.add.accessRules.walkIn.label')}
                            </Label>
                        )}
                        checked={walkInSchedule}
                        onChange={handleWalkInSchedule}
                    />
                    {(walkInSchedule) && (
                        <Column padding={32} stretched gap={16}>
                            <Row stretched gap={32} wrap={isMobile}>
                                <ProfileSubscriptionSelect
                                    namespace="accessRules.subscriptions"
                                    namespaceValues={values.accessRules.subscriptions}
                                    setFieldValue={setFieldValue}
                                    touched={formik.touched}
                                    errors={formik.errors}
                                />
                                <ProfileBundleSelect
                                    namespace="accessRules.bundles"
                                    namespaceValues={values.accessRules.bundles}
                                    setFieldValue={setFieldValue}
                                    touched={formik.touched}
                                    errors={formik.errors}
                                />
                            </Row>
                            <Column gap={12}>
                                <Form.Check
                                    id="matchCompanyWorkTime"
                                    type="radio"
                                    name="walkInSchedule"
                                    value="walk_in_match"
                                    label={(
                                        <Label
                                            htmlFor="matchCompanyWorkTime"
                                            tooltip={t('profiles.add.accessRules.walkInSchedule.matchCompanyWorkTime.tooltip')}
                                        >
                                            {t('profiles.add.accessRules.walkInSchedule.matchCompanyWorkTime.label')}
                                        </Label>
                                    )}
                                    checked={values.rule === 'walk_in_match'}
                                    onChange={handleWalkInSchedule}
                                />
                                <Form.Check
                                    id="alwaysOpen"
                                    type="radio"
                                    name="walkInSchedule"
                                    value="walk_in_always"
                                    label={(
                                        <Label
                                            htmlFor="alwaysOpen"
                                            tooltip={t('profiles.add.accessRules.walkInSchedule.alwaysOpen.tooltip')}
                                        >
                                            {t('profiles.add.accessRules.walkInSchedule.alwaysOpen.label')}
                                        </Label>
                                    )}
                                    checked={values.rule === 'walk_in_always'}
                                    onChange={handleWalkInSchedule}
                                />
                            </Column>

                            <CheckBox
                                id="deductUsage"
                                name="deductUsage"
                                checked={values.deductUsage}
                                onChange={handleDeductUsageChange}
                                label={t('profiles.add.accessRules.deductUsage.label')}
                                tooltip={t('profiles.add.accessRules.deductUsage.tooltip')}
                            />
                        </Column>
                    )}
                </Column>
            </Column>

        </Column>
    );
};

export default ProfilesAccessRules;
