import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import TextButton from 'components/Common/TextButton/TextButton';
import { Row } from 'components/Common/Row';
import { useMobile } from 'hooks/useMobile';
import { Label } from 'components/Common/Typography/Label';
import { Column } from 'components/Common/Column';
import { Header } from 'components/Common/Typography/Header';
import { SubHeader } from 'components/Common/Typography/SubHeader';
import { Button } from 'components/Common/Button';
import { getFullName } from '../../helpers/services/getFullName';
import { CLIENTS_LIST_ID_PAGE } from '../../const/CLIENT_URL';

const Waitlist = ({ waitlist, hasAvaliableSpots, onAddToSession }) => {
    const isMobile = useMobile();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Row stretched justify="between" wrap={isMobile}>
                <Row gap={8}>
                    <Header>{t('groupDetailsModal.tabs.waitlist')}</Header>
                    {waitlist && (
                        <SubHeader color="gray" weight="bold" data-testid="data-test-total-waitlist">
                            {`${waitlist.length}`}
                        </SubHeader>
                    )}
                </Row>
            </Row>
            <Column gap={0} stretched>
                {waitlist?.map(({ client, service }) => (
                    <div
                        key={`booking-${client.id}`}
                        className="border-gray-200  d-flex flex-sm-row flex-column justify-content-between mt-3 pl-3 border-left border-5 w-100"
                        data-testid={`data-test-row-${getFullName({ client })}`}
                    >
                        <Row gap={8} justify="between" stretched wrap={isMobile}>
                            <Row gap={8} align="center">
                                <TextButton
                                    color="black"
                                    noPadding
                                    disabled={!client.id}
                                    href={CLIENTS_LIST_ID_PAGE({ clientId: client.id })}
                                    data-testid="data-test-client"
                                >
                                    {getFullName({ client })}
                                </TextButton>
                            </Row>
                            <Row gap={16} align="center">
                                <Label data-testid="data-test-contact-number" noWrap>
                                    {client?.phone}
                                </Label>
                                {hasAvaliableSpots && (
                                    <Button
                                        stretched
                                        color="outline"
                                        size="small"
                                        onClick={() => onAddToSession({ client, service })}
                                    >

                                        {t('groupDetailsModal.addToSession')}
                                    </Button>
                                )}
                            </Row>
                        </Row>
                    </div>
                ))}
            </Column>
        </React.Fragment>
    );
};

export default Waitlist;
