import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Column } from 'components/Common/Column';
import { Divider } from 'components/Common/Divider';
import { BundleSelectFormRow } from './BundleSelectFormRow';

import { emptyFunc } from '../../../helpers/function/emptyFunc';
import { ClientFormRow } from './ClientFormRow';
import { NoteFormRow } from './NoteFormRow';

const SellBundleForm = (props) => {
    const {
        selectedBundle,
        values,
        bundles,
        validation,
        onGetClients,
        onChangeNote,
        onChangeClient,
        onCreateClient,
        onChangeBundle,
        onChangeClientPhone,
        onChangeClientEmail,
        isClientDisabled,
    } = props;

    const isNotZeroPrice = selectedBundle?.price !== 0;

    const isBundleSelected = Boolean(values.bundle);
    return (
        <Column stretched>
            <BundleSelectFormRow
                values={values}
                bundles={bundles}
                selectedBundle={selectedBundle}
                validation={validation}
                onChangeBundle={onChangeBundle}
            />
            <Divider horisontal />
            <ClientFormRow
                values={values}
                validation={validation}
                onGetClients={onGetClients}
                onChangeClient={onChangeClient}
                onCreateClient={onCreateClient}
                onChangeClientPhone={onChangeClientPhone}
                onChangeClientEmail={onChangeClientEmail}
                disabled={isClientDisabled}
            />
            {isBundleSelected && isNotZeroPrice && (
                <Fragment>
                    <Divider horisontal />
                    <NoteFormRow
                        values={values}
                        validation={validation}
                        onChangeNote={onChangeNote}
                    />
                </Fragment>
            )}
        </Column>
    );
};

SellBundleForm.propTypes = {
    values: PropTypes.object.isRequired,
    bundles: PropTypes.array,
    isClientDisabled: PropTypes.bool,
    validation: PropTypes.object,
    onGetClients: PropTypes.func,
    onChangeNote: PropTypes.func,
    onChangeClient: PropTypes.func,
    onCreateClient: PropTypes.func,
    onChangeBundle: PropTypes.func,
    onChangeClientPhone: PropTypes.func,
    onChangeClientEmail: PropTypes.func,
};

SellBundleForm.defaultProps = {
    bundles: [],
    validation: null,
    isClientDisabled: false,
    onGetClients: emptyFunc,
    onChangeNote: emptyFunc,
    onChangeClient: emptyFunc,
    onCreateClient: emptyFunc,
    onChangeBundle: emptyFunc,
    onChangeClientPhone: emptyFunc,
    onChangeClientEmail: emptyFunc,
};

export default SellBundleForm;
