import {
    call,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import { HTTP_STATUS_CODE } from 'const/http/HTTP_STATUS_CODE';
import { resolvePromiseAction } from '@adobe/redux-saga-promise';
import axios from '../../../services/axios';

import * as BUNDLES_TYPES from '../../actions/bundles/actionTypes';

import * as BUNDLES_ACTIONS from '../../actions/bundles';
import * as TOAST_ACTIONS from '../../actions/toast';

import * as BUNDLES_SELECTORS from '../../selectors/bundles';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import { BUNDLES_LIST_PAGE } from '../../../const/CLIENT_URL';
import {
    API_ADMIN_BUNDLE_ARCHIVE_ID_ROUTE,
    API_ADMIN_BUNDLE_CHECK_ID_ROUTE,
    API_ADMIN_BUNDLE_CREATE_ROUTE,
    API_ADMIN_BUNDLE_EDIT_ID_ROUTE,
    API_ADMIN_BUNDLE_LIST_ROUTE,
    API_ADMIN_BUNDLE_SELL_ID_ROUTE,
    API_ADMIN_BUNDLE_TOGGLE_STATUS_ID_ROUTE,
} from '../../../const/API_URL';
import { CHAR_SYMBOL } from '../../../const/string/CHAR_SYMBOL';

const mapResponseToBundle = (bundle) => ({
    ...bundle,
    maxUsages: {
        value: bundle.maxUsages === CHAR_SYMBOL.INFINITY ? null : bundle.maxUsages,
        unlimited: bundle.maxUsages === CHAR_SYMBOL.INFINITY,
    },
    validity: {
        ...bundle.validity,
        unlimited: !bundle.validity.value && !bundle.validity.unit,
    },
    description: bundle.description || '',
});

function* addBundlesItem(action) {
    const { payload } = action;
    const { bundle, history, onFinal } = payload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_CREATE_ROUTE,
            headers: defaultHeaders,
            data: bundle,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'bundles.add.success',
                appearance: 'success',
            }));
            history.push(BUNDLES_LIST_PAGE);
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: error?.response?.data?.result?.message || 'bundles.add.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
        } else {
            yield call(handleFail);
        }
    }

    onFinal?.();
}

function* editBundlesItem(action) {
    const { payload } = action;
    const {
        bundleId, bundle, history, onFinal,
    } = payload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_EDIT_ID_ROUTE({ bundleId }),
            headers: defaultHeaders,
            data: bundle,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'bundles.edit.success',
                appearance: 'success',
            }));
            history.push(BUNDLES_LIST_PAGE);
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: error?.response?.data?.result?.message || 'bundles.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
        } else {
            yield call(handleFail);
        }
    }

    onFinal?.();
}

function* sellBundlesItem(action) {
    const { payload } = action;
    const {
        bundleId, sellInfo, onFinal, onDone,
    } = payload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_SELL_ID_ROUTE({ bundleId }),
            headers: defaultHeaders,
            data: sellInfo,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'bundles.sell.success',
                appearance: 'success',
            }));
            yield put(BUNDLES_ACTIONS.getSoldBundles());
            onDone?.();
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'bundles.sell.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
        } else if (error?.response?.data?.result?.message) {
            yield put(TOAST_ACTIONS.showToast({
                message: error.response.data.result.message,
                appearance: 'error',
                isCustom: true,
            }));
        } else {
            yield call(handleFail);
        }
    }

    onFinal?.();
}

function* getBundles(action) {
    const { search, page, hideArchived } = yield select(BUNDLES_SELECTORS.bundlesListSelector);
    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(BUNDLES_ACTIONS.setBundlesLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: 'GET',
            url: API_ADMIN_BUNDLE_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                search,
                page,
                hideArchived: Number(hideArchived),
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === 200) {
            const { items, itemsCount, pagesCount } = result;
            const bundles = camelize(items).map(mapResponseToBundle);
            yield put(BUNDLES_ACTIONS.setBundles({ bundles, itemsCount, pagesCount }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'bundles.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(BUNDLES_ACTIONS.setBundlesLoading({ loading: false }));
    }
}

function* getAllBundles(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'GET',
            url: API_ADMIN_BUNDLE_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                page: 0,
                hideInactive: 1,
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === 200) {
            const { items } = result;
            const bundles = camelize(items).map(mapResponseToBundle);

            yield call(resolvePromiseAction, action, bundles);
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'bundles.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* toggleBundlesItemStatus(action) {
    const { payload } = action;
    const { bundleId } = payload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_TOGGLE_STATUS_ID_ROUTE({ bundleId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const { status } = result;
            yield put(BUNDLES_ACTIONS.setBundlesItemStatus({ bundleId, status: !status }));
        }
    } catch (error) {
        yield put(TOAST_ACTIONS.showToast({
            message: 'bundles.toggleStatusById.error',
            appearance: 'error',
        }));
    }
}

function* archiveBundlesItem(action) {
    const { payload } = action;
    const { bundleId } = payload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_ARCHIVE_ID_ROUTE({ bundleId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield call(resolvePromiseAction, action, { bundleId });
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'bundles.archive.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* checkBundlesItem(action) {
    const { payload } = action;
    const {
        bundleId, onSuccess, onFail, onFinal, formData,
    } = payload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_CHECK_ID_ROUTE({ bundleId }),
            headers: defaultHeaders,
            data: formData,
        });
        const { data } = res;
        const { code, result } = data;

        if (code === HTTP_STATUS_CODE.OK) {
            if (onSuccess) {
                yield call(onSuccess, { result });
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            if (onFail) {
                yield call(onFail, { bundleId });
            }
        }

        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        onFinal?.();
    }
}

export const bundlesListSaga = [
    takeEvery(BUNDLES_TYPES.BUNDLES_ITEM_STATUS_TOGGLE, toggleBundlesItemStatus),
    takeLatest(BUNDLES_TYPES.BUNDLES_GET, getBundles),
    takeEvery(BUNDLES_TYPES.BUNDLES_ITEM_ADD, addBundlesItem),
    takeEvery(BUNDLES_TYPES.BUNDLES_ITEM_EDIT, editBundlesItem),
    takeEvery(BUNDLES_TYPES.BUNDLES_ITEM_SELL, sellBundlesItem),
    takeEvery(BUNDLES_ACTIONS.archiveBundlesItem, archiveBundlesItem),
    takeEvery(BUNDLES_ACTIONS.checkBundlesItem, checkBundlesItem),
    takeLatest(BUNDLES_ACTIONS.getAllBundles, getAllBundles),
];
