import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { CommonConfirmModal } from 'components/Common/CommonConfirmModal';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { INVOICE_STATUS } from 'const/financials/invoices/INVOICE_STATUS';

const InvoicePaymentOptionsModal = ({
    terminals,
    onSelectPayment,
    onClose,
    loading,
    visible,
    hasUnpaid,
    unpaidDisabled,
}) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    const bookingConfirmOpts = useMemo(() => {
        const result = [];

        if (hasUnpaid) {
            result.push({
                name: 'ideal',
                label: `${t('add.status.unpaid.label')} ${t('add.status.unpaid.subLabel')}`,
                disabledTooltip: undefined,
                tooltip: t('add.status.unpaid.tooltip'),
                type: INVOICE_STATUS.UNPAID,
                paid: false,
                disabled: unpaidDisabled,
            });
        }
        result.push({
            name: 'ideal',
            label: `${t('add.status.paid.label')} ${t('add.status.paid.subLabel')}`,
            disabledTooltip: undefined,
            tooltip: t('add.status.paid.tooltip'),
            type: INVOICE_STATUS.PAID,
            paid: true,
            disabled: false,
        });
        if (terminals?.length) {
            result.push({
                name: 'terminal',
                label: t('add.status.terminal.label'),
                disabledTooltip: undefined,
                tooltip: t('add.status.terminal.tooltip'),
                type: INVOICE_STATUS.UNPAID,
                paid: false,
                disabled: false,
                terminals,
            });
        }
        return result;
    }, [terminals, t, hasUnpaid, unpaidDisabled]);

    return (
        <CommonConfirmModal
            isShow={visible}
            onClose={onClose}
            onConfirm={onSelectPayment}
            options={bookingConfirmOpts}
            title={t('add.status.title')}
            loading={loading}
            backText={t('add.status.back')}
            confirmText={t('add.status.confirm')}
        />
    );
};

export default InvoicePaymentOptionsModal;
