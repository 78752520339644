import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDialog } from 'hooks/useDialog';

import { InvoicesExportDialog } from 'components/Financials/Invoices/InvoicesExportDialog';
import { InvoicesPageMobileFooter } from 'components/Financials/Invoices/InvoicesPageMobileFooter';

import { InvoicesPageHeader } from 'components/Financials/Invoices/InvoicesPageHeader';
import { InvoicesTableHeader } from 'components/Financials/Invoices/InvoicesTableHeader';
import { InvoicesTableBodyRow } from 'components/Financials/Invoices/InvoicesTableBodyRow';

import * as INVOICES_ACTIONS from 'store/actions/financials/invoices';
import * as COMPANY_ACTIONS from 'store/actions/company';
import * as FINANCIALS_SELECTORS from 'store/selectors/financilas';

import { Content } from 'components/Common/Content';
import { Table } from 'components/Common/NewTable/Table';
import Container from 'components/Layout/Container/Container';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import sentry from 'services/sentry';
import { useMultisafe } from 'hooks/useMultisafe';
import { PERIODS_MAP } from 'components/Common/PeriodPicker/PeriodPicker';
import { companyPaymentTerminalsSelector } from 'store/selectors/company';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 9;

const InvoicesList = () => {
    const dispatch = useDispatch();

    const invoicesExportDialog = useDialog(false);

    const {
        page,
        items: invoices,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        filters,
        search,
        totalRevenue,
        appyBeePay,
        manual,
    } = useSelector(FINANCIALS_SELECTORS.invoicesListSelector);
    const terminals = useSelector(companyPaymentTerminalsSelector);
    const isMultifasePay = useMultisafe();

    const invoicesList = useMemo(() => (
        (!showAll && invoices.length > ITEMS_PER_PAGE)
            ? invoices.slice(0, ITEMS_PER_PAGE)
            : invoices
    ), [showAll, invoices]);

    const handlePrevPage = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(INVOICES_ACTIONS.setInvoicesPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(INVOICES_ACTIONS.getInvoices());
    }, [
        dispatch,
        page,
        showAll,
        filters,
        search,
    ]);

    const handleSearch = useCallback((nextSearch) => {
        dispatch(INVOICES_ACTIONS.setInvoicesSearch({ search: nextSearch }));
    }, [dispatch]);

    useEffect(() => () => {
        dispatch(INVOICES_ACTIONS.clearInvoicesFilters());
        dispatch(INVOICES_ACTIONS.setExportPeriod({ period: PERIODS_MAP.month }));
    }, []);

    useEffect(() => {
        if (isMultifasePay) {
            dispatch(COMPANY_ACTIONS.getCompanyPaymentTerminals());
        }
    }, [dispatch, isMultifasePay]);

    const handleChangeStatusFilter = useCallback((value) => {
        dispatch(INVOICES_ACTIONS.setInvoicesFilters({
            filters: {
                status: filters.status === value
                    ? null
                    : value,
            },
        }));
    }, [dispatch, filters.status]);

    const handleChangeDateFilter = useCallback(({ period, dateFrom, dateTo }) => {
        dispatch(INVOICES_ACTIONS.setInvoicesFilters({
            filters: {
                from: dateFrom.format('X'),
                to: dateTo.format('X'),
            },
        }));

        dispatch(INVOICES_ACTIONS.setExportPeriod({ period }));
    }, [dispatch]);

    const handleSetInvoicesFilters = useCallback(({ filters }) => {
        dispatch(INVOICES_ACTIONS.setInvoicesFilters({ filters }));
    }, [dispatch]);

    const handleInvoicesCreateCredit = useCallback((invoiceId) => {
        const onSuccess = () => {
            dispatch(INVOICES_ACTIONS.getInvoices());
        };
        dispatch(INVOICES_ACTIONS.createInvoiceCredit({ invoiceId, onSuccess }));
    }, [dispatch]);

    const handleChangeStatus = useCallback((invoiceId, terminalId) => {
        if (invoiceId) {
            const onSuccess = () => {
                dispatch(INVOICES_ACTIONS.getInvoices());
            };
            dispatch(INVOICES_ACTIONS.toggleInvoicePaymentStatus({ invoiceId, terminalId, onSuccess })).catch(sentry.ignore);
        }
    }, [dispatch]);

    return (
        <React.Fragment>
            <InvoicesPageHeader
                onExport={invoicesExportDialog.onShow}
                searchValue={search}
                onSearch={handleSearch}
                totalRevenue={totalRevenue}
                appyBeePay={appyBeePay}
                manual={manual}
                onChangeDateHandler={handleChangeDateFilter}
                onChangeStatusHandler={handleChangeStatusFilter}
                filters={filters}
            />
            <Container>
                <Content loading={loading}>
                    <Table
                        footer={{
                            page,
                            showAll,
                            itemsCount,
                            pagesCount,
                            columnsCount: COLUMNS_COUNT,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onShowAll: handleShowAll,
                            onPrevPage: handlePrevPage,
                            onNextPage: handleNextPage,
                            onLastPage: handleLastPage,
                            onFirstPage: handleFirstPage,
                            onShowPages: handleShowPages,
                            onChangePage: handleChangePage,
                        }}
                    >
                        <TableHeader>
                            <InvoicesTableHeader hideClearingStatus={isMultifasePay} />
                        </TableHeader>
                        <TableBody>
                            {invoicesList.map((invoice) => (
                                <ListTableBodyRow key={invoice.id} data-testid={`data-test-invoice-list-row-${invoice.fullNumber}`}>
                                    <InvoicesTableBodyRow
                                        invoice={invoice}
                                        hideClearingStatus={isMultifasePay}
                                        onInvoicesCreateCredit={handleInvoicesCreateCredit}
                                        onInvoiceChangeStatus={handleChangeStatus}
                                        onRefresh={() => dispatch(INVOICES_ACTIONS.getInvoices())}
                                        terminals={terminals}
                                    />
                                </ListTableBodyRow>
                            ))}
                        </TableBody>
                    </Table>
                    <InvoicesPageMobileFooter
                        searchValue={search}
                        onSearch={handleSearch}
                        filters={filters}
                        onSetInvoicesFilters={handleSetInvoicesFilters}
                    />
                </Content>

                <InvoicesExportDialog
                    isVisible={invoicesExportDialog.visible}
                    onClose={invoicesExportDialog.onClose}
                />
            </Container>
        </React.Fragment>
    );
};

export default InvoicesList;
