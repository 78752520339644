/* eslint-disable import/prefer-default-export */
import { BILLING_SORT_BY_TYPE } from 'const/billings/BILLINGS_SORT_BY_TYPE';
import {
    BILLINGS_PAGE_SET,
    BILLINGS_PAGE_SET_FIRST,
    BILLINGS_PAGE_SET_LAST,
    BILLINGS_PAGE_SET_NEXT,
    BILLINGS_PAGE_SET_PREV,
    BILLINGS_SORT_OPTIONS_SET_TRIGGER,
    GET_BILLINGS_REJECTED,
    GET_BILLINGS_RESOLVED,
    GET_BILLINGS_TRIGGER,
} from '../../actions/financials/billings/types';

const INITIAL_STATE = {
    page: 1,
    items: [],
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
    sortOptions: {
        sortBy: BILLING_SORT_BY_TYPE.DATE,
        orderBy: 1,
    },
};

export const billingsListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (GET_BILLINGS_RESOLVED): {
        const { items, itemsCount, pagesCount } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items,
            page,
            itemsCount,
            pagesCount,
            loading: false,
        };
    }
    case (BILLINGS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (BILLINGS_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (BILLINGS_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (BILLINGS_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (BILLINGS_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (GET_BILLINGS_TRIGGER): {
        return {
            ...state,
            loading: true,
        };
    }
    case (GET_BILLINGS_REJECTED): {
        return {
            ...state,
            loading: false,
        };
    }
    case (BILLINGS_SORT_OPTIONS_SET_TRIGGER): {
        const { sortOptions: { sortBy, orderBy } } = payload;
        return {
            ...state,
            sortOptions: {
                sortBy: sortBy ?? state.sortOptions.sortBy,
                orderBy: orderBy ?? state.sortOptions.orderBy,
            },
        };
    }
    default:
        return state;
    }
};
