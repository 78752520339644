import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { CommonConfirmModal } from 'components/Common/CommonConfirmModal';

const EditBookingConfirmModal = ({
    isShow,
    hide,
    loading,
    hasUnpaid,
    confirmAction,
    terminals,
    recurrence,
}) => {
    const { t } = useTranslation();

    const bookingConfirmOpts = useMemo(() => {
        const result = [];
        if (recurrence) {
            if (hasUnpaid) {
                result.push({
                    name: 'cash',
                    label: t('addBookingConfirmModal.unpaid.label'),
                    disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                    tooltip: t('addBookingConfirmModal.unpaid.tooltip'),
                    type: 'open',
                    paid: false,
                });
            }
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.paid.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.paid.tooltip'),
                type: 'paid',
                paid: true,
            });
        } else {
            if (hasUnpaid) {
                result.push({
                    name: 'cash',
                    label: t('addBookingConfirmModal.ideal.label'),
                    disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                    tooltip: t('addBookingConfirmModal.ideal.tooltip'),
                    type: 'open',
                    paid: false,
                });
            }
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.cash.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.cash.tooltip'),
                type: 'paid',
                paid: true,
            });
        }
        if (terminals?.length) {
            result.push({
                name: 'terminal',
                label: t('addBookingConfirmModal.terminal.label'),
                disabledTooltip: undefined,
                tooltip: t('addBookingConfirmModal.terminal.tooltip'),
                type: 'open',
                paid: false,
                disabled: false,
                terminals,
            });
        }
        return result;
    }, [hasUnpaid, recurrence, terminals, t]);

    return (
        <CommonConfirmModal
            isShow={isShow}
            onClose={hide}
            onConfirm={confirmAction}
            options={bookingConfirmOpts}
            title={t('addBookingConfirmModal.title')}
            subHeader={t('addBookingConfirmModal.bookingWillBe')}
            loading={loading}
            backText={t('addBookingConfirmModal.back')}
            confirmText={t('addBookingConfirmModal.yes')}
            confirmDisabledTooltip={t('addBookingConfirmModal.yesDisabledTooltip')}
        />
    );
};

export default EditBookingConfirmModal;
