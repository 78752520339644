import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import SearchInput2 from 'components/Common/SearchInput2';
import { Button } from 'components/Common/Button';
import { useMobile } from 'hooks/useMobile';
import Switch from 'components/Switch/Switch';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

function SubscriptionsHeader(props) {
    const {
        searchValue,
        onSearch,
        onButtonClickHandler,
        hideArchived,
        handleToggleHideArchived,
    } = props;
    const { t } = useTranslation();
    const { t: ts } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const isMobile = useMobile();

    return (
        <TextSubHeader
            text={(t('subscriptions.pageTitle.subscriptionsList'))}
            after={(
                <React.Fragment>
                    {!isMobile
                        && <SearchInput2 searchValue={searchValue} onSearch={onSearch} />}
                    <Switch
                        checked={hideArchived}
                        onChange={handleToggleHideArchived}
                        label={ts('list.header.actions.hideArchived.label')}
                        tooltip={ts('list.header.actions.hideArchived.tooltip')}
                    />
                </React.Fragment>
            )}
            rightActions={
                !isMobile && (
                    <Button
                        onClick={onButtonClickHandler}
                    >
                        {t('placeholders.addSubscription')}
                    </Button>
                )
            }
        />
    );
}

SubscriptionsHeader.propTypes = {
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
    onButtonClickHandler: PropTypes.func,
};

export default SubscriptionsHeader;
