import { createPromiseAction } from '@adobe/redux-saga-promise';

import {
    ADD_SUBSCRIPTION,
    GET_SUBSCRIPTIONS_LIST,
    SET_SUBSCRIPTIONS_LIST,
    LOAD_SUBSCRIPTIONS_LIST_OPTIONS,
    GET_SOLD_SUBSCRIPTIONS_LIST,
    SET_SOLD_SUBSCRIPTIONS_LIST,
    SET_IS_DATA_LOADED,
    SET_PAGES_COUNT,
    SET_ITEMS_COUNT,
    TOGGLE_SUBSCRIPTION_STATUS,
    UPDATE_SUBSCRIPTION_STATUS,
    EDIT_SUBSCRIPTION,
    SET_SHOW_ALL,
    SET_CURRENT_PAGE,
    SET_SEARCH_VALUE,
    GET_SELL_SUBSCRIPTION_DATA,
    SET_SELL_SUBSCRIPTION_DATA,
    SELL_SUBSCRIPTION,
    CHANGE_SOLD_SUBSCRIPTION,
    RESET_SELL_SUBSCRIPTION,
    TOGGLE_SUBSCRIPTION_PAYMENT_STATUS,
    UPDATE_SUBSCRIPTION_PAYMENT_INFO,
    TOGGLE_USER_LOCK,
    UPDATE_SUBSCRIPTION_USER_INFO,
    SET_SOLD_SUBSCRIPTION_BOOKINGS,
    TOGGLE_CANCEL_SOLD_SUBSCRIPTION_MODAL,
    TOGGLE_CANCEL_SOLD_SUBSCRIPTION_WITH_BOOKINGS_MODAL,
    SET_CANCEL_SOLD_SUBSCRIPTION_END_DATE,
    CANCEL_SOLD_SUBSCRIPTION,
    RESET_CANCEL_SOLD_SUBSCRIPTION,
    RESET_SUBSCRIPTIONS_REDUCER,
    OPEN_EDIT_SUBSCRIPTION_USAGES_MODAL,
    GET_SUBSCRIPTION_USAGES_LIST,
    SET_SUBSCRIPTION_USAGES_LIST,
    RESET_EDIT_SUBSCRIPTION_USAGES,
    GET_SUBSCRIPTION_PERIODS_USAGES,
    SOLD_SUBSCRIPTIONS_ITEM_RESEND_PAYMENT,
    SHOW_BOOKINGS_TO_CANCELING_MODAL,
    RESET_BOOKINGS_TO_CANCELING,
    RESET_CHANGE_SOLD_SUBSCRIPTION,
    SET_STATUS_FILTER,
    SET_ID_FILTER,
    GET_SUBSCRIPTIONS_OPTIONS,
    SET_SUBSCRIPTIONS_OPTIONS,
    DELETE_SUBSCRIPTION,
    SOLD_SUBSCRIPTIONS_ITEM_PAUSE,
    SOLD_SUBSCRIPTIONS_ITEM_RESUME,
    SOLD_SUBSCRIPTIONS_ITEM_BOOKINGS_GET,
    SOLD_SUBSCRIPTIONS_ITEM_REMOVE,
    SOLD_SUBSCRIBTIONS_REQEST_EXPORT,
    SET_SOLD_IS_DATA_LOADED,
    SET_SOLD_SHOW_ALL,
    SET_SOLD_CURRENT_PAGE,
    SET_SOLD_SEARCH_VALUE,
    RESET_SOLD_SUBSCRIPTIONS_REDUCER,
    CHECK_SUBSCRIPTION,
    GET_ALL_SUBSCRIPTIONS,
    GET_SOLD_SUBSCRIPTIONS_LIST_OPTIONS,
    SET_SOLD_HIDE_INACTIVE,
    SET_SOLD_SUBSCRIPTIONS_STATUS,
    GET_SOLD_SUBSCRIPTIONS_STATISTICS,
    TOGGLE_SUBSCRIPTIONS_HIDE_ARCHIVED,
} from './actionTypes';

export const addSubscription = (payload, history, onFinal) => ({
    type: ADD_SUBSCRIPTION,
    payload,
    history,
    onFinal,
});

export const getSubscriptionsList = () => ({
    type: GET_SUBSCRIPTIONS_LIST,
});

export const setSubscriptionsList = (list) => ({
    type: SET_SUBSCRIPTIONS_LIST,
    list,
});

export const loadSubscriptionsListOptions = createPromiseAction(
    LOAD_SUBSCRIPTIONS_LIST_OPTIONS,
);

export const deleteSubscription = createPromiseAction(
    DELETE_SUBSCRIPTION,
);

export const getAllSubscriptions = createPromiseAction(
    GET_ALL_SUBSCRIPTIONS,
);

export const setIsDataLoaded = (value) => ({
    type: SET_IS_DATA_LOADED,
    value,
});

export const setSoldIsDataLoaded = (value) => ({
    type: SET_SOLD_IS_DATA_LOADED,
    value,
});

export const setPagesCount = (pagesCount) => ({
    type: SET_PAGES_COUNT,
    pagesCount,
});

export const setItemsCount = (itemsCount) => ({
    type: SET_ITEMS_COUNT,
    itemsCount,
});

export const toggleSubscriptionStatus = (subscriptionId) => ({
    type: TOGGLE_SUBSCRIPTION_STATUS,
    subscriptionId,
});

export const updateSubscriptionStatus = (subscriptionId, status) => ({
    type: UPDATE_SUBSCRIPTION_STATUS,
    subscriptionId,
    status,
});

export const editSubscription = (payload, id, history, onFinal) => ({
    type: EDIT_SUBSCRIPTION,
    payload,
    id,
    history,
    onFinal,
});

export const setShowAll = (showAll) => ({
    type: SET_SHOW_ALL,
    showAll,
});

export const setSoldShowAll = (showAll) => ({
    type: SET_SOLD_SHOW_ALL,
    showAll,
});

export const setCurrentPage = (currentPage) => ({
    type: SET_CURRENT_PAGE,
    currentPage,
});

export const setSoldCurrentPage = (currentPage) => ({
    type: SET_SOLD_CURRENT_PAGE,
    currentPage,
});

export const setSearchValue = (searchValue) => ({
    type: SET_SEARCH_VALUE,
    searchValue,
});

export const setSoldSearchValue = (searchValue) => ({
    type: SET_SOLD_SEARCH_VALUE,
    searchValue,
});

export const setStatusFilter = (statusFilter) => ({
    type: SET_STATUS_FILTER,
    statusFilter,
});

export const setIdFilter = (idFilter) => ({
    type: SET_ID_FILTER,
    idFilter,
});

export const getSubscriptionsOptions = () => ({
    type: GET_SUBSCRIPTIONS_OPTIONS,
});

export const setSubscriptionsOptions = (list) => ({
    type: SET_SUBSCRIPTIONS_OPTIONS,
    subscriptionsOptions: list,
});

export const getSoldSubscriptionsList = () => ({
    type: GET_SOLD_SUBSCRIPTIONS_LIST,
});

export const toggleSubscriptionsHideArchived = ({ hideArchived }) => ({
    type: TOGGLE_SUBSCRIPTIONS_HIDE_ARCHIVED,
    payload: { hideArchived },
});

export const getSoldSubscriptionsListOptions = createPromiseAction(GET_SOLD_SUBSCRIPTIONS_LIST_OPTIONS);
export const cancelSoldSubscription = createPromiseAction(CANCEL_SOLD_SUBSCRIPTION);
export const sellSubscription = createPromiseAction(SELL_SUBSCRIPTION);

export const setSoldSubscriptionsList = ({
    subscriptions, pagesCount, itemsCount, clientId,
}) => ({
    type: SET_SOLD_SUBSCRIPTIONS_LIST,
    subscriptions,
    pagesCount,
    itemsCount,
    clientId,
});

export const getSellSubscriptionData = () => ({
    type: GET_SELL_SUBSCRIPTION_DATA,
});

export const setSellSubscriptionData = (clients, subscriptions) => ({
    type: SET_SELL_SUBSCRIPTION_DATA,
    clients,
    subscriptions,
});

export const changeSoldSubscription = ({
    soldSubscriptionId,
    newSubscriptionId,
    onSuccess,
    isConfirmed,
}) => ({
    type: CHANGE_SOLD_SUBSCRIPTION,
    payload: {
        soldSubscriptionId,
        newSubscriptionId,
        onSuccess,
        isConfirmed,
    },
});

export const resetSellSubscription = () => ({
    type: RESET_SELL_SUBSCRIPTION,
});

export const setSoldSubscriptionsStatus = (subscriptionsStatus) => ({
    type: SET_SOLD_SUBSCRIPTIONS_STATUS,
    subscriptionsStatus,
});

export const toggleSubscriptionPaymentStatus = (subscriptionId, paymentId) => ({
    type: TOGGLE_SUBSCRIPTION_PAYMENT_STATUS,
    subscriptionId,
    paymentId,
});

export const updateSubscriptionPaymentInfo = (subscriptionPaymentInfo) => ({
    type: UPDATE_SUBSCRIPTION_PAYMENT_INFO,
    subscriptionPaymentInfo,
});

export const toggleUserLock = (clientId) => ({
    type: TOGGLE_USER_LOCK,
    clientId,
});

export const updateSubscriptionUserInfo = (subscriptionUserInfo) => ({
    type: UPDATE_SUBSCRIPTION_USER_INFO,
    subscriptionUserInfo,
});

export const setSoldSubscriptionBookings = (subscriptionBookings) => ({
    type: SET_SOLD_SUBSCRIPTION_BOOKINGS,
    subscriptionBookings,
});

export const toggleCancelSoldSubscriptionModal = ({
    showCancelModal,
    subscriptionId,
    subscriptionStartDate,
    nextSubscriptionDate,
    nextSubscriptionPrice,
    payments,
    nextDebitDate,
    subscriptionStatus,
    paymentType,
    price,
}) => ({
    type: TOGGLE_CANCEL_SOLD_SUBSCRIPTION_MODAL,
    showCancelModal,
    subscriptionId,
    subscriptionStartDate,
    nextSubscriptionDate,
    nextSubscriptionPrice,
    payments,
    nextDebitDate,
    subscriptionStatus,
    paymentType,
    price,
});

export const toggleCancelSoldSubscriptionWithBookingsModal = ({
    showCancelWithBookingsModal,
    subscriptionId,
    paymentType,
}) => ({
    type: TOGGLE_CANCEL_SOLD_SUBSCRIPTION_WITH_BOOKINGS_MODAL,
    showCancelWithBookingsModal,
    subscriptionId,
    paymentType,
});

export const setCancelSoldSubscriptionEndDate = (endDate) => ({
    type: SET_CANCEL_SOLD_SUBSCRIPTION_END_DATE,
    endDate,
});

export const resetCancelSoldSubscription = () => ({
    type: RESET_CANCEL_SOLD_SUBSCRIPTION,
});

export const resetSubscriptionsReducer = () => ({
    type: RESET_SUBSCRIPTIONS_REDUCER,
});

export const resetSoldSubscriptionsReducer = () => ({
    type: RESET_SOLD_SUBSCRIPTIONS_REDUCER,
});

export const openEditSubscriptionUsagesModal = (
    subscriptionId,
    paymentStartDate,
) => ({
    type: OPEN_EDIT_SUBSCRIPTION_USAGES_MODAL,
    subscriptionId,
    paymentStartDate,
});

export const getSubscriptionUsagesList = () => ({
    type: GET_SUBSCRIPTION_USAGES_LIST,
});

export const setSubscriptionUsagesList = (usages, formInitialState) => ({
    type: SET_SUBSCRIPTION_USAGES_LIST,
    usages,
    formInitialState,
});

export const resetEditSubscriptionUsages = () => ({
    type: RESET_EDIT_SUBSCRIPTION_USAGES,
});

export const getSubscriptionPeriodsUsages = (startDate) => ({
    type: GET_SUBSCRIPTION_PERIODS_USAGES,
    startDate,
});

export const resendSoldSubscriptionPayment = ({ soldSubscriptionId }) => ({
    type: SOLD_SUBSCRIPTIONS_ITEM_RESEND_PAYMENT,
    payload: { soldSubscriptionId },
});

export const pauseSoldSubscriptionItem = ({
    soldSubscriptionId,
    data,
    onSuccess,
    onError,
}) => ({
    type: SOLD_SUBSCRIPTIONS_ITEM_PAUSE,
    payload: {
        soldSubscriptionId,
        data,
        onSuccess,
        onError,
    },
});

export const resumeSoldSubscriptionItem = ({
    soldSubscriptionId,
    data,
    onSuccess,
    onError,
}) => ({
    type: SOLD_SUBSCRIPTIONS_ITEM_RESUME,
    payload: {
        soldSubscriptionId,
        data,
        onSuccess,
        onError,
    },
});

export const showBookingsToCancelingModal = ({
    bookings,
    newSubscriptionId,
}) => ({
    type: SHOW_BOOKINGS_TO_CANCELING_MODAL,
    bookings,
    newSubscriptionId,
});

export const resetBookingsToCanceling = () => ({
    type: RESET_BOOKINGS_TO_CANCELING,
});

export const resetChangeSoldSubscription = () => ({
    type: RESET_CHANGE_SOLD_SUBSCRIPTION,
});

export const getSoldSubscriptionsItemBookings = ({
    startDate,
    soldSubscriptionId,
    onSuccess,
    onFail,
}) => ({
    type: SOLD_SUBSCRIPTIONS_ITEM_BOOKINGS_GET,
    payload: {
        startDate,
        soldSubscriptionId,
        onSuccess,
        onFail,
    },
});

export const deleteSoldSubscriptionsItem = ({
    soldSubscriptionId,
    onSuccess,
    onFail,
}) => ({
    type: SOLD_SUBSCRIPTIONS_ITEM_REMOVE,
    payload: {
        soldSubscriptionId,
        onSuccess,
        onFail,
    },
});

export const reqestExportSoldSubscribtions = () => ({
    type: SOLD_SUBSCRIBTIONS_REQEST_EXPORT,
});

export const checkSubscription = createPromiseAction(CHECK_SUBSCRIPTION);

export const setSoldHideInactive = (hideInactive) => ({
    type: SET_SOLD_HIDE_INACTIVE,
    hideInactive,
});

export const getSoldSubscriptionsStatistics = createPromiseAction(
    GET_SOLD_SUBSCRIPTIONS_STATISTICS,
);
