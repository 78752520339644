import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { formatPrice } from 'helpers/formatters/formatPrice';
import { Row } from 'components/Common/Row';
import { Tooltip } from 'components/Common/Tooltip';
import { IconButton } from 'components/Common/IconButton';
import { DownloadIcon } from 'components/Icon/Icon';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';

import * as BILLINGS_ACTIONS from '../../../../store/actions/financials/billings';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.table.body.rows';
const T_FIELDS = `${T_PREFIX}.fields`;

const BillingsTableBodyRow = (props) => {
    const { payout } = props;
    const {
        id,
        number,
        date,
        totalAmount,
    } = payout || {};
    const { t } = useTranslation(LOCALE_NAMESPACE.PAYOUTS);

    const dispatch = useDispatch();

    const payoutDateCreated = date && moment(date).isValid()
        ? moment(date).format('DD MMM YYYY HH:mm:ss')
        : '-';

    const handleDownloadPayout = useCallback(() => {
        dispatch(BILLINGS_ACTIONS.downloadBilling({ billingId: id }));
    }, [id]);

    return (
        <React.Fragment>
            <ListTableBodyCell
                className="text-muted"
            >
                {number}
            </ListTableBodyCell>
            <ListTableBodyCell
                className="text-muted"
            >
                {payoutDateCreated}
            </ListTableBodyCell>
            <ListTableBodyCell
                className="text-muted"
                align="right"
            >
                {formatPrice.toEuroWithComma({ amount: totalAmount }) }
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">
                <Row gap={8}>
                    <Tooltip
                        tooltip={t(`${T_FIELDS}.actions.download.label`)}
                        placement="top"
                        forButton
                    >
                        <IconButton
                            onClick={handleDownloadPayout}
                            color="gray"
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Row>
            </ListTableBodyCell>
        </React.Fragment>
    );
};

BillingsTableBodyRow.propTypes = {
    payout: PropTypes.object,
};

BillingsTableBodyRow.defaultProps = {
    payout: null,
};

export default BillingsTableBodyRow;
