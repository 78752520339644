import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { CommonConfirmModal } from 'components/Common/CommonConfirmModal';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'sell.form';
const T_FIELDS = `${T_PREFIX}.fields`;

const SellBundleConfirmModal = ({
    terminals,
    hasIdeal,
    isAutomaticPaymentAvailable,
    onSelectPayment,
    onClose,
    loading,
    visible,
}) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    const bookingConfirmOpts = useMemo(() => {
        const result = [];

        if (isAutomaticPaymentAvailable) {
            if (hasIdeal) {
                result.push({
                    name: 'ideal',
                    label: t(`${T_FIELDS}.paymentType.ideal.label`),
                    disabledTooltip: undefined,
                    tooltip: t(`${T_FIELDS}.paymentType.ideal.tooltip`),
                    type: 'ideal',
                    paid: false,
                    disabled: false,
                });
            }
            result.push({
                name: 'cash',
                label: t(`${T_FIELDS}.paymentType.cash.label`),
                disabledTooltip: undefined,
                tooltip: t(`${T_FIELDS}.paymentType.cash.tooltip`),
                type: 'cash',
                paid: true,
                disabled: false,
            });
        }
        if (!isAutomaticPaymentAvailable) {
            result.push({
                name: 'cash',
                label: t(`${T_FIELDS}.paymentType.manual.label`),
                disabledTooltip: undefined,
                tooltip: t(`${T_FIELDS}.paymentType.manual.tooltip`),
                type: 'cash',
                paid: true,
                disabled: false,
            });
        }
        if (terminals?.length) {
            result.push({
                name: 'terminal',
                label: t(`${T_FIELDS}.paymentType.terminal.label`),
                disabledTooltip: undefined,
                tooltip: t(`${T_FIELDS}.paymentType.terminal.tooltip`),
                type: 'ideal',
                paid: false,
                disabled: false,
                terminals,
            });
        }
        return result;
    }, [terminals, t]);

    return (
        <CommonConfirmModal
            isShow={visible}
            onClose={onClose}
            onConfirm={onSelectPayment}
            options={bookingConfirmOpts}
            title={t(`${T_FIELDS}.paymentType.title`)}
            loading={loading}
            backText={t(`${T_FIELDS}.paymentType.back`)}
            confirmText={t(`${T_FIELDS}.paymentType.confirm`)}
        />
    );
};

export default SellBundleConfirmModal;
