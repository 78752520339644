import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Common/Button';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import SearchInput2 from 'components/Common/SearchInput2';

import { useMobile } from 'hooks/useMobile';
import Switch from 'components/Switch/Switch';
import { emptyFunc } from 'helpers/function/emptyFunc';

import { BUNDLES_ADD_PAGE } from 'const/CLIENT_URL';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.header';

const BundlesListHeader = (props) => {
    const {
        search,
        onSearch,
        onHideArchived,
        hideArchived,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const isMobile = useMobile();

    const handleSearch = useCallback((nextSearch) => {
        onSearch({ search: nextSearch });
    }, [onSearch]);

    return (
        <TextSubHeader
            text={t(`${T_PREFIX}.title`)}
            after={(
                <Fragment>
                    { !isMobile && (
                        <SearchInput2
                            searchValue={search}
                            onSearch={handleSearch}
                        />
                    )}
                    <Switch
                        checked={hideArchived}
                        onChange={onHideArchived}
                        label={t(`${T_PREFIX}.actions.hideArchived.label`)}
                        tooltip={t(`${T_PREFIX}.actions.hideArchived.tooltip`)}
                    />
                </Fragment>
            )}
            rightActions={(
                !isMobile && (
                    <Button
                        href={BUNDLES_ADD_PAGE}
                    >
                        {t(`${T_PREFIX}.actions.add`)}
                    </Button>
                )
            )}
        />
    );
};

BundlesListHeader.propTypes = {
    search: PropTypes.string,
    onSearch: PropTypes.func,
};

BundlesListHeader.defaultProps = {
    search: '',
    onSearch: emptyFunc,
};

export default BundlesListHeader;
