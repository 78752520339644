import React, { useCallback, useMemo } from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

// Actions
import * as actions from 'store/actions';
import { permissionsResourceSelector } from 'store/selectors/permissions';

// Components
import Event from 'components/Event/Event';
import BookingModal from 'components/BookingModal/BookingModal';
import EditBookingModal from 'components/EditBookingModal/EditBookingModal';
import AddBookingModal from 'components/AddBookingModal/AddBookingModal';
import { ConfirmationModal, EventDetailsModal } from 'components';

// Utils
import moment from 'moment';
import * as EVENTS_ACTIONS from 'store/actions/events';
import momentAmsterdamTime from 'helpers/time/momentAmsterdamTime';

import { Button } from 'components/Common/Button';
import { Content } from 'components/Common/Content';
import { EVENTS_ADD_PAGE } from 'const/CLIENT_URL';
import { Column } from 'components/Common/Column';
import { Header } from 'components/Common/Typography/Header';
import SubHeader from 'components/Common/Typography/SubHeader/SubHeader';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import Container from 'components/Layout/Container/Container';
import { Divider } from 'components/Common/Divider';
import { Pagination } from '../../../../../components/Common/Pagination';
import { eventsPageSelector } from '../../../../../store/selectors/events';

const groupEventsByDate = (events) => events.reduce((events, event) => {
    const date = moment(event.time.from).format('D MMM YYYY');
    if (events[date]) {
        return {
            ...events,
            [date]: [
                ...events[date],
                event,
            ],
        };
    }

    return {
        ...events,
        [date]: [event],
    };
}, {});

const EventGroup = ({ stringDate, events, onClick }) => (
    <Column key={events[0].id} stretched gap={32}>
        <Column gap={0} stretched>
            <Header>{stringDate}</Header>
            <Divider horisontal />
        </Column>
        <Column gap={32} stretched>
            {events.map((event) => (
                <Event
                    key={`event-${event.id}`}
                    className="mt-5 mt-md-4"
                    event={event}
                    onClick={() => onClick(event.resourceId)}
                />
            ))}
        </Column>
    </Column>
);

const Index = () => {
    const addBookingDetails = useSelector((state) => state.bookings.addBookingDetails);
    const { banAdd } = useSelector(permissionsResourceSelector);

    const [clickedEventID, setClickedEventID] = useState(null);
    const [isSendingNotification, _] = useState(false);
    const [clickedBookingID, setClickedBookingID] = useState(null);
    const [editBooking, setEditBooking] = useState(false);
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [isCancellingEvent, setIsCancellingEvent] = useState(false);

    useEffect(() => {
        if (!addBookingDetails) {
            return;
        }

        setShowBookingModal(true);
    }, [addBookingDetails]);

    const {
        loadingEvents,
        events: eventsArray,
        pagesCount,
        itemsCount,
        page,
        showAll,
    } = useSelector(eventsPageSelector);

    const event = useSelector((state) => (state.events.events || []).find(({ resourceId }) => resourceId === clickedEventID));
    const dispatch = useDispatch();

    const { addToast } = useToasts();

    const { t } = useTranslation();

    const handleEventClick = useCallback((eventId) => {
        setClickedEventID(eventId);
    }, []);

    const EventsComponent = useMemo(() => {
        if (!eventsArray) return;
        const sortedEvents = eventsArray.sort((a, b) => a.time.from - b.time.from);

        const pastEvents = groupEventsByDate(sortedEvents.filter((event) => moment(event.time.from).isBefore(momentAmsterdamTime())));

        const upcomingEvents = groupEventsByDate(sortedEvents.filter((event) => moment(event.time.from).isAfter(momentAmsterdamTime())));

        const upcomingEventsComponents = Object.entries(upcomingEvents).map(([stringDate, events]) => EventGroup({ stringDate, events, onClick: handleEventClick }));
        const pastEventsComponents = Object.entries(pastEvents).map(([stringDate, events]) => EventGroup({ stringDate, events, onClick: handleEventClick }));

        return (
            <Column gap={32} stretched>
                {upcomingEventsComponents.length !== 0 && <SubHeader>{t('eventsRoute.comingEvents')}</SubHeader>}
                {upcomingEventsComponents}
                {pastEventsComponents.length !== 0 && <SubHeader>{t('eventsRoute.passedEvents')}</SubHeader>}
                {pastEventsComponents.reverse()}
            </Column>
        );
    }, [eventsArray]);

    const onCancelHandler = async () => {
        if (isCancellingEvent) {
            return;
        }

        setIsCancellingEvent(true);
        try {
            await dispatch(actions.cancelEvent({ id: event?.id }));
            addToast(t('eventsRoute.statusEventSuccess'), {
                appearance: 'success',
            });
            setCancelModalVisible(false);
        } catch ({ message }) {
            addToast(message, {
                appearance: 'error',
            });
        } finally {
            setIsCancellingEvent(false);
        }
    };
    const handleShowAll = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsShowAll({ showAll: true }));
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: page - 1 }));
    }, [dispatch, page]);

    const handleNextPage = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: page + 1 }));
    }, [dispatch, page]);

    const handleLastPage = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: pagesCount }));
    }, [dispatch, pagesCount]);

    const handleFirstPage = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: 1 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsShowAll({ showAll: false }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: 1 }));
    }, [dispatch]);

    return (
        <React.Fragment>
            <TextSubHeader
                text={t('eventsRoute.events')}
                rightActions={!banAdd && (
                    <div className="d-flex align-items-center">
                        <Button
                            color="yellow"
                            href={EVENTS_ADD_PAGE}
                        >
                            {t('eventsRoute.addEvent')}
                        </Button>
                    </div>
                )}
            />
            <Container>
                <Column stretched gap={32}>
                    <Content loading={loadingEvents}>{EventsComponent}</Content>
                    <Pagination
                        page={page}
                        pagesCount={pagesCount}
                        itemsCount={itemsCount}
                        itemsPerPage={10}
                        onChangePage={handleChangePage}
                        onShowPages={handleShowPages}
                        onFirstPage={handleFirstPage}
                        onLastPage={handleLastPage}
                        onPrevPage={handlePrevPage}
                        onNextPage={handleNextPage}
                        onShowAll={handleShowAll}
                        showAll={showAll}
                    />
                </Column>
            </Container>

            {clickedEventID && (
                <EventDetailsModal
                    clickedEventID={clickedEventID}
                    setClickedEventID={setClickedEventID}
                    showBookingModal={showBookingModal}
                    clickedBookingID={clickedBookingID}
                    cancelModalVisible={cancelModalVisible}
                    setClickedBookingID={setClickedBookingID}
                    isSendingNotification={isSendingNotification}
                    setCancelModalVisible={setCancelModalVisible}
                />
            )}

            <ConfirmationModal
                isShow={cancelModalVisible}
                hide={() => setCancelModalVisible(false)}
                loading={isCancellingEvent}
                confirmAction={onCancelHandler}
                deleteColor={event?.isCancelled ? 'green' : 'red'}
                titleText={`${t(`eventsRoute.${event?.isCancelled ? 'reactivate' : 'cancel'}`)} ${t('eventsRoute.event')}`}
                bodyText={t(`eventsRoute.areYouSureYouWantTo${event?.isCancelled ? 'Reactivate' : 'Cancel'}Event`)}
                deleteText={t(`eventsRoute.${event?.isCancelled ? 'reactivate' : 'cancel'}`)}
                dismissText={t('groupDetailsModal.back')}
            />

            {showBookingModal && (
                <AddBookingModal
                    show={showBookingModal}
                    onHide={() => setShowBookingModal(false)}
                    productType="event"
                />
            )}

            {clickedBookingID && (
                <BookingModal
                    bookingIDWithTimestamp={clickedBookingID}
                    show={clickedBookingID && !editBooking}
                    onHide={() => setClickedBookingID(null)}
                    onEditChange={() => setEditBooking(true)}
                    productType="event"
                />
            )}

            <EditBookingModal
                bookingIDWithTimestamp={clickedBookingID}
                show={editBooking}
                onHide={() => setEditBooking(false)}
                productType="event"
            />
        </React.Fragment>
    );
};

export default Index;
