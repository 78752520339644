// extracted by mini-css-extract-plugin
var _1 = "TextButton_before__KRV-D";
var _2 = "TextButton_button__Y3-N1";
var _3 = "TextButton_capitalize__2+iFg";
var _4 = "TextButton_disabled__sQfKn";
var _5 = "TextButton_link__mOZLK";
var _6 = "TextButton_noPadding__C+67k";
var _7 = "TextButton_strong__cqhav";
var _8 = "TextButton_uppercase__I+96k";
export { _1 as "before", _2 as "button", _3 as "capitalize", _4 as "disabled", _5 as "link", _6 as "noPadding", _7 as "strong", _8 as "uppercase" }
