import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CLIENTS_LIST_ID_INVOICES_PAGE } from 'const/CLIENT_URL';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Badge } from 'components/Common/Badge';
import { INVOICE_STATUS } from 'const/financials/invoices/INVOICE_STATUS';
import { debounce } from 'lodash';
import TextButton from 'components/Common/TextButton/TextButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import sentry from 'services/sentry';
import IconButton from 'components/Common/IconButton/IconButton';
import { Row } from 'components/Common/Row';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { Popover } from 'components/Common/Popover';
import { Label } from 'components/Common/Typography/Label';
import { PAYMENT_TYPE_ICONS } from 'const/payments/PAYMENT_TYPE_ICONS';
import { useDialog } from '../../../../hooks/useDialog';

import { Tooltip } from '../../../Common/Tooltip';
import { InvoiceCreateCreditDialog } from '../InvoiceCreateCreditDialog';

import {
    DownloadIcon, EditIcon, InvoiceCreditIcon, SentIcon, Trash3Icon, InfoIcon,
} from '../../../Icon/Icon';

import {
    checkInvoiceCreditStatus, resendCustomInvoice,
} from '../../../../store/actions/financials/invoices';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import InvoiceDraftDeleteDialog from '../InvoiceDraftDeleteDialog';
import InvoicesTableInfoPopover from '../InvoicesTableInfoPopover';
import { InvoicePaymentOptionsModal } from '../InvoicePaymentOptionsModal';

const T_PREFIX = 'list.table.body.rows';
const T_FIELDS = `${T_PREFIX}.fields`;

const statusClasses = {
    unpaid: 'red',
    paid: 'green',
    draft: 'gray',
};

const clearingStatusClasses = {
    pending: 'yellow',
    cleared: 'green',
};

const InvoicesTableBodyRow = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const [deleteInvoice, setDeleteInvoice] = useState(null);

    const {
        invoice,
        hideClearingStatus,
        isClientPage,
        onInvoicesCreateCredit,
        onInvoiceChangeStatus,
        onRefresh,
        terminals,
    } = props;
    const {
        id: invoiceId,
        client,
        fullNumber,
        date,
        amount,
        status,
        url,
        type,
        vat,
        isManuallySet,
        paymentType,
        clearingName,
        transactionId,
        allowTerminalPayment,
    } = invoice || {};

    const isDraft = status === INVOICE_STATUS.DRAFT;
    const isPaid = status === INVOICE_STATUS.PAID;

    const isRefound = paymentType.toLowerCase() === 'refund';

    const regularInvoice = type === 1;
    // const creditInvoice = type === 2;

    const showStatusInfo = regularInvoice || isRefound;

    const clearingStatus = showStatusInfo && !isManuallySet && transactionId && isPaid ? (clearingName ? 'Cleared' : 'Pending') : null;
    const confirmationChangeStatusPaid = useDialog(false);
    const {
        firstName, lastName, id,
    } = client || {};

    const dispatch = useDispatch();

    const invoiceCreateCreditDialog = useDialog(false);
    const invoicePaymentOptionsModal = useDialog();

    const name = `${firstName} ${lastName}`;
    const invoiceDate = moment(date).format('DD MMM YYYY');

    const statusLabel = t(`${T_FIELDS}.status.${status}`) + (isManuallySet ? t(`${T_FIELDS}.status.manuallySet`) : '');

    const handleCheckInvoiceCreditStatus = useCallback(() => {
        dispatch(checkInvoiceCreditStatus({
            invoiceId,
            onSuccess: ({ result }) => {
                if (!result) {
                    invoiceCreateCreditDialog.onShow();
                }
            },
        }));
    }, [dispatch, invoiceId]);

    const handleDelete = useCallback(debounce(() => {
        setDeleteInvoice(invoiceId);
    }, 250), [invoiceId]);

    const handleResendCustomInvoice = useCallback(() => {
        dispatch(resendCustomInvoice({ bill: invoiceId })).catch(sentry.ignore);
    }, [dispatch, invoiceId]);

    const handleDeleteClose = (status) => {
        if (status) {
            // Without timeout we have 500 error
            setTimeout(onRefresh, 1000);
        }
        setDeleteInvoice(null);
    };

    const handleToggleInvoicePaymentStatus = useMemo(() => {
        if (isDraft) {
            return undefined;
        }
        if (allowTerminalPayment) {
            return invoicePaymentOptionsModal.onShow;
        }
        return confirmationChangeStatusPaid.onShow;
    }, [invoicePaymentOptionsModal, confirmationChangeStatusPaid, isDraft, allowTerminalPayment]);

    return (
        <React.Fragment>
            <ListTableBodyCell
                className="text-muted"
                data-testid="data-test-invoice-number"
            >
                {fullNumber}
            </ListTableBodyCell>
            <ListTableBodyCell
                data-testid="data-test-client-name"
            >
                {isClientPage
                    ? <Label color="gray" size="small">{name}</Label>
                    : <TextButton color="gray" href={CLIENTS_LIST_ID_INVOICES_PAGE({ clientId: id })} newTab>{name}</TextButton>}
            </ListTableBodyCell>
            <ListTableBodyCell
                className="text-muted"
                data-testid="data-test-invoice-date"
            >
                {isDraft ? '' : invoiceDate}
            </ListTableBodyCell>
            <ListTableBodyCell
                className="text-muted"
                align="right"
                data-testid="data-test-invoice-amount"
            >
                {formatPrice.toEuroWithComma({ amount })}
            </ListTableBodyCell>
            <ListTableBodyCell
                className="text-muted"
                align="right"
                data-testid="data-test-invoice-vat"
            >
                {formatPrice.toEuroWithComma({ amount: vat })}
            </ListTableBodyCell>
            <ListTableBodyCell>
                <Row gap={8}>
                    {paymentType && PAYMENT_TYPE_ICONS[paymentType.toLowerCase()]}
                    <span data-testid="data-test-invoice-payment-type">
                        {paymentType || '-'}
                    </span>
                </Row>
            </ListTableBodyCell>
            <ListTableBodyCell>
                <Badge color={statusClasses[status]} size="small" onClick={handleToggleInvoicePaymentStatus} data-testid="data-test-invoice-status">
                    {statusLabel}
                </Badge>
            </ListTableBodyCell>
            {!hideClearingStatus && (
                <ListTableBodyCell data-testid="data-test-invoice-clearing">
                    <TextButton disabled color={clearingStatusClasses[clearingStatus?.toLowerCase()] || 'black'}>{clearingStatus || '-'}</TextButton>
                </ListTableBodyCell>
            )}
            <ListTableBodyCell align="right" data-testid="data-test-invoice-actions">
                <Row gap={8}>
                    {transactionId && showStatusInfo && isPaid && !isManuallySet && (
                        <Popover
                            content={(
                                <InvoicesTableInfoPopover
                                    invoice={invoice}
                                    isClearingPending={clearingStatus === 'Pending'}
                                />
                            )}
                        >
                            {({ ref, onClick }) => (
                                <Tooltip
                                    forButton
                                    placement="top"
                                    tooltip={t(`${T_FIELDS}.actions.info.tooltip`)}
                                >
                                    <IconButton
                                        ref={ref}
                                        onClick={onClick}
                                        color="gray"
                                        data-testid="data-test-invoice-info"
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Popover>
                    )}
                    { !isDraft && (
                        <Tooltip
                            forButton
                            placement="top"
                            tooltip={t(`${T_FIELDS}.actions.resend.tooltip`)}
                        >
                            <IconButton
                                onClick={handleResendCustomInvoice}
                                color="gray"
                                data-testid="data-test-invoice-resend"
                            >
                                <SentIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    { !isDraft && (
                        <Tooltip
                            forButton
                            placement="top"
                            tooltip={t(`${T_FIELDS}.actions.download.tooltip`)}
                        >
                            <IconButton
                                download
                                href={url}
                                color="gray"
                                data-testid="data-test-invoice-download"
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {regularInvoice && isDraft && (
                        <Tooltip
                            forButton
                            placement="top"
                            tooltip={t(`${T_FIELDS}.actions.edit.tooltip`)}
                        >
                            <IconButton
                                href={`/financials/invoices/edit/${invoiceId}`}
                                color="gray"
                                data-testid="data-test-invoice-edit"
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {regularInvoice && !isDraft && (
                        <React.Fragment>
                            <Tooltip
                                forButton
                                placement="top"
                                tooltip={t(`${T_FIELDS}.actions.createCredit.tooltip`)}
                            >
                                <IconButton
                                    color="gray"
                                    onClick={handleCheckInvoiceCreditStatus}
                                    data-testid="data-test-invoice-credit"
                                >
                                    <InvoiceCreditIcon />
                                </IconButton>
                            </Tooltip>

                            {invoiceCreateCreditDialog.visible && (
                                <InvoiceCreateCreditDialog
                                    isVisible={invoiceCreateCreditDialog.visible}
                                    onClose={invoiceCreateCreditDialog.onClose}
                                    onConfirm={() => onInvoicesCreateCredit(invoiceId)}
                                />
                            )}
                        </React.Fragment>
                    )}
                    {regularInvoice && isDraft && (
                        <Tooltip
                            forButton
                            placement="top"
                            tooltip={t(`${T_FIELDS}.actions.delete.tooltip`)}
                        >
                            <IconButton
                                color="gray"
                                onClick={handleDelete}
                                data-testid="data-test-invoice-delete"
                            >
                                <Trash3Icon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Row>
            </ListTableBodyCell>
            <InvoiceDraftDeleteDialog invoiceId={deleteInvoice} onClose={handleDeleteClose} />

            {confirmationChangeStatusPaid.visible && (
                <ConfirmationModal
                    size="xs"
                    isShow={confirmationChangeStatusPaid.visible}
                    bodyText={t('list.table.confirmationChangeStatusPaid.bodyText')}
                    hide={confirmationChangeStatusPaid.onClose}
                    deleteText={t('list.table.confirmationChangeStatusPaid.yes')}
                    dismissText={t('list.table.confirmationChangeStatusPaid.no')}
                    confirmAction={() => {
                        onInvoiceChangeStatus(invoiceId);
                        confirmationChangeStatusPaid.onClose();
                    }}
                />
            )}
            <InvoicePaymentOptionsModal
                visible={invoicePaymentOptionsModal.visible}
                onClose={invoicePaymentOptionsModal.onClose}
                terminals={terminals}
                hasUnpaid={Boolean(client?.clientEmail)}
                unpaidDisabled={!amount}
                onSelectPayment={(paymentType) => {
                    onInvoiceChangeStatus(invoiceId, paymentType?.terminal?.id);
                    invoicePaymentOptionsModal.onClose();
                }}
            />
        </React.Fragment>
    );
};

InvoicesTableBodyRow.propTypes = {
    invoice: PropTypes.object,
    hideClearingStatus: PropTypes.bool,
    isClientPage: PropTypes.bool,
    onInvoiceCreateCredit: PropTypes.func,
    onInvoiceChangeStatus: PropTypes.func,
};

InvoicesTableBodyRow.defaultProps = {
    invoice: null,
    hideClearingStatus: false,
    isClientPage: false,
    onInvoiceCreateCredit: () => {},
    onInvoiceChangeStatus: () => {},
};

export default InvoicesTableBodyRow;
