import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { deleteProduct } from 'store/actions';

import { Button } from 'components/Common/Button';

import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';

import { Dialog } from 'components/Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from 'components/Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from 'components/Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from 'components/Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from 'components/Common/Dialogs/Dialog/DialogFooter';

const T_PREFIX = 'deleteProductDialog';
const T_BODY = `${T_PREFIX}.body`;
const T_ACTIONS = `${T_PREFIX}.actions`;

const InvoiceProductDeleteDialog = (props) => {
    const { productId, onClose } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const dispatch = useDispatch();

    const onDeleteHandle = useCallback(() => {
        dispatch(deleteProduct({ id: productId }));
        onClose(true);
    });

    const onCloseHandle = useCallback(() => {
        onClose(false);
    });

    return (
        <Dialog
            size="xs"
            visible={Boolean(productId)}
            onClose={onCloseHandle}
        >
            <DialogHeader
                closeButton
                onHide={onCloseHandle}
            >
                <DialogTitle>
                    {t(`${T_PREFIX}.header`)}
                </DialogTitle>
            </DialogHeader>

            <DialogBody>
                {t(`${T_BODY}.text`)}
            </DialogBody>

            <DialogFooter>
                <Button
                    color="outline"
                    onClick={onCloseHandle}
                >
                    {t(`${T_ACTIONS}.cancel`)}
                </Button>

                <Button
                    color="red"
                    onClick={onDeleteHandle}
                >
                    {t(`${T_ACTIONS}.delete`)}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default InvoiceProductDeleteDialog;
