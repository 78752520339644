import { createPromiseAction } from '@adobe/redux-saga-promise';
import * as BILLINGS_TYPES from './types';

export const setBillingsPage = ({ page }) => ({
    type: BILLINGS_TYPES.BILLINGS_PAGE_SET,
    payload: { page },
});

export const setBillingsPageFirst = () => ({
    type: BILLINGS_TYPES.BILLINGS_PAGE_SET_FIRST,
});

export const setBillingsPageLast = () => ({
    type: BILLINGS_TYPES.BILLINGS_PAGE_SET_LAST,
});

export const setBillingsPagePrev = () => ({
    type: BILLINGS_TYPES.BILLINGS_PAGE_SET_PREV,
});

export const setBillingsPageNext = () => ({
    type: BILLINGS_TYPES.BILLINGS_PAGE_SET_NEXT,
});

export const getBillings = createPromiseAction(BILLINGS_TYPES.GET_BILLINGS);
export const downloadBilling = createPromiseAction(BILLINGS_TYPES.BILLING_DOWNLOAD);
export const setBillingsSortOptions = createPromiseAction(BILLINGS_TYPES.BILLINGS_SORT_OPTIONS_SET);
