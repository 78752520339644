import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Common/Button';
import { Row } from 'components/Common/Row';
import { FINANCIALS_INVOICES_LIST_PAGE } from 'const/CLIENT_URL';
import { Column } from 'components/Common/Column';
import { Divider } from 'components/Common/Divider';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Tooltip } from 'components/Common/Tooltip';

function InvoicesAddEditFormFooter({
    disabled, loading, onDelete, onSaveDraft,
}) {
    const { t: tc } = useTranslation();
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    return (
        <Column stretched gap={0}>
            <Divider horisontal padding={0} />
            <Row
                stretched
                justify="end"
                padding={16}
                gap={16}
                wrap
            >
                <Button
                    href={FINANCIALS_INVOICES_LIST_PAGE}
                    color="outline"
                >
                    {tc('placeholders.cancel')}
                </Button>
                {onDelete && (
                    <Button
                        color="red"
                        onClick={onDelete}
                        disabled={disabled}
                    >
                        {tc('placeholders.delete')}
                    </Button>
                )}
                {
                    onSaveDraft && (
                        <Tooltip tooltip={t('add.status.draft.tooltip')} forButton>
                            <Button
                                color="outline"
                                onClick={onSaveDraft}
                                disabled={disabled || loading}
                            >
                                {tc('placeholders.saveDraft')}
                            </Button>
                        </Tooltip>
                    )
                }
                <Button
                    type="submit"
                    color="yellow"
                    disabled={disabled || loading}
                >
                    {tc('placeholders.next')}
                </Button>
            </Row>
        </Column>
    );
}

InvoicesAddEditFormFooter.defaultProps = {
    disabled: false,
    loading: false,
    onDelete: null,
    onSaveDraft: null,
};

export default InvoicesAddEditFormFooter;
