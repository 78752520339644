import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { Button } from 'components/Common/Button';
import { CheckBox } from 'components/Common/CheckBox';
import NumberInput from 'components/Common/NumberInput';
import { Dialog } from 'components/Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from 'components/Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from 'components/Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from 'components/Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from 'components/Common/Dialogs/Dialog/DialogFooter';
import { Column } from 'components/Common/Column';
import { Label } from 'components/Common/Typography/Label';
import { useBool } from '../../../hooks/useBool';
import { emptyFunc } from '../../../helpers/function/emptyFunc';
import { generateRandomString } from '../../../helpers/string/generateRandomString';
import { getError, isInvalid } from '../../../services/validationService';

import DateInput from '../../DateInput/DateInput';

import { CalendarIcon } from '../../Icon/Icon';

import * as BUNDLES_ACTIONS from '../../../store/actions/bundles';

import { SOLD_BUNDLE_PROP } from '../../../const/bundles/SOLD_BUNDLE_PROP';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'sold.dialogs.edit';
const T_BODY = `${T_PREFIX}.body`;
const T_FIELDS = `${T_BODY}.form.fields`;

const initialValues = {
    endsAt: {
        value: moment(new Date(SOLD_BUNDLE_PROP.ENDS_AT.VALUE.DEFAULT)),
        unlimited: SOLD_BUNDLE_PROP.ENDS_AT.UNLIMITED.DEFAULT,
    },
    purchasedAt: moment(new Date(SOLD_BUNDLE_PROP.PURCHASED_AT.DEFAULT)),
    maxUsages: {
        value: SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.DEFAULT,
        unlimited: SOLD_BUNDLE_PROP.MAX_USAGES.UNLIMITED.DEFAULT,
    },
};

const DATA_TRANSFORMER = {
    send: (data) => {
        const formData = new FormData();
        formData.append('endsAt', data.endsAt.value.unix());
        formData.append('unlimitedPeriod', Number(data.endsAt.unlimited));
        if (data.maxUsages.value) {
            formData.append('maxUsages', data.maxUsages.value);
        }
        formData.append('unlimitedUsages', Number(data.maxUsages.unlimited));
        return formData;
    },
};

const SoldBundleEditFormDialog = (props) => {
    const {
        visible,
        soldBundle,
        onClose,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    const hasErrors = useBool(false);

    const dispatch = useDispatch();

    const validationSchema = useMemo(() => yup.object({
        endsAt: yup.object({
            unlimited: yup.bool(),
            value: yup.mixed()
                .when('unlimited', {
                    is: true,
                    then: yup.mixed().nullable(true),
                    otherwise: yup.mixed()
                        .when('purchasedAt', (purchasedAt, schema) => schema.test({
                            name: generateRandomString(),
                            test: (endsAt) => endsAt?.isSameOrAfter(purchasedAt),
                            message: t(`${T_FIELDS}.endsAt.value.validation.afterPurchase`, {
                                date: purchasedAt?.format('DD.MM.YY'),
                            }),
                        })).test({
                            name: generateRandomString(),
                            test: (endsAt) => {
                                const today = moment(new Date()).startOf('date');
                                return endsAt?.isSameOrAfter(today);
                            },
                            message: t(`${T_FIELDS}.endsAt.value.validation.todayOrAfter`),
                        })
                        .required(t(`${T_FIELDS}.endsAt.value.validation.required`)),
                }),
        }),
        maxUsages: yup.object({
            unlimited: yup.bool(),
            value: yup.number()
                .when('unlimited', {
                    is: true,
                    then: yup.number().nullable(true),
                    otherwise: yup.number()
                        .min(
                            SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MIN,
                            t(`${T_FIELDS}.maxUsages.value.validation.min`, {
                                value: SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MIN,
                            }),
                        )
                        .max(
                            SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MAX,
                            t(`${T_FIELDS}.maxUsages.value.validation.max`, {
                                value: SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MAX,
                            }),
                        )
                        .required(t(`${T_FIELDS}.maxUsages.value.validation.required`)),
                }),
        }),
    }), [t]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: useCallback((values) => {
            if (!soldBundle?.id) {
                return;
            }
            const transformedValues = DATA_TRANSFORMER.send(values);
            dispatch(BUNDLES_ACTIONS.editSoldBundlesItem({
                soldBundleId: soldBundle.id,
                soldBundle: transformedValues,
            }));
            onClose();
        }, [soldBundle?.id, dispatch, onClose]),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setTouched,
    } = formik;

    const validation = useMemo(() => {
        hasErrors.onFalse();
        return Object.keys(values).reduce((res, k) => {
            const value = values[k];
            let subValidation;
            const objects = ['maxUsages', 'endsAt'];
            if (objects.includes(k)) {
                subValidation = Object.keys(value).reduce((res1, kk) => {
                    const invalid = isInvalid(`${k}.${kk}`, errors, touched);
                    if (invalid) {
                        hasErrors.onTrue();
                    }
                    return {
                        ...res1,
                        [kk]: {
                            isInvalid: invalid,
                            error: getError(`${k}.${kk}`, errors),
                        },
                    };
                }, {});
            } else {
                const invalid = isInvalid(k, errors, touched);
                if (invalid) {
                    hasErrors.onTrue();
                }
                subValidation = {
                    isInvalid: invalid,
                    error: getError(k, errors),
                };
            }
            return {
                ...res,
                [k]: subValidation,
            };
        }, {});
    }, [values, errors, touched]);

    const handleChangeEndsAt = useCallback((endsAt) => {
        setFieldTouched('endsAt.value', true);
        setFieldValue('endsAt.value', endsAt);
    }, [setFieldValue, setFieldTouched]);

    useEffect(() => {
        if (!visible || !soldBundle) {
            return;
        }

        setTouched({});

        setFieldValue('purchasedAt', moment.unix(soldBundle.purchasedAt));
        setFieldValue(
            'endsAt.value',
            soldBundle.endsAt
                ? moment.unix(soldBundle.endsAt)
                : moment(),
        );
        setFieldValue('endsAt.unlimited', !soldBundle.endsAt);
        setFieldValue('maxUsages.value', soldBundle.bookings.max ?? '');
        setFieldValue('maxUsages.unlimited', soldBundle.bookings.unlimited);
    }, [visible, soldBundle]);

    useEffect(() => {
        if (values.maxUsages.unlimited) {
            setFieldValue('maxUsages.value', '');
        }
    }, [values.maxUsages.unlimited]);

    return (
        <Dialog
            visible={visible}
            restoreFocus={false}
            onClose={onClose}
            size="md"
        >
            <DialogHeader closeButton>
                <DialogTitle>
                    {t(`${T_PREFIX}.header.title`)}
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
                <Form onSubmit={handleSubmit}>
                    <Column stretched gap={16}>
                        <Column stretched gap={8}>
                            <Label>
                                {t(`${T_FIELDS}.endsAt.value.label`)}
                            </Label>
                            <InputGroup>
                                <DateInput
                                    isDisabled={values.endsAt.unlimited}
                                    value={values.endsAt.unlimited ? null : values.endsAt.value}
                                    onChange={handleChangeEndsAt}
                                    isInvalid={validation?.endAt?.value?.isInvalid}
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>
                                        <CalendarIcon width={18} />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-block': validation?.endsAt?.value?.isInvalid,
                                })}
                            >
                                {validation?.endsAt?.value?.error}
                            </Form.Control.Feedback>
                        </Column>
                        <Column stretched gap={8}>
                            <CheckBox
                                id="endsAt.unlimited"
                                name="endsAt.unlimited"
                                label={t(`${T_FIELDS}.endsAt.unlimited.label`)}
                                checked={values.endsAt.unlimited}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-block': validation?.endsAt?.unlimited?.isInvalid,
                                })}
                            >
                                {validation?.endsAt?.unlimited?.error}
                            </Form.Control.Feedback>
                        </Column>
                        <Column stretched gap={8}>
                            <Label>
                                {t(`${T_FIELDS}.maxUsages.value.label`)}
                            </Label>
                            <NumberInput
                                name="maxUsages.value"
                                value={values.maxUsages.value}
                                onChange={(value) => setFieldValue('maxUsages.value', value)}
                                isInvalid={validation?.maxUsages?.value?.isInvalid}
                                min={SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MIN}
                                max={SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MAX}
                                step={SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.STEP}
                                disabled={values.maxUsages.unlimited}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-block': validation?.maxUsages?.value?.isInvalid,
                                })}
                            >
                                {validation?.maxUsages?.value?.error}
                            </Form.Control.Feedback>
                        </Column>
                        <Column stretched gap={8}>
                            <CheckBox
                                id="maxUsages.unlimited"
                                name="maxUsages.unlimited"
                                label={t(`${T_FIELDS}.maxUsages.unlimited.label`)}
                                checked={values.maxUsages.unlimited}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-block': validation?.maxUsages?.unlimited?.isInvalid,
                                })}
                            >
                                {validation?.maxUsages?.unlimited?.error}
                            </Form.Control.Feedback>
                        </Column>
                    </Column>
                </Form>
            </DialogBody>
            <DialogFooter>
                <Button
                    color="outline"
                    onClick={onClose}
                >
                    {t(`${T_BODY}.actions.cancel`)}
                </Button>
                <Button
                    type="submit"
                    disabled={hasErrors.value}
                >
                    {t(`${T_BODY}.actions.save`)}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

SoldBundleEditFormDialog.propTypes = {
    visible: PropTypes.bool,
    soldBundle: PropTypes.object,
    onClose: PropTypes.func,
};

SoldBundleEditFormDialog.defaultProps = {
    visible: false,
    soldBundle: null,
    onClose: emptyFunc,
};

export default SoldBundleEditFormDialog;
