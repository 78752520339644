import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Badge } from 'components/Common/Badge';

import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';

import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { ACTIVITY_ACTION_TYPE } from 'const/activity/ACTIVITY_ACTION_TYPE';
import { Row } from 'components/Common/Row';
import { Link } from 'react-router-dom';
import { Avatar } from 'components/Common/Avatar';

import { CLIENTS_LIST_ID_ACTIVITY_PAGE } from 'const/CLIENT_URL';
import { Label } from 'components/Common/Typography/Label';
import { shortLocaleSelector } from 'store/selectors/locales';
import { safeJsonParse } from 'helpers/json/safeJsonParse';
import momentGMT0Time from 'helpers/time/momentGMT0Time';
import * as styles from './ActivityTableBodyRow.module.scss';

const statusClasses = {
    [ACTIVITY_ACTION_TYPE.BOOKED]: 'green',
    [ACTIVITY_ACTION_TYPE.CANCELLED]: 'red',
    [ACTIVITY_ACTION_TYPE.EDITED]: 'blue',
    [ACTIVITY_ACTION_TYPE.PURCHASED]: 'lightYellow',
    [ACTIVITY_ACTION_TYPE.PAUSED]: 'black',
    [ACTIVITY_ACTION_TYPE.RESUMED]: 'gray',
    [ACTIVITY_ACTION_TYPE.ENDED]: 'lightGray',
    [ACTIVITY_ACTION_TYPE.DELETED]: 'gray',
};

const T_PREFIX = 'activity.list.table.row';

const ActivityTableBodyRow = (props) => {
    const {
        log,
        isClientPage,
    } = props;

    const {
        id,
        action: rawAction,
        client,
        createdAt,
        details,
        product,
        productName,
        shop,
        staff,
        reason,
    } = log || {};

    const { t } = useTranslation(LOCALE_NAMESPACE.ACTIVITY);
    const shortLocale = useSelector(shortLocaleSelector);

    const staffName = `${staff?.firstName || ''} ${staff?.lastName || ''}`.trim();
    const clientName = `${client?.firstName || ''} ${client?.lastName || ''}`.trim();
    const detailsObjetct = useMemo(() => safeJsonParse(details), [details]);
    const action = rawAction.toLowerCase();
    const href = !isClientPage && client?.id ? CLIENTS_LIST_ID_ACTIVITY_PAGE({ clientId: client.id }) : undefined;

    return (
        <ListTableBodyRow data-testid={`data-test-activity-list-table-body-row-${id}`}>
            <ListTableBodyCell data-testid="data-test-activity-list-table-body-cell-timestamp">
                {momentGMT0Time(createdAt).format('DD MMM YYYY · HH:mm:ss')}
            </ListTableBodyCell>
            <ListTableBodyCell data-testid="data-test-activity-list-table-body-cell-location">
                {shop?.name || '-'}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap" data-testid="data-test-activity-list-table-body-cell-employee">
                <Row gap={8}>
                    {!!staffName && (
                        <Avatar
                            data-testid="data-test-activity-list-table-body-cell-employee-avatar"
                            color="primary"
                            src={staff?.avatarUrl || ''}
                            name={staffName}
                        />
                    )}
                    <Label data-testid="data-test-activity-list-table-body-cell-employee-name" noWrap>
                        {staffName || '-'}
                    </Label>
                </Row>
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap" data-testid="data-test-activity-list-table-body-cell-client">
                <Row gap={8}>
                    {!!clientName && (
                        <Avatar
                            data-testid="data-test-activity-list-table-body-cell-client-avatar"
                            src={client?.avatarUrl || ''}
                            name={clientName}
                            href={href}
                        />
                    )}
                    {href ? (
                        <Link to={href} className={styles.name} data-testid="data-test-activity-list-table-body-cell-client-name">
                            {clientName}
                        </Link>
                    ) : <Label data-testid="data-test-activity-list-table-body-cell-client-name" noWrap>{clientName}</Label>}
                </Row>
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap" data-testid="data-test-activity-list-table-body-cell-product-type">
                {product ? t(`activity.header.productType.${product}`) : '-'}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap" data-testid="data-test-activity-list-table-body-cell-product-name">
                {productName || '-'}
            </ListTableBodyCell>
            <ListTableBodyCell data-testid="data-test-activity-list-table-body-cell-action">
                <Badge size="small" color={statusClasses[action]} data-testid="data-test-activity-list-table-body-cell-action-badge">
                    {t(`${T_PREFIX}.action.${action}`)}
                </Badge>
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap" data-testid="data-test-activity-list-table-body-cell-details">
                {detailsObjetct?.[shortLocale] || '-'}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap" data-testid="data-test-activity-list-table-body-cell-reason">
                {reason || '-'}
            </ListTableBodyCell>
        </ListTableBodyRow>
    );
};

ActivityTableBodyRow.propTypes = {
    log: PropTypes.object,
    isClientPage: PropTypes.bool,
};

ActivityTableBodyRow.defaultProps = {
    log: null,
    isClientPage: false,
};

export default ActivityTableBodyRow;
