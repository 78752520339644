import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Label } from 'components/Common/Typography/Label';
import { Column } from 'components/Common/Column';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';
import { formatPrice } from '../../../../helpers/formatters/formatPrice';

import {
    dangerTheme as selectDangerTheme,
    styles as selectStyles,
    theme as selectTheme,
} from '../../../../styles/select';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'sell.form';
const T_FIELDS = `${T_PREFIX}.fields`;

const BundleSelectFormRow = (props) => {
    const {
        values,
        bundles,
        selectedBundle,
        validation,
        onChangeBundle,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    const bundleSelectTheme = validation?.bundle?.isInvalid ? selectDangerTheme : selectTheme;

    const bundleOptions = useMemo(() => (
        bundles.map((b) => ({
            value: b.id,
            label: `${b.name} - ${formatPrice.toEuroWithComma({ amount: b.price })}`,
        }))
    ), [bundles]);

    const bundleValue = useMemo(() => ({
        value: values.bundle,
        label: selectedBundle
            ? `${selectedBundle.name} - ${formatPrice.toEuroWithComma({ amount: selectedBundle.price })}`
            : '',
    }), [values.bundle, selectedBundle]);

    const price = formatPrice.toEuroWithComma({ amount: selectedBundle?.price });

    const isBundleSelected = Boolean(values.bundle);

    return (
        <Column stretched gap={16}>
            <Column stretched gap={8}>
                <Label>
                    {t(`${T_FIELDS}.bundle.label`)}
                </Label>
                <Select
                    name="bundle"
                    value={bundleValue}
                    theme={bundleSelectTheme}
                    styles={selectStyles}
                    options={bundleOptions}
                    onChange={onChangeBundle}
                    placeholder={t(`${T_FIELDS}.bundle.placeholder`)}
                />
                <Form.Control.Feedback
                    type="invalid"
                    className={classNames({
                        'd-block': validation?.bundle?.isInvalid,
                    })}
                >
                    {validation?.bundle?.error}
                </Form.Control.Feedback>
            </Column>
            {isBundleSelected && (
                <React.Fragment>
                    <Column stretched gap={8}>
                        <Label>
                            {t(`${T_FIELDS}.description.label`)}
                        </Label>
                        <Form.Control
                            value={selectedBundle?.description}
                            disabled
                        />
                    </Column>
                    <Column stretched gap={8}>
                        <Label>
                            {t(`${T_FIELDS}.price.label`)}
                        </Label>
                        <Form.Control
                            value={price}
                            disabled
                        />
                    </Column>
                </React.Fragment>
            )}

        </Column>
    );
};

BundleSelectFormRow.propTypes = {
    values: PropTypes.object.isRequired,
    bundles: PropTypes.array,
    selectedBundle: PropTypes.object,
    validation: PropTypes.object,
    onChangeBundle: PropTypes.func,
};

BundleSelectFormRow.defaultProps = {
    bundles: [],
    selectedBundle: null,
    validation: null,
    onChangeBundle: emptyFunc,
};

export default BundleSelectFormRow;
