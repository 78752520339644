import { createPromiseAction } from '@adobe/redux-saga-promise';
import * as CLIENTS_TYPES from './types';

export const getClients = () => ({
    type: CLIENTS_TYPES.CLIENTS_GET,
});

export const setClients = ({
    clients, itemsCount, pagesCount, total,
}) => ({
    type: CLIENTS_TYPES.CLIENTS_SET,
    payload: {
        clients,
        itemsCount,
        pagesCount,
        total,
    },
});

export const setClientsPage = ({ page }) => ({
    type: CLIENTS_TYPES.CLIENTS_PAGE_SET,
    payload: { page },
});

export const setClientsPageFirst = () => ({
    type: CLIENTS_TYPES.CLIENTS_PAGE_SET_FIRST,
});

export const setClientsPageLast = () => ({
    type: CLIENTS_TYPES.CLIENTS_PAGE_SET_LAST,
});

export const setClientsPagePrev = () => ({
    type: CLIENTS_TYPES.CLIENTS_PAGE_SET_PREV,
});

export const setClientsPageNext = () => ({
    type: CLIENTS_TYPES.CLIENTS_PAGE_SET_NEXT,
});

export const addClientsItem = ({ data, onSuccess, onError }) => ({
    type: CLIENTS_TYPES.CLIENTS_ITEM_ADD,
    payload: {
        data,
        onSuccess,
        onError,
    },
});

export const deleteClientsItem = ({ clientId }) => ({
    type: CLIENTS_TYPES.CLIENTS_ITEM_DELETE,
    payload: { clientId },
});

export const toggleClientsItemBlock = createPromiseAction(CLIENTS_TYPES.CLIENTS_ITEM_BLOCK_TOGGLE);

export const setClientsSearch = ({ search }) => ({
    type: CLIENTS_TYPES.CLIENTS_SEARCH_SET,
    payload: { search },
});

export const applyDiscountProfiles = ({ data }) => ({
    type: CLIENTS_TYPES.CLIENTS_APPLY_DISCOUNT,
    payload: { data },
});

export const sendClientsInvitation = ({ data }) => ({
    type: CLIENTS_TYPES.CLIENTS_INVITATION_SEND,
    payload: { data },
});

export const getCompanyDiscountProfiles = createPromiseAction(CLIENTS_TYPES.CLIENTS_FILTRATION_DISCOUNT_GET);

export const getCompanyDiscountProfilesSucceeded = (discountItems) => ({
    type: CLIENTS_TYPES.CLIENTS_FILTRATION_DISCOUNT_SET,
    discountItems,
});

export const setCompanyDiscountProfiles = ({ discountItems }) => ({
    type: CLIENTS_TYPES.CLIENTS_FILTRATION_DISCOUNT_SET,
    payload: { discountItems },
});

export const clientsFiltrationShop = ({ shopId }) => ({
    type: CLIENTS_TYPES.CLIENTS_FILTRATION_SHOP,
    payload: { shopId },
});

export const clientsFiltrationRegistered = ({ registeredStatus }) => ({
    type: CLIENTS_TYPES.CLIENTS_FILTRATION_REGISTERED,
    payload: { registeredStatus },
});

export const setClientsLoading = ({ loading }) => ({
    type: CLIENTS_TYPES.CLIENTS_LOADING_SET,
    payload: { loading },
});

export const setClientsSort = ({ sortBy, sortType }) => ({
    type: CLIENTS_TYPES.CLIENTS_SORT_SET,
    payload: { sortBy, sortType },
});

export const getClientItem = createPromiseAction(CLIENTS_TYPES.CLIENT_ITEM_GET);
export const getClientSilentItem = createPromiseAction(CLIENTS_TYPES.CLIENT_ITEM_SILENT_GET);
export const sendUpdateIbanLink = createPromiseAction(CLIENTS_TYPES.CLIENT_SEND_UPDATE_IBAN_LINK);
export const updateIban = createPromiseAction(CLIENTS_TYPES.CLIENT_UPDATE_IBAN);

export const editClientItem = ({
    clientId,
    data,
    onSuccess,
    onError,
}) => ({
    type: CLIENTS_TYPES.CLIENTS_ITEM_EDIT,
    payload: {
        clientId,
        data,
        onSuccess,
        onError,
    },
});

// Client bookings

export const getClientBookings = ({ clientId }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_GET,
    payload: { clientId },
});

export const setClientBookings = ({
    bookings, itemsCount, pagesCount, active_bookings,
}) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_SET,
    payload: {
        bookings,
        itemsCount,
        pagesCount,
        active_bookings,
    },
});

export const setClientBookingsPage = ({ page }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_PAGE_SET,
    payload: { page },
});

export const setClientBookingsPageFirst = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_PAGE_SET_FIRST,
});

export const setClientBookingsPageLast = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_PAGE_SET_LAST,
});

export const setClientBookingsPagePrev = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_PAGE_SET_PREV,
});

export const setClientBookingsPageNext = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_PAGE_SET_NEXT,
});

export const setClientBookingsLoading = ({ loading }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_LOADING_SET,
    payload: { loading },
});

export const setClientBookingsFilters = ({ year, month, period }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_FILTERS_SET,
    payload: { year, month, period },
});

export const requestExportClientList = () => ({
    type: CLIENTS_TYPES.CLIENTS_EXPORT,
});
