import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
    SubscriptionsHeader,
    SubscriptionsFooter,
} from 'components';

import { Table } from 'components/Common/NewTable/Table';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { SubscriptionsListTableColumns } from 'components/subscriptions/SubscriptionsListTableColumns';
import { SubscriptionsListTableRow } from 'components/subscriptions/SubscriptionsListTableRow';

import {
    deleteSubscription,
    getSubscriptionsList,
    setCurrentPage,
    setSearchValue,
    setShowAll,
    toggleSubscriptionsHideArchived,
    toggleSubscriptionStatus,
} from 'store/actions';
import {
    subscriptionsListPageSelector,
    subscriptionsTableFooterSelector,
} from 'store/selectors/subscriptions';

import { SUBSCRIPTIONS_ADD_PAGE } from 'const/CLIENT_URL';

function SubscriptionsList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        isDataLoaded,
        subscriptionsList,
        searchValue,
        hideArchived,
    } = useSelector(subscriptionsListPageSelector);

    const {
        currentPage,
        itemsCount,
        pagesCount,
        showAll,
    } = useSelector(subscriptionsTableFooterSelector);

    const handleSearch = useCallback((search) => {
        dispatch(setSearchValue(search));
    }, [dispatch]);

    const onButtonClickHandler = useCallback(() => {
        history.push(SUBSCRIPTIONS_ADD_PAGE);
    }, [history.push]);

    const handleToggleStatus = useCallback(({ subscriptionId }) => {
        dispatch(toggleSubscriptionStatus(subscriptionId));
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(setShowAll(true));
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(setCurrentPage(currentPage - 1));
    }, [dispatch, currentPage]);

    const handleNextPage = useCallback(() => {
        dispatch(setCurrentPage(currentPage + 1));
    }, [dispatch, currentPage]);

    const handleLastPage = useCallback(() => {
        dispatch(setCurrentPage(pagesCount));
    }, [dispatch, pagesCount]);

    const handleFirstPage = useCallback(() => {
        dispatch(setCurrentPage(1));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(setShowAll(false));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(setCurrentPage(nextPage));
    }, [dispatch]);

    const handleDuplicateSubscription = useCallback(({ subscriptionId }) => {
        history.push(SUBSCRIPTIONS_ADD_PAGE, { subscriptionId });
    }, [dispatch]);

    const handleArchive = useCallback(({ subscriptionId }) => {
        dispatch(deleteSubscription({ subscriptionId }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getSubscriptionsList());
    }, [dispatch, currentPage, searchValue, hideArchived]);

    const handleToggleHideArchived = () => {
        dispatch(toggleSubscriptionsHideArchived({ hideArchived: !hideArchived }));
    };

    return (
        <React.Fragment>
            <SubscriptionsHeader
                onButtonClickHandler={onButtonClickHandler}
                onSearch={handleSearch}
                searchValue={searchValue}
                hideArchived={hideArchived}
                handleToggleHideArchived={handleToggleHideArchived}
            />
            <Container>
                <Content loading={!isDataLoaded}>
                    <Table footer={{
                        page: currentPage,
                        showAll,
                        itemsCount,
                        pagesCount,
                        columnsCount: 5,
                        itemsPerPage: 10,
                        onShowAll: handleShowAll,
                        onPrevPage: handlePrevPage,
                        onNextPage: handleNextPage,
                        onLastPage: handleLastPage,
                        onFirstPage: handleFirstPage,
                        onShowPages: handleShowPages,
                        onChangePage: handleChangePage,
                    }}
                    >
                        <TableHeader>
                            <SubscriptionsListTableColumns />
                        </TableHeader>
                        <TableBody>
                            {subscriptionsList?.map((subscription) => (
                                <SubscriptionsListTableRow
                                    key={subscription?.id}
                                    subscription={subscription}
                                    onToggleStatus={handleToggleStatus}
                                    onArchive={handleArchive}
                                    onDuplicate={handleDuplicateSubscription}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    <SubscriptionsFooter
                        onButtonClickHandler={onButtonClickHandler}
                        onSearch={handleSearch}
                        searchValue={searchValue}
                    />
                </Content>
            </Container>
        </React.Fragment>
    );
}

export default SubscriptionsList;
