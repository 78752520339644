import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import Form from 'react-bootstrap/Form';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';

import * as PRODUCTS_ACTIONS from 'store/actions/products';
import * as INVOICES_ACTIONS from 'store/actions/financials/invoices';
import * as COMPANY_ACTIONS from 'store/actions/company';

import InvoicesAddEditFormFooter from 'components/Financials/Invoices/InvoicesAddEditFormFooter';
import InvoicesAddEditFormHeader from 'components/Financials/Invoices/InvoicesAddEditFormHeader';
import InvoicesInfoFormGroup from 'components/Financials/Invoices/InvoicesInfoFormGroup';
import { INVOICE_PROP } from 'const/financials/invoices/INVOICE_PROP';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import InvoiceProductsFormGroup from 'components/Financials/Invoices/InvoiceProductsFormGroup';
import { generateRandomString } from 'helpers/string/generateRandomString';
import { customInvoiceLoadingSelector, customInvoiceSelector, invoicesSavingSelector } from 'store/selectors/financilas';
import moment from 'moment';
import { INVOICE_STATUS } from 'const/financials/invoices/INVOICE_STATUS';
import InvoiceDraftDeleteDialog from 'components/Financials/Invoices/InvoiceDraftDeleteDialog';
import { debounce } from 'lodash';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { ConfirmationModal } from 'components';
import { Content } from 'components/Common/Content';
import { FINANCIALS_INVOICES_LIST_PAGE } from 'const/CLIENT_URL';
import Container from 'components/Layout/Container/Container';
import { Column } from 'components/Common/Column';
import { InvoicePaymentOptionsModal } from 'components/Financials/Invoices/InvoicePaymentOptionsModal';
import { useDialog } from 'hooks/useDialog';
import { companyPaymentTerminalsSelector } from 'store/selectors/company';
import { useMultisafe } from 'hooks/useMultisafe';

export const productInitialData = () => ({
    name: '',
    price: '',
    vatValue: INVOICE_PROP.VAT_RATE.DEFAULT,
    quantity: INVOICE_PROP.QUANTITY.DEFAULT,
    id: `new-${generateRandomString()}`,
});

function EditInvoicePage() {
    const { t: tc } = useTranslation();
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const isMultifasePay = useMultisafe();

    const [deleteInvoice, setDeleteInvoice] = useState(null);

    const { invoiceId } = useParams();

    const dispatch = useDispatch();
    const saving = useSelector(invoicesSavingSelector);
    const loading = useSelector(customInvoiceLoadingSelector);
    const customInvoice = useSelector(customInvoiceSelector);
    const invoicePaymentOptionsModal = useDialog();
    const terminals = useSelector(companyPaymentTerminalsSelector);

    const formikInitialValues = useMemo(() => (customInvoice ? ({
        client: {
            clientId: customInvoice?.client.id,
            clientName: customInvoice?.client.firstName,
            clientLastName: customInvoice?.client.lastName,
            clientEmail: customInvoice?.client.email,
        },
        note: customInvoice?.note,
        issueDate: moment(), // moment.unix(customInvoice?.issueDate),
        dueDate: moment().add(2, 'weeks'), // moment.unix(customInvoice?.dueDate),
        status: customInvoice?.status,
        products: customInvoice?.customInvoiceProducts.map(({
            product, quantity, price, vatValue,
        }) => ({
            ...product, price, vatValue, quantity,
        })),
    }) : null), [customInvoice]);

    useEffect(() => {
        dispatch(INVOICES_ACTIONS.getCustomInvoice({ bill: invoiceId }));
        dispatch(PRODUCTS_ACTIONS.getProducts());
    }, [dispatch]);

    useEffect(() => {
        if (isMultifasePay) {
            dispatch(COMPANY_ACTIONS.getCompanyPaymentTerminals());
        }
    }, [dispatch, isMultifasePay]);

    const history = useHistory();

    const validationSchema = useMemo(() => yup.object({
        client: yup.mixed().required(tc('validationErrors.cannotBeEmpty', {
            name: tc('addBookingModal.client'),
        })),
        note: yup.string()
            .trim()
            .max(
                INVOICE_PROP.NOTE.MAX_LENGTH,
                tc('validationErrors.maxLength', {
                    name: t('add.note.label'),
                    length: INVOICE_PROP.NOTE.MAX_LENGTH,
                }),
            ),
        issueDate: yup.date()
            .required(tc('validationErrors.cannotBeEmpty', {
                name: t('add.issueDate.label'),
            }))
            .max(yup.ref('dueDate'), tc('validationErrors.dateOrAfter')),
        dueDate: yup.date()
            .required(tc('validationErrors.cannotBeEmpty', {
                name: t('add.dueDate.label'),
            }))
            .min(yup.ref('issueDate'), tc('validationErrors.dateOrBefore')),
        products: yup.array()
            .of(yup.object({
                name: yup.string()
                    .trim()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.name.label'),
                    })),
                price: yup.number()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.price.label'),
                    })),
                vatValue: yup.string()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.vatValue.label'),
                    })),
                quantity: yup.number()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.quantity.label'),
                    })),
            }))
            .min(1, tc('validationErrors.cannotBeEmpty', {
                name: t('add.products.label'),
            })),
    }), [t]);

    const handleEdit = useCallback((invoice) => {
        const data = new FormData();
        data.append('clientId', invoice.client.clientId);
        data.append('note', invoice.note);
        data.append('issueDate', invoice.issueDate.unix());
        data.append('dueDate', invoice.dueDate.unix());
        data.append('status', invoice.status === INVOICE_STATUS.UNPAID ? 'open' : invoice.status);
        if (invoice.terminalId) {
            data.append('terminalId', invoice.terminalId);
        }

        const products = invoice.products.filter(({ id }) => !String(id).startsWith('new-'));
        const productsToCreate = invoice.products.filter(({ id }) => String(id).startsWith('new-'));
        if (products.length) {
            products.forEach((c, i) => data.append(`products[${i}]`, JSON.stringify({
                id: c.id, quantity: c.quantity, price: c.price, vatValue: c.vatValue,
            })));
        }
        if (productsToCreate.length) {
            productsToCreate.forEach((c, i) => data.append(`productsToCreate[${i}]`, JSON.stringify({
                name: c.name, quantity: c.quantity, price: c.price, vatValue: c.vatValue,
            })));
        }

        dispatch(INVOICES_ACTIONS.editCustomInvoice({ data, history, bill: invoiceId }));
    }, [dispatch, history]);

    const saveConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: handleEdit,
    });

    const onSubmit = useCallback(() => {
        invoicePaymentOptionsModal.onShow();
    }, [invoicePaymentOptionsModal]);

    const formik = useFormik({
        initialValues: formikInitialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
    });

    const handleApply = useCallback((invoice) => {
        if (invoice.status === INVOICE_STATUS.PAID || invoice.status === INVOICE_STATUS.UNPAID) {
            saveConfirmDialog.onSetData(invoice);
            saveConfirmDialog.onShow();
        } else {
            handleEdit(invoice);
        }
    }, [handleEdit, saveConfirmDialog]);

    const handleSelectPayment = useCallback((paymentType) => {
        handleApply({ ...formik.values, status: paymentType.type, terminalId: paymentType.terminal?.id });
    }, [formik, handleApply]);

    const handleSaveDraft = useCallback(() => {
        handleApply({ ...formik.values, status: INVOICE_STATUS.DRAFT });
    }, [formik]);

    const handleDelete = useCallback(debounce(() => {
        setDeleteInvoice(invoiceId);
    }, 250), [invoiceId]);

    const { values, handleSubmit } = formik;

    const handleDeleteClose = (status) => {
        if (status) {
            if (history.length > 1) {
                history.goBack();
            } else {
                history.push(FINANCIALS_INVOICES_LIST_PAGE);
            }
        }
        setDeleteInvoice(null);
    };

    const total = useMemo(
        () => values?.products.reduce((acc, product) => {
            const price = Number(product.price);
            const quantity = Number(product.quantity);
            const productTotal = price * quantity;
            return acc + productTotal;
        }, 0) || 0,
        [values?.products],
    );

    return (
        <React.Fragment>
            <InvoicesAddEditFormHeader withDynamic />
            <Form onSubmit={handleSubmit}>
                <Container>
                    <Content loading={loading || !formikInitialValues || !values}>
                        <Column stretched gap={16}>
                            <InvoicesInfoFormGroup formik={formik} total={total} />
                            <InvoiceProductsFormGroup
                                formik={formik}
                            />
                        </Column>
                        <InvoicesAddEditFormFooter disabled={!values?.products.length} loading={saving} onDelete={handleDelete} onSaveDraft={handleSaveDraft} />
                        <InvoiceDraftDeleteDialog invoiceId={deleteInvoice} onClose={handleDeleteClose} />

                    </Content>
                </Container>
            </Form>
            <ConfirmationModal
                isShow={saveConfirmDialog.visible}
                titleText={tc('placeholders.saveChanges')}
                bodyText={(
                    <Trans
                        t={t}
                        i18nKey="add.saveDialog.body.text"
                        values={{
                            name: `${saveConfirmDialog?.data?.client?.clientName} ${saveConfirmDialog?.data?.client?.clientLastName}`,
                            status: t(`add.status.${saveConfirmDialog?.data?.status}.label`),
                        }}
                    />
                )}
                deleteText={t('add.saveDialog.actions.save')}
                dismissText={t('add.saveDialog.actions.cancel')}
                confirmAction={() => {
                    saveConfirmDialog.onConfirm();
                    invoicePaymentOptionsModal.onClose();
                }}
                hide={saveConfirmDialog.onReject}
            />
            <InvoicePaymentOptionsModal
                visible={invoicePaymentOptionsModal.visible && !saveConfirmDialog.visible}
                onClose={invoicePaymentOptionsModal.onClose}
                terminals={terminals}
                hasUnpaid={Boolean(values?.client?.clientEmail)}
                unpaidDisabled={!total}
                onSelectPayment={handleSelectPayment}
            />
        </React.Fragment>
    );
}

export default EditInvoicePage;
