import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './Row.module.scss';

const paddingClasses = {
    0: styles.padding0,
    4: styles.padding4,
    8: styles.padding8,
    12: styles.padding12,
    16: styles.padding16,
    24: styles.padding24,
    32: styles.padding32,
};

const spacingClasses = {
    0: styles.spacing0,
    4: styles.spacing4,
    8: styles.spacing8,
    12: styles.spacing12,
    16: styles.spacing16,
    24: styles.spacing24,
    32: styles.spacing32,
};

const gapClasses = {
    0: styles.gap0,
    4: styles.gap4,
    8: styles.gap8,
    10: styles.gap10,
    12: styles.gap12,
    16: styles.gap16,
    24: styles.gap24,
    32: styles.gap32,
};

const justifyClasses = {
    start: styles.justifyStart,
    center: styles.justifyCenter,
    end: styles.justifyEnd,
    between: styles.justifyBetween,
    around: styles.justifyAround,
};

const Row = (props) => (
    <div
        name={props.name}
        className={
            classNames(
                styles.row,
                paddingClasses[props.padding],
                gapClasses[props.gap],
                props.stretched ? styles.stretched : '',
                props.noOverflow ? styles.noOverflow : '',
                (props.wrap) ? styles.wrap : '',
                styles[props.align],
                styles[`justify${props.justify}`],
                justifyClasses[props.justify],
                props.scrollable ? styles.scrollable : '',
                spacingClasses[props.spacing],
                props.onClick ? styles.clickable : '',
                props.paddingTop ? styles.paddingTop : '',
                props.flex ? styles.flex : '',
            )
        }
        style={props.style}
        data-testid={props['data-testid']}
        onClick={props.onClick}
    >
        {props.children}
    </div>
);

Row.propTypes = {
    style: PropTypes.object,
    padding: PropTypes.oneOf(Object.keys(paddingClasses).map(Number)),
    spacing: PropTypes.oneOf(Object.keys(spacingClasses).map(Number)),
    gap: PropTypes.oneOf(Object.keys(gapClasses).map(Number)),
    stretched: PropTypes.bool,
    wrap: PropTypes.bool,
    align: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
    justify: PropTypes.oneOf(Object.keys(justifyClasses)),
    noOverflow: PropTypes.bool,
    scrollable: PropTypes.bool,
    children: PropTypes.node.isRequired,
    'data-testid': PropTypes.string,
    onClick: PropTypes.func,
    paddingTop: PropTypes.bool,
    flex: PropTypes.bool,
};

Row.defaultProps = {
    style: {},
    gap: 8,
    spacing: 0,
    padding: 0,
    stretched: false,
    wrap: false,
    align: 'center',
    justify: 'start',
    noOverflow: false,
    scrollable: false,
    'data-testid': undefined,
    onClick: undefined,
    paddingTop: false,
    flex: false,
};

export default Row;
