import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import * as COMPANY_ACTIONS from 'store/actions/company';

import { formatPrice } from 'helpers/formatters/formatPrice';
import TextButton from 'components/Common/TextButton/TextButton';
import IconButton from 'components/Common/IconButton/IconButton';
import { Button } from 'components/Common/Button';
import { Content } from 'components/Common/Content';
import { Dialog } from 'components/Common/Dialogs/Dialog/Dialog';
import { DialogFooter } from 'components/Common/Dialogs/Dialog/DialogFooter';
import { DialogHeader } from 'components/Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from 'components/Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from 'components/Common/Dialogs/Dialog/DialogBody';
import { Divider } from 'components/Common/Divider';
import { companyPaymentTerminalsSelector } from 'store/selectors/company';
import { Column } from 'components/Common/Column';
import { Row } from 'components/Common/Row';
import { Label } from 'components/Common/Typography/Label';
import { useMobile } from 'hooks/useMobile';
import { useMultisafe } from 'hooks/useMultisafe';
import { useBool } from '../../hooks/useBool';
import { useDialog } from '../../hooks/useDialog';
import { getFullName } from '../../helpers/services/getFullName';

import { Time, Trash3Icon } from '../Icon/Icon';
import { ConfirmationModal } from '..';

import * as styles from './BookingModal.module.scss';

import * as actions from '../../store/actions';
import { permissionsBookingSelector } from '../../store/selectors/permissions';

import { PAYMENT_TYPE } from '../../const/payments/PAYMENT_TYPE';
import { PRODUCT_TYPE } from '../../const/product/PRODUCT_TYPE';
import { PAYMENT_SYSTEM_TYPE } from '../../const/payments/PAYMENT_SYSTEM_TYPE';
import { CLIENTS_LIST_ID_PAGE } from '../../const/CLIENT_URL';
import EditBookingConfirmModal from './EditBookingConfirmModal';

const NOT_IDEAL_PAYMENTS = [PAYMENT_TYPE.CASH, PAYMENT_TYPE.SUBSCRIPTION, PAYMENT_TYPE.BUNDLE, PAYMENT_TYPE.DISCOUNT];

const BookingModal = ({
    bookingIDWithTimestamp, show, onHide, onEditChange, productType = 'object',
}) => {
    const bookings = useSelector((state) => state[`${productType}s`].bookings);
    const { cancellationFee } = useSelector((state) => state.company);
    const terminals = useSelector(companyPaymentTerminalsSelector);
    const isMultifasePay = useMultisafe();
    const isMobile = useMobile();

    const dispatch = useDispatch();

    const confirmationChangeStatusPaid = useDialog(false);
    const addBookingConfirmModal = useDialog(false);

    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isPresentModal, setPresentModal] = useState(false);
    const [isDeletingRecurringBooking, setDeletingRecurringBooking] = useState(false);
    const [isDeletingBooking, setDeletingBooking] = useState(false);
    const [isOmittingBooking, setOmittingBooking] = useState(false);
    const [isChangingPayBooking, setChangingPayBooking] = useState(false);

    const booking = bookings && bookings.find(({ id, time }) => id === bookingIDWithTimestamp?.id && time.from === bookingIDWithTimestamp?.timestamp);

    const {
        banEdit,
        banDelete,
        banSetPaid,
        banSetPresent,
    } = useSelector(permissionsBookingSelector(booking?.recurrence.enabled));

    const { addToast } = useToasts();

    const { t, i18n } = useTranslation();

    const isWithCancellationFee = useBool(true);
    const isNeedWithCancellationFeeParam = useBool(false);

    const isBookingPaid = booking?.payment?.paid;
    const isEditable = (
        !banEdit
        && ![PRODUCT_TYPE.GROUP, PRODUCT_TYPE.EVENT].includes(productType)
    );
    const isBookingStatusPaid = isBookingPaid || (booking?.payment?.finalCost === 0 && booking?.payment?.type !== 'subscription');
    const paymentType = `paymentTypes.${booking?.payment.type.toLowerCase()}`;

    const generalBanDelete = (timestamp) => {
        if (!booking?.recurrence.enabled && banDelete) return true;
        if (booking?.recurrence.enabled
            && timestamp
            && banDelete.oneRecurring
        ) return true;
        if (booking?.recurrence.enabled
            && !timestamp
            && banDelete.wholeRecurring
        ) return true;

        return false;
    };

    const onDeleteHandler = async (timestamp) => {
        if (
            generalBanDelete(timestamp)
            || isDeletingBooking
            || isDeletingRecurringBooking
        ) {
            return;
        }

        const loadingHandler = timestamp ? setDeletingBooking : setDeletingRecurringBooking;
        const label = t(`bookingModal.${timestamp ? 'b' : 'recurringB'}ookingHasBeenSuccessfullyDeleted`);
        loadingHandler(true);
        const data = {
            booking,
            timestamp,
        };
        if (isNeedWithCancellationFeeParam.value) {
            data.isWithCancellationFee = isWithCancellationFee.value;
        }

        try {
            await dispatch(actions.deleteBooking(data));
            addToast(label, {
                appearance: 'success',
            });
            setDeleteModalVisible(false);
            onHide();
        } catch ({ message }) {
            addToast(message, {
                appearance: 'error',
            });
        } finally {
            loadingHandler(false);
        }
    };

    const onOmitHandler = async () => {
        if (banSetPresent || isOmittingBooking) return;

        setOmittingBooking(true);
        try {
            await dispatch(actions.omitBooking({
                bookingID: booking.id,
                timestamp: booking.time.from / 1000,
            }));
            addToast(t('bookingModal.bookingHasBeenOmitted'), {
                appearance: 'success',
            });
            setPresentModal(false);
        } catch ({ message }) {
            addToast(message, {
                appearance: 'error',
            });
        } finally {
            setOmittingBooking(false);
        }
    };

    const isCanChange = useMemo(() => (
        !banSetPaid
        && booking?.payment?.finalCost
        // '-' is a temporary solution for the case when the payment type is not set, but the booking is maid and we need to change the status
        && [PAYMENT_TYPE.SUBSCRIPTION, PAYMENT_TYPE.CASH, PAYMENT_TYPE.IDEAL, '-'].includes(booking?.payment?.type)
    ), [
        banSetPaid,
        booking?.payment?.finalCost,
        booking?.payment?.type,
    ]);

    const onChangePayHandler = useCallback(async (status, terminalId) => {
        setChangingPayBooking(true);
        try {
            await dispatch(actions.changePayStatus({
                bookingID: booking.id,
                timestamp: booking.time.from / 1000,
                paymentStatus: status,
                terminalId,
            }));
            addToast(t('addBookingModal.payStatusChangedSuccessfully'), {
                appearance: 'success',
            });
        } catch ({ message }) {
            addToast(message, {
                appearance: 'error',
            });
        }
        setChangingPayBooking(false);
        confirmationChangeStatusPaid.onClose();
    }, [booking, isBookingPaid]);

    useEffect(() => {
        if (!booking) {
            onHide();
        }
    }, [booking]);

    useEffect(() => {
        if (isMultifasePay) {
            dispatch(COMPANY_ACTIONS.getCompanyPaymentTerminals());
        }
    }, [dispatch, isMultifasePay]);

    const handleChangePaymentStatus = useCallback(() => {
        if (isBookingStatusPaid || !terminals?.length) {
            confirmationChangeStatusPaid.onShow();
            return;
        }

        addBookingConfirmModal.onShow();
    }, [terminals, isBookingStatusPaid]);

    const handleSelectPayment = useCallback((paymentType) => {
        onChangePayHandler(paymentType.type, paymentType?.terminal?.id);
        addBookingConfirmModal.onClose();
    }, [dispatch, onChangePayHandler]);

    if (!booking) {
        return null;
    }

    const {
        time: {
            from,
        },
        payment: {
            type,
            paymentSystem,
            finalCost,
            paid,
        },
        cancellationPolicy,
    } = booking;

    const confirmCancelText = (() => {
        const isFreeBooking = !finalCost || !paid;
        const isBundleOrSubscriptionPaymentType = [PAYMENT_TYPE.BUNDLE, PAYMENT_TYPE.SUBSCRIPTION].includes(type);
        const hasCancellationFee = !NOT_IDEAL_PAYMENTS.includes(type.toLowerCase());
        const isInsideCancellationPolicy = moment.unix(from / 1000).diff(moment(), 'hours') > (cancellationPolicy ?? 0);
        const isCashPaymentType = type === PAYMENT_TYPE.CASH;

        if ((isFreeBooking || !hasCancellationFee) && isInsideCancellationPolicy) {
            return (
                <p className={classNames('mb-2', styles.confirmationModalText)}>
                    {isBundleOrSubscriptionPaymentType ? t('bookingModal.cancelConfirmation.freeBookingRefund') : t('bookingModal.cancelConfirmation.freeBooking')}
                </p>
            );
        }

        if ((isFreeBooking && !isBundleOrSubscriptionPaymentType) || isCashPaymentType) {
            return (
                <p className={classNames('mb-2', styles.confirmationModalText)}>
                    {t('bookingModal.cancelConfirmation.freeBooking')}
                </p>
            );
        }

        if (hasCancellationFee && paymentSystem === PAYMENT_SYSTEM_TYPE.PAY_PRO) {
            return (
                <p className={classNames('mb-2', styles.confirmationModalText)}>
                    {t('bookingModal.payProWarning')}
                </p>
            );
        }

        if (isInsideCancellationPolicy) {
            return (
                <p className={classNames('mb-2', styles.confirmationModalText)}>
                    {hasCancellationFee ? (
                        t(
                            'bookingModal.cancelConfirmation.insideCancellationPolicy',
                            { price: cancellationFee ?? 0 },
                        )
                    ) : t('bookingModal.cancelConfirmation.freeBooking')}
                </p>
            );
        }

        if (!isNeedWithCancellationFeeParam.value) {
            isNeedWithCancellationFeeParam.onTrue();
        }
        return (
            <React.Fragment>
                <p className={classNames('mb-2', styles.confirmationModalText)}>
                    {t(
                        'bookingModal.cancelConfirmation.outsideCancellationPolicy.title',
                        { price: cancellationFee ?? 0 },
                    )}
                </p>

                <label className="mb-2 d-flex align-items-center cursor-pointer">
                    <input
                        type="radio"
                        checked={isWithCancellationFee.value}
                        onClick={isWithCancellationFee.onTrue}
                        className={styles.confirmationModalRadio}
                    />
                    {hasCancellationFee ? (
                        t(
                            'bookingModal.cancelConfirmation.outsideCancellationPolicy.options.withCancelationFee',
                            { price: cancellationFee ?? 0 },
                        )
                    ) : t('bookingModal.cancelConfirmation.outsideCancellationPolicy.options.withRefund')}
                </label>

                <label className="d-flex align-items-center cursor-pointer">
                    <input
                        type="radio"
                        checked={!isWithCancellationFee.value}
                        onClick={isWithCancellationFee.onFalse}
                        className={styles.confirmationModalRadio}
                    />
                    {hasCancellationFee
                        ? t('bookingModal.cancelConfirmation.outsideCancellationPolicy.options.withoutCancelationFee')
                        : t('bookingModal.cancelConfirmation.outsideCancellationPolicy.options.withoutRefund')}
                </label>
            </React.Fragment>
        );
    })();

    return (
        <React.Fragment>
            <Dialog
                scrollable
                size="lg"
                visible={
                    show
                    && !isDeleteModalVisible
                    && !isPresentModal
                    && !confirmationChangeStatusPaid.visible
                    && !addBookingConfirmModal.visible
                }
                onClose={onHide}
            >
                <DialogHeader closeButton>
                    <DialogTitle className="mb-0 font-weight-600" data-testid="data-test-booking-modal-title">
                        {t('bookingModal.title')}
                    </DialogTitle>
                </DialogHeader>

                <DialogBody>
                    <Column gap={8} stretched>
                        <Row stretched gap={8} wrap={isMobile}>
                            <Row stretched>
                                <Row gap={8}>
                                    {booking && booking.recurrence.enabled && (
                                        <Time width={24} height={24} />
                                    )}
                                    <Label color="gray">{t('bookingModal.bookingTime')}</Label>
                                </Row>
                            </Row>
                            <Row stretched>
                                <Label data-testid="data-test-booking-time">
                                    {booking && moment(booking.time.from).format('HH:mm DD/MM/YYYY')}
                                </Label>
                            </Row>
                        </Row>
                        <Divider horisontal padding={16} />

                        <Row stretched gap={8} wrap={isMobile}>
                            <Row stretched>
                                <Label color="gray">{t('bookingModal.clientName')}</Label>
                            </Row>
                            <Row stretched>
                                <TextButton
                                    color="black"
                                    disabled={!booking?.client?.id}
                                    href={CLIENTS_LIST_ID_PAGE({ clientId: booking.client.id })}
                                    data-testid="data-test-booking-client-name"
                                >
                                    {booking && getFullName({ client: booking.client })}
                                </TextButton>
                            </Row>
                        </Row>

                        <Row stretched gap={8} wrap={isMobile}>
                            <Row stretched>
                                <Label color="gray">{t('bookingModal.contactNumber')}</Label>
                            </Row>
                            <Row stretched>
                                <Label data-testid="data-test-booking-contact-number">
                                    {booking && booking.client.contactNumber}
                                </Label>
                            </Row>
                        </Row>

                        <Row stretched gap={8} wrap={isMobile}>
                            <Row stretched>
                                <Label color="gray">{t('bookingModal.email')}</Label>
                            </Row>

                            <Row stretched>
                                <Label data-testid="data-test-booking-email">
                                    {booking && booking.client.email}
                                </Label>
                            </Row>
                        </Row>

                        <Row stretched gap={8} wrap={isMobile}>
                            <Row stretched>
                                <Label color="gray">{t('bookingModal.note')}</Label>
                            </Row>

                            <Row stretched>
                                <Label data-testid="data-test-booking-note">
                                    {booking.client.note ? booking.client.note : '-'}
                                </Label>
                            </Row>
                        </Row>
                        <Divider horisontal padding={16} />
                        {booking?.product?.name && (
                            <Row stretched gap={8} wrap={isMobile}>
                                <Row stretched>
                                    <Label color="gray">
                                        {t('bookingModal.product')}
                                    </Label>
                                </Row>

                                <Row stretched>
                                    <Label data-testid="data-test-booking-product-name">
                                        {booking.product.name}
                                    </Label>
                                </Row>
                            </Row>
                        )}

                        <Row stretched gap={8} wrap={isMobile}>
                            <Row stretched>
                                <Label color="gray">
                                    {t('bookingModal.package')}
                                </Label>
                            </Row>

                            <Row stretched>
                                <Label data-testid="data-test-booking-package-name">
                                    {booking && booking.service.name}
                                </Label>
                            </Row>
                        </Row>

                        <Row stretched gap={8} wrap={isMobile}>
                            <Row stretched>
                                <Label color="gray">
                                    {t('bookingModal.paymentType')}
                                </Label>
                            </Row>

                            <Row stretched>
                                <Label data-testid="data-test-booking-payment-type">
                                    {i18n.exists(paymentType) ? t(paymentType) : (booking?.payment.type || '-')}
                                </Label>
                            </Row>
                        </Row>

                        <Row stretched gap={8} wrap={isMobile}>
                            <Row stretched>
                                <Label color="gray">
                                    {t('bookingModal.paidUpfront')}
                                </Label>
                            </Row>

                            <Row stretched>
                                <Label data-testid="data-test-booking-paid-upfront">
                                    {formatPrice.toEuroWithComma({ amount: booking?.payment.paidUpfront })}
                                </Label>
                            </Row>
                        </Row>

                        <Row stretched gap={8} wrap={isMobile}>
                            <Row stretched>
                                <Label color="gray">
                                    {t('bookingModal.finalCost')}
                                </Label>
                            </Row>

                            <Row stretched>
                                <Label data-testid="data-test-booking-final-cost">
                                    {formatPrice.toEuroWithComma({ amount: booking?.payment.finalCost })}
                                </Label>
                            </Row>
                        </Row>

                        <Row stretched gap={8} wrap={isMobile}>
                            <Row stretched>
                                <Label color="gray">
                                    {t('bookingModal.remainingCost')}
                                </Label>
                            </Row>

                            <Row stretched>
                                <Label data-testid="data-test-booking-remaining-cost">
                                    {formatPrice.toEuroWithComma({ amount: booking?.payment.remainingCost })}
                                </Label>
                            </Row>
                        </Row>
                        <Divider horisontal padding={16} />
                        <Row stretched gap={8} wrap={isMobile}>
                            <Content loading={isChangingPayBooking}>
                                <TextButton
                                    color={isBookingStatusPaid ? 'green' : 'red'}
                                    disabled={!isCanChange}
                                    onClick={handleChangePaymentStatus}
                                    noPadding
                                    data-testid="data-test-booking-change-pay-status"
                                >
                                    {t(`addBookingModal.${isBookingStatusPaid ? 'paid' : 'unpaid'}`)}
                                </TextButton>
                            </Content>
                        </Row>
                    </Column>
                </DialogBody>

                <DialogFooter>
                    <Button
                        color="outline"
                        onClick={onHide}
                        data-testid="data-test-booking-modal-cancel-button"
                    >
                        {t('bookingModal.cancel')}
                    </Button>
                    {Boolean(booking?.alreadyStarted) && (
                        <Button
                            color="black"
                            onClick={() => setPresentModal(true)}
                            disabled={booking?.notPresent || banSetPresent}
                            data-testid="data-test-booking-modal-not-present-button"
                        >
                            {t('bookingModal.notPresentInShop')}
                        </Button>
                    )}

                    <Button
                        onClick={/* istanbul ignore next */ () => onEditChange(true)}
                        disabled={!isEditable}
                        data-testid="data-test-booking-modal-edit-button"
                    >
                        {t('bookingModal.editBooking')}
                    </Button>

                    <IconButton
                        color="red"
                        size={40}
                        onClick={() => setDeleteModalVisible(true)}
                        data-testid="data-test-booking-modal-delete-button"
                    >
                        <Trash3Icon />
                    </IconButton>
                </DialogFooter>
            </Dialog>

            <ConfirmationModal
                size="lg"
                isShow={isDeleteModalVisible}
                hide={() => setDeleteModalVisible(false)}
                titleText={t('bookingModal.deleteBooking')}
                bodyText={(
                    <React.Fragment>
                        {confirmCancelText}

                        {booking?.hasPast && (
                            <p className="mb-0 text-muted">
                                *
                                {t('bookingModal.hasLastNote')}
                            </p>
                        )}
                    </React.Fragment>
                )}
                customFooter={(
                    <DialogFooter>
                        <Button
                            color="outline"
                            onClick={() => setDeleteModalVisible(false)}
                            data-testid="data-test-booking-modal-delete-cancel-button"
                        >
                            {t('bookingModal.noLeave')}
                        </Button>

                        {booking?.recurrence.enabled && (
                            <Button
                                color="red"
                                className="mx-2"
                                onClick={() => onDeleteHandler()}
                                disabled={generalBanDelete()}
                                loading={isDeletingRecurringBooking}
                                data-testid="data-test-booking-modal-delete-recurring-button"
                            >
                                {t('bookingModal.deleteRecurringBooking')}
                            </Button>
                        )}

                        <Button
                            color="black"
                            className="mx-2"
                            onClick={() => onDeleteHandler(booking.time.from / 1000)}
                            disabled={generalBanDelete(booking.time.from / 1000)}
                            loading={isDeletingBooking}
                            data-testid="data-test-booking-modal-delete-button"
                        >
                            {t(booking?.recurrence.enabled
                                ? 'bookingModal.deleteBookingCurrent'
                                : 'bookingModal.yesCancel')}
                        </Button>
                    </DialogFooter>
                )}
            />

            <ConfirmationModal
                isShow={isPresentModal}
                hide={() => setPresentModal(false)}
                loading={isOmittingBooking}
                confirmAction={onOmitHandler}
                deleteColor="black"
                titleText={t('bookingModal.notPresentInShop')}
                bodyText={t('bookingModal.areYouSureYouWantToOmitBooking')}
                deleteText={t('bookingModal.omit')}
            />

            <ConfirmationModal
                size="xs"
                isShow={confirmationChangeStatusPaid.visible}
                bodyText={t('bookingModal.confirmationChangeStatusPaid.bodyText')}
                hide={confirmationChangeStatusPaid.onClose}
                deleteText={t('bookingModal.confirmationChangeStatusPaid.yes')}
                dismissText={t('bookingModal.confirmationChangeStatusPaid.no')}
                confirmAction={() => onChangePayHandler(isBookingPaid ? 'open' : 'paid')}
            />
            <EditBookingConfirmModal
                isShow={addBookingConfirmModal.visible}
                hide={addBookingConfirmModal.onClose}
                terminals={terminals}
                hasUnpaid={booking?.client?.email}
                confirmAction={handleSelectPayment}
                recurrence={booking?.recurrence.enabled}
            />
        </React.Fragment>
    );
};

export default BookingModal;
