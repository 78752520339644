import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { BILLING_SORT_BY_TYPE } from 'const/billings/BILLINGS_SORT_BY_TYPE';
import * as FINANCIALS_SELECTORS from '../../../../store/selectors/financilas';
import { setBillingsSortOptions } from '../../../../store/actions/financials/billings';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;

const BillingsTableHeader = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.BILLINGS);

    const { sortOptions } = useSelector(FINANCIALS_SELECTORS.billingsListSelector);
    const dispatch = useDispatch();

    const handleCellClick = useCallback((sortBy) => {
        if (sortOptions.sortBy === sortBy) {
            dispatch(setBillingsSortOptions({
                sortOptions: {
                    orderBy: sortOptions.orderBy * -1,
                },
            }));
        } else {
            dispatch(setBillingsSortOptions({
                sortOptions: {
                    sortBy,
                    orderBy: 1,
                },
            }));
        }
    }, [sortOptions]);

    return (
        <React.Fragment>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.number.label`)}
                    active={sortOptions.sortBy === BILLING_SORT_BY_TYPE.NUMBER}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => handleCellClick(BILLING_SORT_BY_TYPE.NUMBER)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_FIELDS}.date.label`)}
                    active={sortOptions.sortBy === BILLING_SORT_BY_TYPE.DATE}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => handleCellClick(BILLING_SORT_BY_TYPE.DATE)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.totalAmount.label`)}
                    active={sortOptions.sortBy === BILLING_SORT_BY_TYPE.TOTAL_AMOUNT}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => handleCellClick(BILLING_SORT_BY_TYPE.TOTAL_AMOUNT)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.actions.label`)}
                />
            </TableHeaderCell>
        </React.Fragment>
    );
};

BillingsTableHeader.propTypes = {};

BillingsTableHeader.defaultProps = {};

export default BillingsTableHeader;
