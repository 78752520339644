import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import moment from 'moment';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import TextButton from 'components/Common/TextButton/TextButton';
import { IconButton } from 'components/Common/IconButton';
import { Row } from 'components/Common/Row';
import {
    ArrowSmallUpIcon,
    ArrowSmallDownIcon,
    ChangeIcon, Close, MoreHorizontalIcon, PauseIcon, PlayIcon, SentIcon, Trash3Icon,
    LockIcon,
    UnlockIcon,
    InfoIcon,
} from 'components/Icon/Icon';
import { ActionSheet } from 'components/Common/ActionSheet';
import { CLIENTS_LIST_ID_SUBSCRIPTIONS_PAGE } from 'const/CLIENT_URL';
import { CHAR_SYMBOL } from 'const/string/CHAR_SYMBOL';
import { PAYMENT_TYPE_ICONS } from 'const/payments/PAYMENT_TYPE_ICONS';
import { Badge } from 'components/Common/Badge';
import { Label } from 'components/Common/Typography/Label';
import { Column } from 'components/Common/Column';
import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { Tooltip } from '../../Common/Tooltip';

import { SOLD_SUBSCRIPTION_STATUS } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_STATUS';
import { SOLD_SUBSCRIPTION_PAYMENT_STATUS } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PAYMENT_STATUS';
import { SOLD_SUBSCRIPTION_PAYMENT_TYPE } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PAYMENT_TYPE';
import { SOLD_SUBSCRIPTION_PROP } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PROP';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './SoldSubscriptionInfoTableCells.module.scss';

const T_PREFIX = 'sold.table.body.row.info';

export const PAYMENT_STATUS_T_KEY_MAP = {
    paid: 'paid',
    open: 'unpaid',
    closed: 'closed',
    paused: 'paused',
    pending: 'pending',
};

export const PAYMENT_COLOR_KEY_MAP = {
    paid: 'green',
    open: 'red',
    closed: 'outline',
    paused: 'gray',
    pending: 'blue',
};

const SoldSubscriptionInfoTableCells = (props) => {
    const {
        isClientPage,
        showPayments,
        soldSubscription,
        onShow,
        onEdit,
        onPause,
        onResume,
        onCancel,
        onResend,
        onDelete,
        onToggleUserLock,
        onToggleShowPayments,
    } = props;

    const {
        id: soldSubscriptionId,
        clientId,
        clientName,
        clientLastName,
        clientIsBlocked,
        clientAge,
        subscriptionId,
        subscriptionTitle,
        contractStartDate,
        contractEndDate,
        nextDebitDate,
        price,
        paymentType,
        type,
        payments,
        pause,
        showResend,
        unpaidCounter,
        paymentsStatusInfo,
        subscriptionStatus,
        updatingAt,
        updatingSubscription,
    } = soldSubscription || {};
    const { locale } = useSelector((state) => state.locales);
    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const { t: tc } = useTranslation();
    const age = clientAge ? `${clientAge}${CHAR_SYMBOL.UNBREAKABLE_SPACE}${tc('time.years')}` : '-';

    const curMonth = new Date().getMonth();
    const monthLastDay = moment().endOf('month').startOf('day');

    const isStarted = moment().isAfter(moment.unix(contractStartDate));
    const isOpenStatus = subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.OPEN;
    const isPaidStatus = subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.PAID;
    const isCloseStatus = subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.CLOSED;
    const isPendingStatus = subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.OPEN
        && payments[payments.length - 1].paymentStatusTitle === SOLD_SUBSCRIPTION_PAYMENT_STATUS.PENDING;
    const hasEndDate = Boolean(contractEndDate);
    const canDelete = !isClientPage && !isCloseStatus && !hasEndDate;

    const isPaused = useMemo(() => !isCloseStatus && (
        pause?.status || (isOpenStatus && payments?.at(-1)?.paymentPaused)
    ), []);
    const isPausedFromNextMonth = pause?.from && (moment.unix(pause.from).month() === curMonth + 1);
    const isAutomaticPayment = paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.AUTOMATIC || paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.IBAN;
    const isSubscriptionManuallySet = paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.MANUAL;
    const isAutoDebitOrderReceived = monthLastDay.diff(moment().startOf('day'), 'days') <= SOLD_SUBSCRIPTION_PROP.AUTO_DEBIT.DAYS_PERIOD;

    const updatingAtData = updatingAt ? moment.unix(updatingAt).locale(locale).format('DD.MM.YYYY') : '';

    const showPauseResume = useMemo(() => {
        if (!pause?.from && nextDebitDate) {
            return true;
        }
        if (pause?.from) {
            return pause?.unlimited;
        }
        return false;
    }, [pause?.from, nextDebitDate, pause?.unlimited]);

    const pauseStatus = useMemo(() => {
        if (isCloseStatus || !pause || !pause.from) {
            return '';
        }

        const startDate = moment.unix(pause.from).locale(locale).format('DD.MM.YYYY');
        const endDate = pause.unlimited
            ? t(`${T_PREFIX}.status.infinite`)
            : moment.unix(pause.to).locale(locale).format('DD.MM.YYYY');
        const status = pause.status ? 'pausedFrom' : 'willBePaused';
        return t(`${T_PREFIX}.status.statuses.${status}`, {
            startDate,
            endDate,
        });
    }, [
        isCloseStatus,
        pause?.status,
        pause?.from,
        pause?.to,
        pause?.unlimited,
        t,
    ]);

    const statusKey = useMemo(() => {
        if (!subscriptionStatus) {
            return '';
        }
        let key = subscriptionStatus;
        if (isPaused) {
            key = PAYMENT_STATUS_T_KEY_MAP.paid;
        } else if (isPendingStatus) {
            key = PAYMENT_STATUS_T_KEY_MAP.pending;
        }
        return key;
    }, [
        isPendingStatus,
        subscriptionStatus,
        isPaused,
    ]);

    const paymentStatus = useMemo(() => {
        const status = t(`${T_PREFIX}.status.statuses.${PAYMENT_STATUS_T_KEY_MAP[statusKey]}`);
        return (unpaidCounter && isOpenStatus && !isPaused)
            ? `${status} (${unpaidCounter})`
            : status;
    }, [
        t,
        statusKey,
        unpaidCounter,
        isOpenStatus,
        isPaused,
    ]);

    const handleShow = useCallback(() => {
        if (!soldSubscriptionId || !contractStartDate) {
            return;
        }
        onShow({ soldSubscriptionId, contractStartDate });
    }, [
        soldSubscriptionId,
        contractStartDate,
        onShow,
    ]);

    const handleResend = useCallback(() => {
        if (!soldSubscriptionId) {
            return;
        }
        onResend({ soldSubscriptionId });
    }, [
        soldSubscriptionId,
        onResend,
    ]);

    const handleEdit = useCallback(() => {
        const editableSubId = updatingSubscription?.id || subscriptionId;
        if (!soldSubscriptionId || !editableSubId) {
            return;
        }

        onEdit({
            soldSubId: soldSubscriptionId,
            editableSubId,
            curSubId: subscriptionId,
        });
    }, [
        soldSubscriptionId,
        updatingSubscription?.id,
        subscriptionId,
        onEdit,
    ]);

    const handleCancel = useCallback(() => {
        if (!soldSubscriptionId || !contractStartDate || !subscriptionStatus || !paymentType) {
            return;
        }
        onCancel({
            soldSubscriptionId, contractStartDate, subscriptionStatus, nextDebitDate, paymentType, payments, price, updatingAt, updatingSubscription,
        });
    }, [
        soldSubscriptionId,
        contractStartDate,
        updatingAt,
        updatingSubscription,
        nextDebitDate,
        subscriptionStatus,
        paymentType,
        onCancel,
        payments,
    ]);

    const handleToggleUserLock = useCallback(() => {
        if (!clientId) {
            return;
        }
        onToggleUserLock({ clientId });
    }, [clientId, onToggleUserLock]);

    const handlePauseResume = useCallback(() => {
        if (!showPauseResume || !soldSubscriptionId) {
            return null;
        }
        if (pause?.from) {
            return onResume({ soldSubscription });
        }
        return onPause({ soldSubscription });
    }, [
        showPauseResume,
        soldSubscriptionId,
        pause?.from,
        onResume,
        onPause,
        soldSubscription,
    ]);

    const handleDelete = useCallback(() => {
        if (!soldSubscriptionId) {
            return;
        }
        onDelete({ soldSubscriptionId });
    }, [
        soldSubscriptionId,
        onDelete,
    ]);

    const paymentInfoLabel = paymentsStatusInfo
        ? t(`${T_PREFIX}.status.paymentInfo.${paymentsStatusInfo}`)
        : null;

    const paymentTypeLabel = t(`${T_PREFIX}.status.paymentType.${paymentType}`);

    const tooltips = {
        btnDelete: '',
        btnEdit: '',
        btnCancel: '',
    };

    const isDisabledPause = payments[0].paymentStatus === false;

    const isAllUnpaid = payments.every((payment) => payment.paymentStatus === false);

    if (!isSubscriptionManuallySet && ((isOpenStatus && isStarted) || isPaidStatus) && !(isAllUnpaid && isOpenStatus)) {
        tooltips.btnDelete = t(`${T_PREFIX}.tooltips.noDelete`);
    }

    if (isPaused) {
        tooltips.btnEdit = t(`${T_PREFIX}.tooltips.noEdit.paused`);
    } else if (isPausedFromNextMonth) {
        tooltips.btnEdit = t(`${T_PREFIX}.tooltips.noEdit.pausedNextMonth`);
    }

    if (isAutomaticPayment) {
        if (isAutoDebitOrderReceived && isOpenStatus) {
            tooltips.btnEdit = t(`${T_PREFIX}.tooltips.noEdit.autoDebitReceived`);
        }
    }

    if (isPendingStatus) {
        tooltips.btnCancel = tc('subscriptions.cancelSoldSubscriptionModals.showFirstPaymentNotPaid');
    }

    const contractDates = useMemo(() => {
        const contractStartDateString = contractStartDate ? moment.unix(contractStartDate).locale(locale).format('DD.MM.YYYY') : '';
        const contractEndDateString = contractEndDate ? moment.unix(contractEndDate).locale(locale).format('DD.MM.YYYY') : '';

        if (!contractStartDateString && !contractEndDateString) {
            return '-';
        }

        return [contractStartDateString, contractEndDateString].filter(Boolean).join(' - ');
    }, [contractStartDate, contractEndDate, locale]);

    return (
        <React.Fragment>
            <ListTableBodyCell noPadding align="center">
                <IconButton
                    type="button"
                    color="transparent"
                    onClick={onToggleShowPayments}
                    size={32}
                >
                    {showPayments ? (
                        <ArrowSmallUpIcon />
                    ) : (
                        <ArrowSmallDownIcon />
                    )}
                </IconButton>
            </ListTableBodyCell>
            {!isClientPage && (
                <ListTableBodyCell className={styles.clientCell}>
                    <Row gap={4}>
                        <IconButton
                            type="button"
                            color={clientIsBlocked ? 'outlineRed' : 'transparent'}
                            onClick={handleToggleUserLock}
                            size={24}
                        >
                            {clientIsBlocked ? (
                                <LockIcon width={16} />
                            ) : (
                                <UnlockIcon width={16} />
                            )}
                        </IconButton>
                        <TextButton
                            noPadding
                            color="black"
                            href={CLIENTS_LIST_ID_SUBSCRIPTIONS_PAGE({ clientId })}
                            newTab
                        >
                            {`${clientName} ${clientLastName}`}
                        </TextButton>
                    </Row>
                </ListTableBodyCell>
            )}

            {!isClientPage && (
                <ListTableBodyCell>
                    {age}
                </ListTableBodyCell>
            )}
            <ListTableBodyCell className={styles.titleCell}>
                <span className={styles.titleStatus}>
                    {subscriptionTitle}
                </span>
                {Boolean(updatingAt && updatingSubscription) && (
                    <span className={styles.pauseStatus}>
                        {`${t(`${T_PREFIX}.changeSubscription.from`)} ${updatingAtData} ${updatingSubscription?.name}`}
                    </span>
                )}
            </ListTableBodyCell>
            <ListTableBodyCell>
                {contractDates}
            </ListTableBodyCell>
            <ListTableBodyCell>
                {Boolean(nextDebitDate) && moment.unix(nextDebitDate).locale(locale).format('DD MMM YYYY')}
            </ListTableBodyCell>
            <ListTableBodyCell align="right">
                {price}
            </ListTableBodyCell>
            <ListTableBodyCell>
                <Row gap={8}>
                    {type && PAYMENT_TYPE_ICONS[type.toLowerCase()]}
                    <span data-testid="data-test-sold-subscription-payment-type">
                        {type || '-'}
                    </span>
                </Row>
            </ListTableBodyCell>
            <ListTableBodyCell>
                <Badge size="small" color={PAYMENT_COLOR_KEY_MAP[statusKey]}>
                    {paymentStatus}
                </Badge>
            </ListTableBodyCell>
            <ListTableBodyCell>
                <Column gap={8}>
                    <Label capitalize color="black" weight="light" noWrap>
                        {(paymentTypeLabel) + (paymentInfoLabel ? ` (${paymentInfoLabel})` : '') }
                    </Label>
                    {!!pauseStatus && (
                        <Label capitalize color="gray" weight="light" noWrap>
                            {pauseStatus}
                        </Label>
                    )}
                </Column>
            </ListTableBodyCell>
            <ListTableBodyCell align="right">
                <ActionSheet
                    content={(
                        <React.Fragment>
                            {showResend && (
                                <ActionSheet.Item
                                    onClick={handleResend}
                                    before={<SentIcon />}
                                >
                                    {t(`${T_PREFIX}.actions.resend`)}
                                </ActionSheet.Item>
                            )}
                            {!contractEndDate && (
                                <ActionSheet.Item
                                    onClick={handleCancel}
                                    before={<Close />}
                                    disabled={Boolean(tooltips.btnCancel)}
                                    tooltip={tooltips.btnCancel}
                                >
                                    {t(`${T_PREFIX}.actions.cancel`)}
                                </ActionSheet.Item>
                            )}
                            {(subscriptionStatus !== PAYMENT_STATUS_T_KEY_MAP.closed) && nextDebitDate && (
                                <ActionSheet.Item
                                    onClick={handleEdit}
                                    before={<ChangeIcon />}
                                    disabled={Boolean(tooltips.btnEdit)}
                                    tooltip={tooltips.btnEdit}
                                >
                                    {t(`${T_PREFIX}.actions.edit`)}
                                </ActionSheet.Item>
                            )}
                            <ActionSheet.Item
                                onClick={handleShow}
                                before={<InfoIcon />}
                            >
                                {t(`${T_PREFIX}.actions.info`)}
                            </ActionSheet.Item>
                            {showPauseResume && (
                                <ActionSheet.Item
                                    onClick={handlePauseResume}
                                    before={pause?.from ? <PlayIcon /> : <PauseIcon />}
                                    disabled={isDisabledPause}
                                    tooltip={isDisabledPause && t(`${T_PREFIX}.tooltips.noPause`)}
                                >
                                    {pause?.from ? t(`${T_PREFIX}.actions.resume`) : t(`${T_PREFIX}.actions.pause`)}
                                </ActionSheet.Item>
                            )}
                            {canDelete && (
                                <ActionSheet.Item
                                    onClick={handleDelete}
                                    before={<Trash3Icon />}
                                    disabled={Boolean(tooltips.btnDelete)}
                                    tooltip={tooltips.btnDelete}
                                    color="outlineRed"
                                >
                                    {t(`${T_PREFIX}.actions.delete`)}
                                </ActionSheet.Item>
                            )}
                        </React.Fragment>
                    )}
                >
                    {({ onClick, ref }) => (
                        <Tooltip
                            tooltip={tc('subscriptions.tableHeaders.actions')}
                            placement="top"
                            forButton
                        >
                            <IconButton
                                type="button"
                                color="gray"
                                onClick={onClick}
                                ref={ref}
                            >
                                <MoreHorizontalIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </ActionSheet>
            </ListTableBodyCell>
        </React.Fragment>
    );
};

SoldSubscriptionInfoTableCells.propTypes = {
    showPayments: PropTypes.bool,
    soldSubscription: PropTypes.object,
    onShow: PropTypes.func,
    onPause: PropTypes.func,
    onResume: PropTypes.func,
    onCancel: PropTypes.func,
    onResend: PropTypes.func,
    onDelete: PropTypes.func,
    onToggleUserLock: PropTypes.func,
    onToggleShowPayments: PropTypes.func,
    onOpenClient: PropTypes.func,
    isClientPage: PropTypes.bool,
    onToggleActive: PropTypes.func,

};

SoldSubscriptionInfoTableCells.defaultProps = {
    showPayments: false,
    soldSubscription: null,
    isClientPage: false,
    onShow: emptyFunc,
    onPause: emptyFunc,
    onResume: emptyFunc,
    onCancel: emptyFunc,
    onResend: emptyFunc,
    onDelete: emptyFunc,
    onToggleUserLock: emptyFunc,
    onToggleShowPayments: emptyFunc,
    onOpenClient: emptyFunc,
    onToggleActive: emptyFunc,
};

export default SoldSubscriptionInfoTableCells;
