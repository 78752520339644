import React, { useEffect, useCallback, useMemo } from 'react';
import {
    Redirect,
    Route,
    Switch,
    useParams,
    useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NavigationService from 'services/navigationService';

import { SellSubscriptionModal, SubNavigation } from 'components';

import { Loader } from 'components/Common/Loader';
import { ClientPageHeader } from 'components/clients/ClientPage/ClientPageHeader';
import { ClientDataBlock } from 'components/clients/ClientPage/ClientDataBlock';
import { EditClientDialog } from 'components/clients/EditClientDialog';

import { ProfileNula } from 'components/clients/ClientPage/ProfileNula';
import { THIRD_LEVEL_NAV_HEIGHT } from 'const/navigation/DESKTOP_NAV';
import { useMobile } from 'hooks/useMobile';
import { useDialog } from 'hooks/useDialog';

import { CLIENTS_LIST_PAGE } from 'const/CLIENT_URL';

import * as CLIENTS_ACTIONS from 'store/actions/clients';

import * as CLIENTS_SELECTORS from 'store/selectors/clients';
import * as COMPANY_SELECTORS from 'store/selectors/company';

import { Column } from 'components/Common/Column';
import Container from 'components/Layout/Container/Container';
import SellBundleModal from 'components/bundles/SellBundleModal';
import { Row } from 'components/Common/Row';
import { useSubRoute } from 'hooks/useSubRoute';
import { ProfileBlockedBanner } from 'components/clients/ClientPage/ClientDataBlock/ProfileBlockedBanner';
import routes from './scenes';

import * as styles from './page.module.scss';

const subNavigationVisibleRoutes = [
    'notes',
    'bookings',
    'bundles',
    'subscriptions',
    'invoices',
    'reviews',
    'communication',
    'info',
    'access',
    'activity',
    'emails',
];

const ClientPage = () => {
    const { clientId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const isMobile = useMobile();

    const editClientDialog = useDialog(false);
    const showSellBundleModal = useDialog(false);
    const showSellSubscriptionModal = useDialog(false);

    const isRequiredClientAddress = useSelector(COMPANY_SELECTORS.companyIsRequiredClientAddressSelector);
    const { item: client, loading } = useSelector(CLIENTS_SELECTORS.clientItemSelector);
    const { enableBusinessMode } = useSelector(COMPANY_SELECTORS.companyBuisnessModeSelector);
    const { sections } = useSelector((state) => state.company);
    const subNavigation = NavigationService.getClientsSubNavigation({
        clientId, isMobile, sections,
    });
    const [currentSubRoute] = useSubRoute();

    const handleBack = useCallback(() => {
        history.push(CLIENTS_LIST_PAGE);
    }, [history.push]);

    const handleEdit = useCallback(() => {
        editClientDialog.onShow();
    }, [editClientDialog.onShow]);

    const handleSellSubscription = useCallback(() => {
        showSellSubscriptionModal.onShow();
    }, [clientId]);

    const handleSellBundle = useCallback(() => {
        showSellBundleModal.onShow();
    }, [clientId]);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.getClientItem({ clientId }));
    }, [dispatch, clientId]);

    const filteredRoutes = useMemo(() => (routes.filter(({
        mobileOnly, section,
    }) => {
        const isShownByPlatform = !mobileOnly || mobileOnly && isMobile;
        const isShownBySections = !section || sections[section];
        return isShownByPlatform && isShownBySections;
    })), [isMobile]);

    if (loading) {
        return (
            <Loader />
        );
    }

    return (
        <React.Fragment>
            <ClientPageHeader
                client={client}
                onEdit={handleEdit}
                onBack={handleBack}
                onSellSubscription={handleSellSubscription}
                onSellBundle={handleSellBundle}
            />
            <Container>
                <Column gap={24} stretched>
                    {Boolean(client?.block) && (
                        <ProfileBlockedBanner client={client} />
                    )}
                    <Column gap={32} stretched>
                        {!isMobile && (
                            <Column gap={16} stretched>
                                <ClientDataBlock client={client} />
                                {enableBusinessMode && <ProfileNula client={client} />}
                            </Column>
                        )}
                        {
                            subNavigationVisibleRoutes.includes(currentSubRoute) && (
                                <div className={styles.withBorderBottom}>
                                    <Row
                                        spacing={isMobile ? 12 : 32}
                                        gap={32}
                                        style={{ height: THIRD_LEVEL_NAV_HEIGHT }}
                                    >
                                        <SubNavigation
                                            isDataLoaded
                                            subRoutes={subNavigation}
                                            withQuery={false}
                                        />
                                    </Row>
                                </div>
                            )
                        }
                    </Column>
                    <Switch>
                        {
                            filteredRoutes.map(({ component, exact, path }) => (
                                <Route
                                    key={path}
                                    component={component}
                                    exact={exact}
                                    path={path}
                                />
                            ))
                        }
                        <Redirect to={filteredRoutes[0].path} />
                    </Switch>
                </Column>
                {editClientDialog.visible && (
                    <EditClientDialog
                        client={client}
                        visible={editClientDialog.visible}
                        onClose={editClientDialog.onClose}
                        isRequiredClientAddress={isRequiredClientAddress}
                    />
                )}
                {showSellBundleModal.visible && (
                    <SellBundleModal
                        onClose={showSellBundleModal.onClose}
                        defaultClient={client}
                    />
                )}
                {
                    showSellSubscriptionModal.visible && (
                        <SellSubscriptionModal
                            onClose={showSellSubscriptionModal.onClose}
                            defaultClient={client}
                        />
                    )
                }
            </Container>
        </React.Fragment>
    );
};

ClientPage.propTypes = {};

ClientPage.defaultProps = {};

export default ClientPage;
