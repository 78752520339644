import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import { Column } from 'components/Common/Column';
import { Label } from 'components/Common/Typography/Label';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import * as styles from './NoteFormRow.module.scss';

const T_PREFIX = 'sell.form';
const T_FIELDS = `${T_PREFIX}.fields`;

const NOTE_MAX_LENGTH = 200;

const NoteFormRow = (props) => {
    const {
        values,
        validation,
        onChangeNote,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    return (
        <Column stretched gap={8}>
            <Label htmlFor="note" tooltip={t(`${T_FIELDS}.note.tooltip`)}>
                {t(`${T_FIELDS}.note.label`)}
            </Label>
            <Form.Control
                as="textarea"
                value={values.note}
                name="note"
                className={styles.note}
                onChange={onChangeNote}
                maxLength={NOTE_MAX_LENGTH}
            />
            <Form.Control.Feedback
                type="invalid"
                className={classNames({
                    'd-block': validation?.note?.isInvalid,
                })}
            >
                {validation?.note?.error}
            </Form.Control.Feedback>
        </Column>
    );
};

NoteFormRow.propTypes = {
    values: PropTypes.object.isRequired,
    validation: PropTypes.object,
    onChangeNote: PropTypes.func,
};

NoteFormRow.defaultProps = {
    validation: null,
    onChangeNote: emptyFunc,
};

export default NoteFormRow;
