import React, {
    useState, useEffect, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

// Common Components
import { Tooltip } from 'components/Common/Tooltip';
import { Button } from 'components/Common/Button';
import { CheckBox } from 'components/Common/CheckBox';
import { SubHeader } from 'components/Common/Typography/SubHeader';
import { Column } from 'components/Common/Column';
import { Dialog } from 'components/Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from 'components/Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from 'components/Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from 'components/Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from 'components/Common/Dialogs/Dialog/DialogFooter';
import { Label } from 'components/Common/Typography/Label';

const CommonConfirmModal = ({
    isShow,
    onClose,
    onConfirm,
    options,
    title,
    subHeader,
    loading,
    checkboxConfig,
    initialSelectedOption,
    backText,
    confirmText,
    confirmDisabledTooltip,
}) => {
    const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

    // Set a default selection if none provided
    useEffect(() => {
        if (!selectedOption && options.length) {
            const defaultOption = options.find((opt) => !opt.disabled);
            setSelectedOption(defaultOption);
        }
    }, [options, selectedOption]);

    const handleOptionChange = (option) => {
        if (option.disabled) return;
        setSelectedOption(option);
    };

    const handleTerminalChange = (option, terminal) => {
        if (terminal.disabled) return;
        setSelectedOption({ ...option, terminal });
    };

    return (
        <Dialog size="md" visible={isShow} onClose={onClose}>
            <DialogHeader>
                <DialogTitle>{title}</DialogTitle>
            </DialogHeader>
            <DialogBody>
                <Column gap={16} stretched>
                    {subHeader && <SubHeader>{subHeader}</SubHeader>}
                    <Column gap={12} stretched>
                        {options.map((option, index) => (
                            <Fragment key={`${option.name}-${index}`}>
                                <Form.Check
                                    type="radio"
                                    id={`${option.name}-${index}`}
                                    label={(
                                        <Tooltip
                                            tooltip={option.disabledTooltip}
                                            placement="right-end"
                                            isDisabled={!option.disabled}
                                            forButton
                                        >
                                            <Label
                                                pointer
                                                htmlFor={`${option.name}-${index}`}
                                                tooltip={!option.disabled && option.tooltip}
                                                disabled={option.disabled}
                                            >
                                                {option.label}
                                            </Label>
                                        </Tooltip>
                                    )}
                                    name={option.name}
                                    checked={
                                        selectedOption?.name === option.name
                                        && selectedOption?.paid === option.paid
                                    }
                                    onChange={() => (option.terminals ? handleTerminalChange(option, option.terminals[0]) : handleOptionChange(option))}
                                    disabled={option.disabled}
                                />
                                {option.terminals
                                && (
                                    <Column gap={12} stretched padding={32}>
                                        {option.terminals.map((terminal, terminalIndex) => (
                                            <Form.Check
                                                type="radio"
                                                id={`${terminal.id}-${terminalIndex}`}
                                                label={(
                                                    <Label
                                                        pointer
                                                        htmlFor={`${terminal.id}-${terminalIndex}`}
                                                    >
                                                        {terminal.name}
                                                    </Label>
                                                )}
                                                name={terminal.name}
                                                checked={selectedOption?.terminal?.id === terminal.id}
                                                onChange={() => handleTerminalChange(option, terminal)}
                                                disabled={terminal.disabled}
                                            />
                                        ))}
                                    </Column>
                                )}
                            </Fragment>
                        ))}
                        {checkboxConfig && checkboxConfig.visible && (
                            <CheckBox
                                label={checkboxConfig.label}
                                checked={checkboxConfig.checked}
                                onChange={checkboxConfig.onChange}
                                disabled={checkboxConfig.disabled || !selectedOption}
                            />
                        )}
                    </Column>
                </Column>
            </DialogBody>
            <DialogFooter>
                <Button color="outline" onClick={onClose}>
                    {backText}
                </Button>
                <Tooltip
                    tooltip={confirmDisabledTooltip}
                    placement="top"
                    isDisabled={!!selectedOption}
                    forButton
                >
                    <Button
                        color="yellow"
                        onClick={() => onConfirm(selectedOption)}
                        disabled={loading || !selectedOption}
                        loading={loading}
                    >
                        {confirmText}
                    </Button>
                </Tooltip>
            </DialogFooter>
        </Dialog>
    );
};

CommonConfirmModal.propTypes = {
    isShow: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            tooltip: PropTypes.string,
            disabledTooltip: PropTypes.string,
            disabled: PropTypes.bool,
            paid: PropTypes.bool,
            terminals: PropTypes.shape({
                name: PropTypes.string,
                id: PropTypes.number,
            }),
        }),
    ),
    title: PropTypes.string.isRequired,
    subHeader: PropTypes.string,
    loading: PropTypes.bool,
    checkboxConfig: PropTypes.shape({
        visible: PropTypes.bool,
        label: PropTypes.string,
        checked: PropTypes.bool,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
    }),
    initialSelectedOption: PropTypes.shape({
        name: PropTypes.string,
        paid: PropTypes.bool,
    }),
    backText: PropTypes.string,
    confirmText: PropTypes.string,
    confirmDisabledTooltip: PropTypes.string,
};

CommonConfirmModal.defaultProps = {
    isShow: false,
    options: [],
    subHeader: '',
    loading: false,
    checkboxConfig: null,
    initialSelectedOption: null,
    backText: 'Back',
    confirmText: 'Confirm',
    confirmDisabledTooltip: 'Please select an option to continue.',
};

export default CommonConfirmModal;
