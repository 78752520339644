import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as CLIENTS_ACTIONS from 'store/actions/clients';

import { Row } from 'components/Common/Row';
import { Panel } from 'components/Common/Panel';
import { LockIcon } from 'components/Icon/Icon';
import { Button } from 'components/Common/Button';
import { Label } from 'components/Common/Typography/Label';
import PropTypes from 'prop-types';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { useTranslation } from 'react-i18next';

const ProfileBlockedBanner = ({ client }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const handleToggleLock = useCallback((e) => {
        e.stopPropagation();
        if (!client.id) {
            return null;
        }
        dispatch(CLIENTS_ACTIONS.toggleClientsItemBlock({ clientId: client.id }));
    }, [client.id, dispatch]);

    return (
        <Panel stretched color="red" borderRadius={4} padding={16}>
            <Row stretched gap={16} justify="between">
                <Label color="red">
                    <Row gap={10} align="center">
                        <LockIcon />
                        {t('profile.blockedBanner.text')}
                    </Row>
                </Label>
                <Button color="white" onClick={handleToggleLock} size="small">
                    {t('profile.blockedBanner.actions.unblock.label')}
                </Button>
            </Row>
        </Panel>
    );
};

ProfileBlockedBanner.propTypes = {
    client: PropTypes.object.isRequired,
};

export default ProfileBlockedBanner;
