import {
    call,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { get } from 'lodash';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import { resolvePromiseAction } from '@adobe/redux-saga-promise';
import * as BUNDLES_TYPES from '../../actions/bundles/actionTypes';
import * as BUNDLES_ACTIONS from '../../actions/bundles';
import * as TOAST_ACTIONS from '../../actions/toast';

import * as BUNDLES_SELECTORS from '../../selectors/bundles';

import axios from '../../../services/axios';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import {
    API_ADMIN_BUNDLE_PAYMENT_TOGGLE_STATUS_ID_ROUTE,
    API_ADMIN_BUNDLE_SOLD_ARCHIVE_ID_ROUTE,
    API_ADMIN_BUNDLE_SOLD_EDIT_ID_ROUTE,
    API_ADMIN_BUNDLE_SOLD_ROUTE,
} from '../../../const/API_URL';

const mapSoldBundle = (item) => {
    const soldBundle = camelize(item);
    return {
        id: soldBundle.id,
        paymentType: soldBundle.paymentInfo,
        purchasedAt: soldBundle.purchasedAt,
        endsAt: soldBundle.endsAt,
        price: soldBundle.finalCost,
        profit: soldBundle.profit,
        client: {
            id: soldBundle.clientId,
            name: soldBundle.clientName,
            lastName: soldBundle.clientLastName,
            email: soldBundle.clientEmail,
        },
        bundle: {
            name: soldBundle.bundleName,
            services: soldBundle.services.map((s) => ({
                id: s.id,
                name: s.name,
            })),
        },
        bookings: {
            value: soldBundle.bookingsInfo.value,
            max: soldBundle.bookingsInfo.max,
            unlimited: soldBundle.bookingsInfo.max === null,
        },
        isEnded: soldBundle.isEnded,
        deleted: soldBundle.deleted,
        isManuallySet: soldBundle.isManuallySet,
        paid: soldBundle.paid,
    };
};

function* getSoldBundles(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { search, page, hideArchived } = yield select(BUNDLES_SELECTORS.soldBundlesSelector);

    try {
        yield put(BUNDLES_ACTIONS.setSoldBundlesLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: 'GET',
            url: API_ADMIN_BUNDLE_SOLD_ROUTE,
            headers: defaultHeaders,
            params: {
                search,
                page,
                hideInactive: Number(hideArchived),
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === 200) {
            const { items, itemsCount, pagesCount } = result;
            const soldBundles = items.map(mapSoldBundle);
            yield put(BUNDLES_ACTIONS.setSoldBundles({ soldBundles, itemsCount, pagesCount }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'soldBundles.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(BUNDLES_ACTIONS.setSoldBundlesLoading({ loading: false }));
    }
}

function* editSoldBundlesItem(action) {
    const { payload } = action;
    const { soldBundleId, soldBundle } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_SOLD_EDIT_ID_ROUTE({ bundleId: soldBundleId }),
            headers: defaultHeaders,
            data: soldBundle,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'soldBundles.edit.success',
                appearance: 'success',
            }));
            yield put(BUNDLES_ACTIONS.getSoldBundles());
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'soldBundles.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* archiveSoldBundlesItem(action) {
    const { payload } = action;
    const { soldBundleId } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_SOLD_ARCHIVE_ID_ROUTE({ bundleId: soldBundleId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'soldBundles.archive.success',
                appearance: 'success',
            }));
            yield put(BUNDLES_ACTIONS.getSoldBundles());
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'soldBundles.archive.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export function* toggleSoldBundlePaymentStatus(action) {
    const { payload } = action;
    const { soldBundleId, terminalId } = payload;
    const defaultHeaders = yield getDefaultHeaders();

    const data = new FormData();
    if (terminalId) {
        data.append('terminalId', terminalId);
    }

    try {
        const request = () => axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_PAYMENT_TOGGLE_STATUS_ID_ROUTE({ bundleId: soldBundleId }),
            headers: defaultHeaders,
            data,
        });

        const {
            data: {
                result: {
                    payment_status: paymentStatus,
                    is_manually_set: isManuallySet,
                },
            },
        } = yield call(request);

        yield put(
            BUNDLES_ACTIONS.setSoldBundlePaymentStatus({
                soldBundleId,
                paymentStatus,
                isManuallySet,
            }),
        );
    } catch (err) {
        yield put(TOAST_ACTIONS.showToast({
            message: get(err, 'response.data.result.message', ''),
            appearance: 'error',
            isCustom: true,
        }));
    }
}

export function* getSoldBundlesOptions(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { search, hideArchived } = yield select(BUNDLES_SELECTORS.soldBundlesSelector);

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'GET',
            url: API_ADMIN_BUNDLE_SOLD_ROUTE,
            headers: defaultHeaders,
            params: {
                search,
                hideInactive: Number(hideArchived),
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === 200) {
            const { items } = result;
            const soldBundles = items.map(mapSoldBundle);
            yield call(resolvePromiseAction, action, soldBundles);
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'soldBundles.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export const soldBundlesSaga = [
    takeEvery(BUNDLES_ACTIONS.getSoldBundlesOptions, getSoldBundlesOptions),
    takeLatest(BUNDLES_TYPES.SOLD_BUNDLES_GET, getSoldBundles),
    takeEvery(BUNDLES_TYPES.SOLD_BUNDLES_ITEM_EDIT, editSoldBundlesItem),
    takeEvery(BUNDLES_TYPES.SOLD_BUNDLES_ITEM_ARCHIVE, archiveSoldBundlesItem),
    takeEvery(BUNDLES_TYPES.SOLD_BUNDLES_PAYMENT_STATUS_TOGGLE, toggleSoldBundlePaymentStatus),
];
