/* eslint-disable import/prefer-default-export */
import * as CLIENTS_TYPES from '../../actions/clients/types';

import { SORT_BY } from '../../../const/clients/SORT_BY';
import { SORT_ORDER } from '../../../const/clients/SORT_ORDER';

const INITIAL_STATE = {
    page: 1,
    items: [],
    search: '',
    sortBy: SORT_BY.ID,
    sortType: SORT_ORDER.DESC,
    discountFilter: [],
    shopFilter: null,
    statusFilter: null,
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
    total: {
        registred: 0,
        notRegistred: 0,
    },
};

export const clientsListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (CLIENTS_TYPES.CLIENTS_SET): {
        const {
            clients, itemsCount, pagesCount, total,
        } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items: clients,
            page,
            itemsCount,
            pagesCount,
            total: total || INITIAL_STATE.total,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_SEARCH_SET): {
        const { search = '' } = payload;
        return {
            ...state,
            search: search.trim(),
            page: state.page && 1,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_FILTRATION_DISCOUNT_SET): {
        const { discountItems } = payload || {};
        return {
            ...state,
            discountFilter: discountItems || INITIAL_STATE.discountFilter,
            page: state.page && 1,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_FILTRATION_SHOP): {
        const { shopId } = payload;
        return {
            ...state,
            shopFilter: shopId,
            page: state.page && 1,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_FILTRATION_REGISTERED): {
        const { registeredStatus } = payload;
        return {
            ...state,
            statusFilter: registeredStatus,
            page: state.page && 1,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_SORT_SET): {
        const { sortBy, sortType } = payload;
        return {
            ...state,
            sortBy,
            sortType,
        };
    }
    case CLIENTS_TYPES.CLIENTS_ITEM_BLOCK_TOGGLE_RESOLVED: {
        const { clientId, blockValue } = payload;
        const items = state.items.map((item) => {
            if (item.id === clientId) {
                return {
                    ...item,
                    isBlocked: Boolean(blockValue),
                };
            }
            return item;
        });
        return {
            ...state,
            items,
        };
    }
    default:
        return state;
    }
};
