import React, {
    useState, useEffect, useMemo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ClientsSearchFilterContainer } from 'components/clients/ClientsSearchFilterContainer';
import { ClientsListTableHeader } from 'components/clients/ClientsListTableHeader';
import { ClientsListTableRow } from 'components/clients/ClientsListTableRow';

import { AddClientDialog } from 'components/clients/AddClientDialog';
import { EditClientDialog } from 'components/clients/EditClientDialog';
import { ApplyDiscountProfileDialog } from 'components/clients/Dialogs/ApplyDiscountProfileDialog';
import { SendInvitationDialog } from 'components/clients/Dialogs/SendInvitationDialog';
import { ConfirmDialog } from 'components/Common/Dialogs/ConfirmDialog';

import { AddMessageDialog } from 'components/clients/Dialogs/AddMessageDialog';
import { Button } from 'components/Common/Button';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { Row } from 'components/Common/Row';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { Column } from 'components/Common/Column';
import { Table } from 'components/Common/NewTable/Table';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { Tooltip } from 'components/Common/Tooltip';
import { Badge } from 'components/Common/Badge';
import Skeleton from 'react-loading-skeleton';
import { useDialog } from '../../../../hooks/useDialog';
import { useConfirmDialog } from '../../../../hooks/useConfirmDialog';

import * as CLIENTS_ACTIONS from '../../../../store/actions/clients';
import * as CLIENTS_SELECTORS from '../../../../store/selectors/clients';
import * as COMPANY_SELECTORS from '../../../../store/selectors/company';

import { CLIENTS_LIST_ID_PAGE, MESSAGES_ADD_PAGE } from '../../../../const/CLIENT_URL';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import { SORT_BY } from '../../../../const/clients/SORT_BY';
import { SORT_ORDER } from '../../../../const/clients/SORT_ORDER';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 9;

const T_PREFIX = 'list';
const T_DELETE = `${T_PREFIX}.dialogs.delete`;

const ClientsList = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const history = useHistory();

    const dispatch = useDispatch();

    const {
        page,
        items: clients,
        search,
        sortBy,
        discountFilter,
        shopFilter,
        statusFilter,
        sortType,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        total,
    } = useSelector(CLIENTS_SELECTORS.clientsListSelector);

    const { item: client } = useSelector(CLIENTS_SELECTORS.clientItemSelector);
    const isRequiredClientAddress = useSelector(COMPANY_SELECTORS.companyIsRequiredClientAddressSelector);

    const addClientDialog = useDialog(false);
    const editClientDialog = useDialog(false);
    const applyDiscountProfileDialog = useDialog(false);
    const sendInvitationDialog = useDialog(false);
    const addMessageDialog = useDialog(false);

    const [selectedClients, setSelectedClients] = useState({});
    const [discountProfiles, setDiscountProfiles] = useState([]);

    const handleDeselectAll = useCallback(() => {
        setSelectedClients({});
    }, [selectedClients]);

    const isAllSelected = useMemo(
        () => clients.every((c) => selectedClients[c.id]?.isSelected),
        [clients, selectedClients],
    );

    const deleteConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: useCallback(
            ({ clientId }) => {
                dispatch(CLIENTS_ACTIONS.deleteClientsItem({ clientId }));
            },
            [dispatch],
        ),
    });

    const clientsList = useMemo(
        () => (!showAll && clients.length > ITEMS_PER_PAGE
            ? clients.slice(0, ITEMS_PER_PAGE)
            : clients),
        [showAll, clients],
    );

    const handleSort = useCallback(() => {
        handleDeselectAll();
        if (sortBy === SORT_BY.FULL_NAME && sortType === SORT_ORDER.ASC) {
            dispatch(
                CLIENTS_ACTIONS.setClientsSort({
                    sortBy: SORT_BY.FULL_NAME,
                    sortType: SORT_ORDER.DESC,
                }),
            );
        } else {
            dispatch(
                CLIENTS_ACTIONS.setClientsSort({
                    sortBy: SORT_BY.FULL_NAME,
                    sortType: SORT_ORDER.ASC,
                }),
            );
        }
    }, [dispatch, sortBy, sortType]);

    const handleSearch = useCallback(
        ({ search: nextSearch }) => {
            handleDeselectAll();
            dispatch(CLIENTS_ACTIONS.setClientsSearch({ search: nextSearch }));
        },
        [dispatch],
    );

    const handleDiscountSelectChange = useCallback(
        ({ discountItems }) => {
            handleDeselectAll();
            dispatch(CLIENTS_ACTIONS.setCompanyDiscountProfiles({ discountItems }));
        },
        [dispatch],
    );

    const handleShopSelectChange = useCallback(
        ({ shopId: value }) => {
            handleDeselectAll();
            dispatch(CLIENTS_ACTIONS.clientsFiltrationShop({ shopId: value }));
        },
        [dispatch],
    );

    const handleRegisterSelectChange = useCallback(
        ({ registeredStatus: value }) => {
            handleDeselectAll();
            dispatch(
                CLIENTS_ACTIONS.clientsFiltrationRegistered({ registeredStatus: value }),
            );
        },
        [dispatch],
    );

    const handleDiscountProfileLoad = useCallback(async () => {
        const discountItems = await dispatch(
            CLIENTS_ACTIONS.getCompanyDiscountProfiles(),
        );
        setDiscountProfiles(discountItems);
    }, [dispatch]);

    const handleAddMessage = useCallback(() => {
        if (Object.values(selectedClients).length) {
            addMessageDialog.onShow();
        } else {
            history.push(MESSAGES_ADD_PAGE);
        }
    }, [selectedClients]);

    const handleSelect = useCallback(
        ({ clientId, isRegistered, email }) => {
            const selectedClient = {
                isSelected: true,
                isRegistered,
                email,
            };
            setSelectedClients((prevState) => ({
                ...prevState,
                [clientId]: selectedClient,
            }));
        },
        [selectedClients],
    );

    const handleDeselect = useCallback(
        ({ clientId }) => {
            const selectedClientsCopy = { ...selectedClients };
            delete selectedClientsCopy[clientId];
            setSelectedClients(selectedClientsCopy);
        },
        [selectedClients],
    );

    const handleSelectAll = useCallback(() => {
        setSelectedClients(
            clients.reduce(
                (acc, client) => ({
                    ...acc,
                    [client.id]: {
                        isSelected: true,
                        isRegistered: client.isRegistered,
                        email: client.contactInfo.email,
                    },
                }),
                {},
            ),
        );
    }, [clients, selectedClients]);

    const handleInfo = useCallback(
        ({ clientId }) => {
            history.push(CLIENTS_LIST_ID_PAGE({ clientId }));
        },
        [history.push],
    );

    const handleBlockToggle = useCallback(({ clientId }) => {
        dispatch(CLIENTS_ACTIONS.toggleClientsItemBlock({
            clientId,
        }));
    }, [dispatch]);

    const handleEdit = useCallback(
        ({ clientId }) => {
            dispatch(CLIENTS_ACTIONS.getClientItem({ clientId }));
            editClientDialog.onShow();
        },
        [editClientDialog.onShow, dispatch],
    );

    const handleDelete = useCallback(
        ({ clientId }) => {
            deleteConfirmDialog.onSetData({ clientId });
            deleteConfirmDialog.onShow();
        },
        [deleteConfirmDialog.onSetData, deleteConfirmDialog.onShow],
    );

    const handlePrevPage = useCallback(() => {
        handleDeselectAll();
        dispatch(CLIENTS_ACTIONS.setClientsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        handleDeselectAll();
        dispatch(CLIENTS_ACTIONS.setClientsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        handleDeselectAll();
        dispatch(CLIENTS_ACTIONS.setClientsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        handleDeselectAll();
        dispatch(CLIENTS_ACTIONS.setClientsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        handleDeselectAll();
        dispatch(CLIENTS_ACTIONS.setClientsPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        handleDeselectAll();
        dispatch(CLIENTS_ACTIONS.setClientsPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(
        ({ page: nextPage }) => {
            handleDeselectAll();
            dispatch(CLIENTS_ACTIONS.setClientsPage({ page: nextPage }));
        },
        [dispatch],
    );

    useEffect(() => {
        handleDiscountProfileLoad();
    }, [handleDiscountProfileLoad]);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.getClients());
    }, [
        dispatch,
        page,
        search,
        sortBy,
        sortType,
        discountFilter,
        statusFilter,
        shopFilter,
    ]);

    const onExport = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.requestExportClientList());
    }, [dispatch]);

    const clientsTotal = total.registred + total.notRegistred;
    const clientsRegistred = total.registred;
    const clientsNotRegistred = total.notRegistred;

    return (
        <React.Fragment>
            <TextSubHeader
                text={(
                    <Row gap={8}>
                        {t('list.header.title')}
                        <Tooltip
                            tooltip={(
                                <div className="d-flex flex-column align-items-start p-2 ">
                                    <span className="font-size-14 font-weight-500">{t('list.header.clientsTotal.total', { count: clientsTotal })}</span>
                                    <span className="font-size-14 font-weight-500">{t('list.header.clientsTotal.registered', { count: clientsRegistred })}</span>
                                    <span className="font-size-14 font-weight-500">{t('list.header.clientsTotal.notRegistered', { count: clientsNotRegistred })}</span>
                                </div>
                            )}
                            placement="bottom"
                            forButton
                        >
                            <Badge color="gray" size="extra-small">
                                {clientsTotal ?? <Skeleton height={16} width={21} />}
                            </Badge>
                        </Tooltip>
                    </Row>
                )}
                rightActions={[
                    (clientsList.length ? (
                        <Button
                            color="outline"
                            onClick={onExport}
                        >
                            {tc('placeholders.export')}
                        </Button>
                    ) : null),
                    <Button
                        color="yellow"
                        onClick={handleAddMessage}
                    >
                        {t('list.header.actions.addMessage.label')}
                    </Button>,
                    <Button
                        color="yellow"
                        onClick={sendInvitationDialog.onShow}
                    >
                        {t('list.header.actions.sendInvitations.label')}
                    </Button>,
                    <Button
                        color="yellow"
                        onClick={applyDiscountProfileDialog.onShow}
                    >
                        {t('list.header.actions.applyDiscountProfile.label')}
                    </Button>,
                    <Button
                        color="yellow"
                        onClick={addClientDialog.onShow}
                    >
                        {t('list.header.actions.add.label')}
                    </Button>,
                ]}
            />
            <Container>
                <Column gap={32} stretched>
                    <ClientsSearchFilterContainer
                        search={search}
                        onSearch={handleSearch}
                        discountFilter={discountFilter}
                        onDiscountSelectChange={handleDiscountSelectChange}
                        onShopSelectChange={handleShopSelectChange}
                        onRegisterSelectChange={handleRegisterSelectChange}
                        discountProfiles={discountProfiles}
                    />
                    <Content loading={loading}>
                        <Table
                            footer={{
                                page,
                                showAll,
                                itemsCount,
                                pagesCount,
                                columnsCount: COLUMNS_COUNT,
                                itemsPerPage: ITEMS_PER_PAGE,
                                onShowAll: handleShowAll,
                                onPrevPage: handlePrevPage,
                                onNextPage: handleNextPage,
                                onLastPage: handleLastPage,
                                onFirstPage: handleFirstPage,
                                onShowPages: handleShowPages,
                                onChangePage: handleChangePage,
                            }}
                        >
                            <ClientsListTableHeader
                                isSelected={!!isAllSelected}
                                onSelectAll={handleSelectAll}
                                onDeselectAll={handleDeselectAll}
                                onSort={handleSort}
                                sortBy={sortBy}
                                sortType={sortType}
                            />
                            <TableBody>
                                {clientsList.map((client) => (
                                    <ClientsListTableRow
                                        key={client.id}
                                        client={client}
                                        onInfo={handleInfo}
                                        onEdit={handleEdit}
                                        onBlockToggle={handleBlockToggle}
                                        onDelete={handleDelete}
                                        isSelected={selectedClients[client?.id]?.isSelected}
                                        onSelect={handleSelect}
                                        onDeselect={handleDeselect}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </Content>
                </Column>
            </Container>
            <AddClientDialog
                visible={addClientDialog.visible}
                onClose={addClientDialog.onClose}
                isRequiredClientAddress={isRequiredClientAddress}
            />
            <EditClientDialog
                client={client}
                visible={editClientDialog.visible}
                onClose={editClientDialog.onClose}
                isRequiredClientAddress={isRequiredClientAddress}
            />
            <ApplyDiscountProfileDialog
                selectedClients={selectedClients}
                visible={applyDiscountProfileDialog.visible}
                onClose={applyDiscountProfileDialog.onClose}
                onDeselectAll={handleDeselectAll}
                discountProfiles={discountProfiles}
            />
            <SendInvitationDialog
                selectedClients={selectedClients}
                visible={sendInvitationDialog.visible}
                onClose={sendInvitationDialog.onClose}
                onDeselectAll={handleDeselectAll}
            />
            <AddMessageDialog
                selectedClients={selectedClients}
                visible={addMessageDialog.visible}
                onClose={addMessageDialog.onClose}
                onDeselectAll={handleDeselectAll}
            />
            <ConfirmDialog
                size="md"
                text={t(`${T_DELETE}.text`)}
                title={t(`${T_DELETE}.title`)}
                visible={deleteConfirmDialog.visible}
                rejectProps={{
                    label: t(`${T_DELETE}.actions.reject.label`),
                }}
                confirmProps={{
                    label: t(`${T_DELETE}.actions.confirm.label`),
                }}
                onClose={deleteConfirmDialog.onReject}
                onReject={deleteConfirmDialog.onReject}
                onConfirm={deleteConfirmDialog.onConfirm}
            />
        </React.Fragment>
    );
};

ClientsList.propTypes = {};

ClientsList.defaultProps = {};

export default ClientsList;
