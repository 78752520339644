import React, { useCallback, useEffect, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { Content } from 'components/Common/Content';

import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as FINANCIALS_SELECTORS from 'store/selectors/financilas';
import * as BILLINGS_ACTIONS from 'store/actions/financials/billings';
import { BillingsTableHeader } from 'components/Financials/Billings/BillingsTableHeader';
import { BillingsTableBodyRow } from 'components/Financials/Billings/BillingsTableBodyRow';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 4;

function Billing() {
    const { t } = useTranslation(LOCALE_NAMESPACE.BILLINGS);
    const dispatch = useDispatch();

    const {
        page,
        items,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        sortOptions,
    } = useSelector(FINANCIALS_SELECTORS.billingsListSelector);

    const billingsList = useMemo(() => (
        (!showAll && items.length > ITEMS_PER_PAGE)
            ? items.slice(0, ITEMS_PER_PAGE)
            : items
    ), [showAll, items]);

    const handlePrevPage = useCallback(() => {
        dispatch(BILLINGS_ACTIONS.setBillingsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(BILLINGS_ACTIONS.setBillingsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(BILLINGS_ACTIONS.setBillingsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(BILLINGS_ACTIONS.setBillingsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(BILLINGS_ACTIONS.setBillingsPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(BILLINGS_ACTIONS.setBillingsPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(BILLINGS_ACTIONS.setBillingsPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(BILLINGS_ACTIONS.getBillings());
    }, [
        dispatch,
        page,
        showAll,
        sortOptions.sortBy,
        sortOptions.orderBy,
    ]);

    return (
        <React.Fragment>
            <TextSubHeader
                text={t('list.header.title')}
            />
            <Container
                fluid
                className="d-flex flex-column flex-grow-1 px-3 px-lg-5 "
            >
                <Content loading={loading}>
                    <Table
                        footer={{
                            page,
                            showAll,
                            itemsCount,
                            pagesCount,
                            columnsCount: COLUMNS_COUNT,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onShowAll: handleShowAll,
                            onPrevPage: handlePrevPage,
                            onNextPage: handleNextPage,
                            onLastPage: handleLastPage,
                            onFirstPage: handleFirstPage,
                            onShowPages: handleShowPages,
                            onChangePage: handleChangePage,
                        }}
                    >
                        <TableHeader>
                            <BillingsTableHeader />
                        </TableHeader>

                        <TableBody>
                            {billingsList.map((billing) => (
                                <ListTableBodyRow key={billing.id}>
                                    <BillingsTableBodyRow
                                        payout={billing}
                                    />
                                </ListTableBodyRow>
                            ))}
                        </TableBody>
                    </Table>
                </Content>
            </Container>
        </React.Fragment>

    );
}

export default Billing;
