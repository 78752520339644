import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Container from 'components/Layout/Container/Container';
import { Table } from 'components/Common/NewTable/Table';
import { Content } from 'components/Common/Content';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { BundlesListTableColumns } from 'components/bundles/BundlesListTableColumns';
import { BundlesListTableRow } from 'components/bundles/BundlesListTableRow';
import { useDialog } from 'hooks/useDialog';

import { BundlesListHeader } from 'components/bundles/BundlesListHeader';
import { BundlesListFooter } from 'components/bundles/BundlesListFooter';
import { BundleDetailsDialog } from 'components/bundles/BundleDetailsDialog';

import { BUNDLES_EDIT_ID_PAGE } from 'const/CLIENT_URL';

import * as BUNDLES_ACTIONS from 'store/actions/bundles';

import * as BUNDLES_SELECTORS from 'store/selectors/bundles';

const ITEMS_PER_PAGE = 10;

const COLUMNS_COUNT = 7;

const BundlesList = () => {
    const history = useHistory();

    const dispatch = useDispatch();
    const {
        page,
        items: bundles,
        search,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        hideArchived,
    } = useSelector(BUNDLES_SELECTORS.bundlesListSelector);

    const detailsDialog = useDialog(false);

    const handleShowInfo = useCallback(({ bundleId }) => {
        detailsDialog.onSetShow(bundleId);
    }, [detailsDialog.onSetShow]);

    const handleArchive = useCallback(({ bundleId }) => {
        dispatch(BUNDLES_ACTIONS.archiveBundlesItem({ bundleId }));
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPage({ page: 0 }));
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPageFirst());
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(BUNDLES_ACTIONS.setBundlesPage({ page: nextPage }));
    }, [dispatch]);

    const handleSearch = useCallback(({ search: nextSearch }) => {
        dispatch(BUNDLES_ACTIONS.setBundlesSearch({ search: nextSearch }));
    }, [dispatch]);

    const handleEdit = useCallback(({ bundleId }) => {
        history.push(BUNDLES_EDIT_ID_PAGE({ bundleId }));
    }, [history.push]);

    const handleToggleStatus = useCallback(({ bundleId }) => {
        dispatch(BUNDLES_ACTIONS.toggleBundlesItemStatus({ bundleId }));
    }, [dispatch]);

    const bundlesList = useMemo(() => (
        (!showAll && bundles.length > ITEMS_PER_PAGE)
            ? bundles.slice(0, Math.min(ITEMS_PER_PAGE, bundles.length))
            : bundles
    ), [showAll, bundles]);

    const handleToggleHideArchived = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.toggleBundlesHideArchived());
    }, [dispatch]);

    useEffect(() => {
        dispatch(BUNDLES_ACTIONS.getBundles());
    }, [
        dispatch,
        page,
        search,
        hideArchived,
    ]);

    return (
        <React.Fragment>
            <BundlesListHeader
                search={search}
                onSearch={handleSearch}
                onHideArchived={handleToggleHideArchived}
                hideArchived={hideArchived}
            />
            <Container>
                <Content loading={loading}>
                    <Table footer={{
                        page,
                        showAll,
                        itemsCount,
                        pagesCount,
                        columnsCount: COLUMNS_COUNT,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onShowAll: handleShowAll,
                        onPrevPage: handlePrevPage,
                        onNextPage: handleNextPage,
                        onLastPage: handleLastPage,
                        onFirstPage: handleFirstPage,
                        onShowPages: handleShowPages,
                        onChangePage: handleChangePage,
                    }}
                    >
                        <TableHeader>
                            <BundlesListTableColumns />
                        </TableHeader>
                        <TableBody>
                            {bundlesList.map((bundle) => (
                                <BundlesListTableRow
                                    key={bundle?.id}
                                    bundle={bundle}
                                    onEdit={handleEdit}
                                    onClick={handleShowInfo}
                                    onArchive={handleArchive}
                                    onToggleStatus={handleToggleStatus}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    <BundlesListFooter
                        search={search}
                        onSearch={handleSearch}
                    />
                </Content>
                <BundleDetailsDialog
                    visible={Boolean(detailsDialog.visible)}
                    bundleId={detailsDialog.visible}
                    onClose={detailsDialog.onClose}
                />
            </Container>
        </React.Fragment>
    );
};

export default BundlesList;
