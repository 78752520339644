import React, {
    Fragment,
    useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { instantFormValidation } from 'helpers/input/instantFieldValidation';

import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as COMPANY_SELECTORS from 'store/selectors/company';

import {
    styles as selectStyles,
    theme as selectTheme,
} from 'styles/select';

import { getError, isInvalid } from 'services/validationService';
import { Trash3Icon } from 'components/Icon/Icon';
import { getVATRateSelectOptions } from 'components/subscriptions/SubscriptionPricingFormGroup/SubscriptionPricingFormGroup';
import { useSelector } from 'react-redux';
import { handleKeyDownOnPriceInput } from 'helpers/input/handleKeyDownOnNumberInput';
import { INVOICE_PROP } from 'const/financials/invoices/INVOICE_PROP';
import { CHAR_SYMBOL } from 'const/string/CHAR_SYMBOL';
import ProductsSelect from 'components/products/ProductsSelect/ProductsSelect';
import { productsListSelector } from 'store/selectors/products';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { IconButton } from 'components/Common/IconButton';
import NumberInput from 'components/Common/NumberInput';
import { Column } from 'components/Common/Column';
import { Label } from 'components/Common/Typography/Label';
import { Row } from 'components/Common/Row';
import { useMobile } from 'hooks/useMobile';
import { InvoiceProductDeleteDialog } from '../../InvoiceProductDeleteDialog';

const isSame = (initialProduct, product) => initialProduct && product
    && initialProduct.name === product.name
    && initialProduct.price === product.price
    && initialProduct.vatValue === product.vatValue;

function InvoiceProductFormGroup(props) {
    const {
        index,
        product,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        onDelete,
        isDeleteDisabled,
    } = props;

    const [deleteProduct, setDeleteProduct] = useState(null);
    const { enableBusinessMode } = useSelector(COMPANY_SELECTORS.companyBuisnessModeSelector);
    const products = useSelector(productsListSelector);

    const initialProductData = useRef(null);

    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    useEffect(() => {
        const initialProduct = initialProductData.current;

        if (initialProduct) {
            if (String(product.id).startsWith('new-') && isSame(initialProduct, product)) {
                setFieldValue(`products[${index}].id`, initialProduct.id);
                return;
            }

            if (!String(product.id).startsWith('new-') && !isSame(initialProduct, product)) {
                setFieldValue(`products[${index}].id`, `new-${initialProduct.id}`);
                return;
            }
        }

        if (!String(product.id).startsWith('new-')) {
            initialProductData.current = product;
        }
    }, [product]);

    const handleQuantityChange = useCallback((value) => {
        setFieldValue(`products[${index}].quantity`, value);
    }, [setFieldValue]);

    const handlePriceChange = useCallback((e) => {
        const { target } = e;
        const { value } = target;
        if ([
            CHAR_SYMBOL.MINUS,
            CHAR_SYMBOL.PRICE.SEPARATOR.DOT,
        ].includes(value)) {
            return;
        }
        if (value === '') {
            setFieldValue(`products[${index}].price`, '');
            return;
        }
        const val = Math.min(Math.max(INVOICE_PROP.PRICE.MIN, value), INVOICE_PROP.PRICE.MAX);
        const muv = Math.trunc((val * 100).toFixed(2)) / 100;
        setFieldValue(`products[${index}].price`, muv);
    }, [setFieldValue, setFieldTouched]);

    const handleDelete = useCallback(() => {
        onDelete({ id: product.id, index });
    }, [onDelete, product.id, index]);

    const handleDeleteProduct = useCallback(() => {
        setDeleteProduct(null);
    }, []);

    const handleVATChange = useCallback((selected) => {
        setFieldValue(`products[${index}].vatValue`, selected.value);
    }, [setFieldValue, setFieldTouched, index]);

    const validationHandler = instantFormValidation({ setFieldTouched, touched });
    const isMobile = useMobile();

    return (
        <Fragment>
            <Row gap={16} wrap={isMobile} stretched align="start">
                <Column gap={0} stretched={isMobile ? 100 : 90}>
                    <Row gap={16} wrap={isMobile} stretched align="start">
                        <ProductsSelect
                            product={product}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            products={products}
                            index={index}
                            onDelete={setDeleteProduct}
                        />
                        <Row gap={16} stretched={isMobile ? 100 : 30} wrap={isMobile} align="start">
                            <Column gap={8} stretched>
                                <Label htmlFor={`quantity-${index}`}>
                                    {t('add.products.quantity.label')}
                                </Label>
                                <NumberInput
                                    id={`quantity-${index}`}
                                    value={product.quantity}
                                    onChange={handleQuantityChange}
                                    isInvalid={isInvalid(`products[${index}].quantity`, errors, touched)}
                                    max={INVOICE_PROP.QUANTITY.MAX}
                                    min={INVOICE_PROP.QUANTITY.MIN}
                                />

                                <Form.Control.Feedback
                                    type="invalid"
                                    className={classNames({
                                        'd-flex': isInvalid(`products[${index}].quantity`, errors, touched),
                                    })}
                                >
                                    {getError(`products[${index}].quantity`, errors)}
                                </Form.Control.Feedback>
                            </Column>
                            <Column gap={8} stretched>
                                <Label htmlFor={`price-${index}`}>
                                    {t('add.products.price.label')}
                                </Label>
                                <Form.Control
                                    id={`price-${index}`}
                                    type="number"
                                    name="price"
                                    value={product.price}
                                    isInvalid={isInvalid(`products[${index}].price`, errors, touched)}
                                    onChange={validationHandler(handlePriceChange)}
                                    onKeyDown={handleKeyDownOnPriceInput}
                                />

                                <Form.Control.Feedback
                                    type="invalid"
                                    className={classNames({
                                        'd-flex': isInvalid(`products[${index}].price`, errors, touched),
                                    })}
                                >
                                    {getError(`products[${index}].price`, errors)}
                                </Form.Control.Feedback>
                            </Column>
                            <Column gap={8} stretched>
                                <Label htmlFor={`vatValue-${index}`}>
                                    {t('add.products.vatValue.label')}
                                </Label>
                                <Select
                                    id={`vatValue-${index}`}
                                    styles={selectStyles}
                                    theme={selectTheme}
                                    name="vatValue"
                                    value={getVATRateSelectOptions(enableBusinessMode).find(({ value }) => value === product.vatValue)}
                                    options={getVATRateSelectOptions(enableBusinessMode)}
                                    onChange={handleVATChange}
                                />
                            </Column>
                        </Row>

                    </Row>
                </Column>
                <Row gap={16} justify="between" flex padding={24}>
                    <Column gap={0}>
                        <Label htmlFor={`total-${index}`} style={{ lineHeight: 1 }} weight="light" color="gray">
                            {t('add.products.total.label')}
                        </Label>
                        <Label id={`total-${index}`}>
                            {formatPrice.toEuroWithComma({ amount: (Number(product.price) * Number(product.quantity)) })}
                        </Label>
                    </Column>
                    <IconButton
                        type="button"
                        color="gray"
                        onClick={handleDelete}
                        size={36}
                        disabled={isDeleteDisabled}
                    >
                        <Trash3Icon />
                    </IconButton>
                </Row>
            </Row>
            <InvoiceProductDeleteDialog productId={deleteProduct} onClose={handleDeleteProduct} />
        </Fragment>
    );
}

export default InvoiceProductFormGroup;
