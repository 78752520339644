import React from 'react';
import PropTypes from 'prop-types';

import { InfoTooltipIcon } from 'components/Icon/Icon';
import { Tooltip } from 'components/Common/Tooltip';

import { ENVS } from 'services/envs';
import * as styles from './InfoTooltip.module.scss';

const InfoTooltip = ({
    text, placement, isDisabled, ...props
}) => (
    <Tooltip
        tooltip={text}
        placement={placement}
        isDisabled={isDisabled}
        show={ENVS.isDevelopment ? window.showTooltips : undefined}
    >
        <span className={styles.infoIcon} data-testid={props['data-testid']}>
            <InfoTooltipIcon height={18} width={18} />
        </span>
    </Tooltip>
);

InfoTooltip.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
    placement: PropTypes.string,
    isDisabled: PropTypes.bool,
    'data-testid': PropTypes.string,
};

InfoTooltip.defaultProps = {
    text: '',
    placement: 'bottom',
    isDisabled: false,
    'data-testid': 'data-test-info-tooltip',
};

export default InfoTooltip;
