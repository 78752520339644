import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import * as styles from './Typography.module.scss';

const colorClasses = {
    yellow: styles.yellow,
    black: styles.black,
    gray: styles.gray,
    red: styles.red,
    green: styles.green,
    blue: styles.blue,
};

const Typography = (props) => (
    <label
        className={classNames(
            props.className,
            styles.typography,
            colorClasses[props.disabled ? 'gray' : props.color],
            props.uppercase && styles.uppercase,
            props.ellipsis && styles.ellipsis,
            props.pointer && styles.pointer,
            styles[props.align],
            props.noWrap && styles.noWrap,
            styles[props.weight],
            props.italic && styles.italic,
            props.lineThrough && styles.lineThrough,
            props.capitalize && styles.capitalize,
            props.breakWord && styles.breakWord,
        )}
        htmlFor={props.htmlFor}
        data-testid={props['data-testid']}
    >
        {props.children}
        {props.tooltip && (
            <InfoTooltip
                text={props.tooltip}
                placement="top"
            />
        )}
    </label>
);

Typography.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    ellipsis: PropTypes.bool,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    color: PropTypes.oneOf(['yellow', 'black', 'gray', 'red', 'green']),
    uppercase: PropTypes.bool,
    htmlFor: PropTypes.string,
    'data-testid': PropTypes.string,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    pointer: PropTypes.bool,
    noWrap: PropTypes.bool,
    weight: PropTypes.oneOf(['light', 'normal', 'bold']),
    italic: PropTypes.bool,
    lineThrough: PropTypes.bool,
    capitalize: PropTypes.bool,
    breakWord: PropTypes.bool,
    disabled: PropTypes.bool,
};

Typography.defaultProps = {
    className: '',
    children: null,
    ellipsis: false,
    tooltip: null,
    color: 'black',
    uppercase: false,
    htmlFor: null,
    'data-testid': null,
    align: 'left',
    pointer: false,
    noWrap: false,
    weight: 'normal',
    italic: false,
    lineThrough: false,
    capitalize: false,
    breakWord: false,
    disabled: false,
};

export default Typography;
