import { createSelector } from 'reselect';

export const subscriptionsSelector = (state) => state.subscriptions;

export const soldSubscriptionsSelector = createSelector(
    subscriptionsSelector,
    (subscriptionsState) => subscriptionsState.soldSubscriptions,
);

export const subscriptionsListSelector = createSelector(
    subscriptionsSelector,
    (subscriptionsState) => subscriptionsState.subscriptions,
);

export const soldSubscriptionsListSelector = createSelector(
    soldSubscriptionsSelector,
    (subscriptions) => subscriptions.items,
);

const soldSubscriptionsStatusSelector = createSelector(
    soldSubscriptionsSelector,
    (subscriptions) => subscriptions.subscriptionsStatus,
);

const sellSubscriptionSelector = createSelector(
    soldSubscriptionsSelector,
    ({ sellSubscription }) => sellSubscription,
);

export const soldSubscriptionsPagesSelector = createSelector(
    soldSubscriptionsSelector,
    ({ currentPage, pagesCount }) => ({
        currentPage,
        pagesCount,
    }),
);

export const subscriptionsPagesSelector = createSelector(
    subscriptionsListSelector,
    ({ currentPage, pagesCount }) => ({
        currentPage,
        pagesCount,
    }),
);

const subscriptionsItemsCountSelector = createSelector(
    subscriptionsListSelector,
    ({ itemsCount }) => itemsCount,
);

const soldSubscriptionsItemsCountSelector = createSelector(
    soldSubscriptionsSelector,
    ({ itemsCount }) => itemsCount,
);

const soldSubscriptionsHideInactiveSelector = createSelector(
    soldSubscriptionsSelector,
    ({ hideInactive }) => hideInactive,
);

const soldSubscriptionsShowAllSelector = createSelector(
    soldSubscriptionsSelector,
    ({ showAll }) => showAll,
);

const subscriptionsShowAllSelector = createSelector(
    subscriptionsListSelector,
    ({ showAll }) => showAll,
);

const subscriptionsSearchSelector = createSelector(
    subscriptionsListSelector,
    ({ searchValue }) => searchValue,
);

const subscriptionsHideArchivedSelector = createSelector(
    subscriptionsListSelector,
    ({ hideArchived }) => hideArchived,
);

const soldSubscriptionsSearchSelector = createSelector(
    soldSubscriptionsSelector,
    ({ searchValue }) => searchValue,
);

const soldSubscriptionsStatusFilterSelector = createSelector(
    soldSubscriptionsSelector,
    ({ statusFilter }) => statusFilter,
);

const soldSubscriptionsIdFilterSelector = createSelector(
    soldSubscriptionsSelector,
    ({ idFilter }) => idFilter,
);

export const subscriptionsOptionsSelector = createSelector(
    soldSubscriptionsSelector,
    ({ subscriptionsOptions }) => subscriptionsOptions,
);

export const editSubscriptionUsagesSelector = createSelector(
    soldSubscriptionsSelector,
    ({ editSubscriptionUsages }) => editSubscriptionUsages,
);

const subscriptionsListTableSelector = createSelector(
    subscriptionsListSelector,
    ({ items }) => items,
);

const subscriptionsIsDataLoadedSelector = createSelector(
    subscriptionsListSelector,
    (subscriptions) => subscriptions.isDataLoaded,
);

const soldSubscriptionsIsDataLoadedSelector = createSelector(
    soldSubscriptionsSelector,
    (subscriptions) => subscriptions.isDataLoaded,
);

const soldSubscriptionsSelectedAllSelector = createSelector(
    soldSubscriptionsSelector,
    ({ selectedAll }) => selectedAll,
);

export const cancelSoldSubscriptionSelector = createSelector(
    soldSubscriptionsSelector,
    ({ cancelSoldSubscription }) => cancelSoldSubscription,
);

export const changeSoldSubscriptionSelector = createSelector(
    soldSubscriptionsSelector,
    ({ changeSoldSubscription }) => changeSoldSubscription,
);

export const clientIdSoldSubscriptionsSelector = createSelector(
    soldSubscriptionsSelector,
    ({ clientId }) => clientId,
);

export const subscriptionsListPageSelector = createSelector(
    subscriptionsListTableSelector,
    subscriptionsIsDataLoadedSelector,
    subscriptionsPagesSelector,
    subscriptionsItemsCountSelector,
    subscriptionsSearchSelector,
    subscriptionsHideArchivedSelector,
    (subscriptionsList, isDataLoaded, pages, itemsCount, searchValue, hideArchived) => ({
        subscriptionsList,
        isDataLoaded,
        ...pages,
        itemsCount,
        searchValue,
        hideArchived,
    }),
);

export const soldSubscriptionsListPageSelector = createSelector(
    soldSubscriptionsListSelector,
    soldSubscriptionsIsDataLoadedSelector,
    soldSubscriptionsSelectedAllSelector,
    soldSubscriptionsPagesSelector,
    soldSubscriptionsItemsCountSelector,
    soldSubscriptionsHideInactiveSelector,
    soldSubscriptionsSearchSelector,
    soldSubscriptionsIdFilterSelector,
    soldSubscriptionsStatusFilterSelector,
    sellSubscriptionSelector,
    cancelSoldSubscriptionSelector,
    editSubscriptionUsagesSelector,
    soldSubscriptionsStatusSelector,
    (
        soldSubscriptionsList,
        isDataLoaded,
        allSelected,
        pages,
        itemsCount,
        hideInactive,
        searchValue,
        idFilter,
        statusFilter,
        sellSubscription,
        cancelSoldSubscription,
        editSubscriptionUsages,
        subscriptionsStatus,
    ) => ({
        soldSubscriptionsList,
        isDataLoaded,
        allSelected,
        ...pages,
        itemsCount,
        hideInactive,
        searchValue,
        idFilter,
        statusFilter,
        sellSubscription,
        cancelSoldSubscription,
        editSubscriptionUsages,
        subscriptionsStatus,
    }),
);

export const soldSubscriptionsTableFooterSelector = createSelector(
    soldSubscriptionsPagesSelector,
    soldSubscriptionsItemsCountSelector,
    soldSubscriptionsShowAllSelector,
    ({ currentPage, pagesCount }, itemsCount, showAll) => ({
        currentPage,
        itemsCount,
        pagesCount,
        showAll,
    }),
);

export const subscriptionsTableFooterSelector = createSelector(
    subscriptionsPagesSelector,
    subscriptionsItemsCountSelector,
    subscriptionsShowAllSelector,
    ({ currentPage, pagesCount }, itemsCount, showAll) => ({
        currentPage,
        itemsCount,
        pagesCount,
        showAll,
    }),
);

export const editSubscriptionSelector = createSelector(
    subscriptionsListSelector,
    subscriptionsIsDataLoadedSelector,
    ({ items }, isDataLoaded) => ({
        subscriptions: items,
        isDataLoaded,
    }),
);

export const sellSubscriptionModalSelector = createSelector(
    sellSubscriptionSelector,
    ({ clients, subscriptions }) => ({
        subscriptions: subscriptions.map(({
            id, name, price, description, price_formatted,
        }) => ({
            label: `${name} - ${price_formatted}`,
            description,
            value: id,
            price: price_formatted,
            isFree: !price,
        })),

        clients: clients.map((client) => ({
            label: client.firstName,
            value: {
                clientId: client.client_id,
                clientName: client.firstName,
                clientEmail: client.email,
                clientPhone: client.contactNumber,
                note: client.note,
            },
        })),
    }),
);

export const soldSubscriptionListsQuerySelector = createSelector(
    soldSubscriptionsPagesSelector,
    soldSubscriptionsSearchSelector,
    soldSubscriptionsIdFilterSelector,
    soldSubscriptionsStatusFilterSelector,
    soldSubscriptionsHideInactiveSelector,
    ({ currentPage }, searchValue, idFilter, statusFilter, hideInactive) => ({
        currentPage,
        searchValue,
        idFilter,
        statusFilter,
        hideInactive,
    }),
);

export const subscriptionListsQuerySelector = createSelector(
    subscriptionsPagesSelector,
    subscriptionsSearchSelector,
    subscriptionsHideArchivedSelector,
    ({ currentPage }, searchValue, hideArchived) => ({
        currentPage,
        searchValue,
        hideArchived,
    }),
);
